import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import compose from "ramda/src/compose";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";

const styles = theme => ({
  content: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: "100%"
  },
  appBar: {
  },
  toolbar: theme.mixins.toolbar
});

const ContentLayout = ({ classes, title, children, history: { push } }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePassword, setChangePassword] = useState(false);

  function handleClick (event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose () {
    setAnchorEl(null);
  }

  return (
    <>
      {changePassword
        ? <ChangePasswordModal onClose={() => setChangePassword(false)} />
        : null}
      <main className={classes.content}>
        <AppBar position="sticky" top={0} className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton
              onClick={handleClick}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
          <MuiMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setChangePassword(true);
              }}
            >
              Změnit heslo
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                localStorage.removeItem("token");
                push("/login");
              }}
            >
              Odhlásit
            </MenuItem>
          </MuiMenu>
        </AppBar>
        <div className={classes.toolbar} />
        <div style={{ height: "100%", padding: "0 1.3rem" }}>
          {children}
        </div>
      </main>
    </>
  );
};

ContentLayout.propTypes = {};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ContentLayout);
