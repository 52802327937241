import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import DatePicker from "material-ui-pickers/DatePicker/DatePickerModal";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import { ComponentType, FC, useState } from "react";
import { graphql, MutationFunc } from "react-apollo";
import styled from "styled-components";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import { any } from "../../utils/permissions";
import updateClassroomMutationNode, { UpdateClassroomMutationParams } from "../../utils/updateClassroomMutation";
import SemesterSelectInput from "../SemesterSelectInput";
import { useUser } from "../UserContext";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import FinishConfirmationModal from "./FinishConfirmationModal";

interface Props {
  classroom: ClassroomAttributes;
  onClose: (refetch?: boolean) => unknown;
  editDisabled: boolean;
}

interface InnerProps {
  classes: any;
  updateClassroomMutation: MutationFunc<ClassroomAttributes, UpdateClassroomMutationParams>;
  exportMutation: MutationFunc;

}

const ProjectForm: FC<Props & InnerProps> = ({
  onClose,
  classes,
  updateClassroomMutation,
  classroom,
  exportMutation,
  editDisabled
}) => {
  const { Role } = useUser();
  const isAdmin = any(["SUPER_ADMIN", "ADMIN"], Role);
  const isCore = any(["CORE"], Role);
  const { enqueueSnackbar } = useSnackbar();
  const [archiveConfirmModal, setArchiveConfirmModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [finishConfirmModal, setFinishConfirmModal] = useState(false);
  const [classroomName, setClassroomName] = useState(
    classroom.classroomName || ""
  );
  const [schoolMeeting, setSchoolMeeting] = useState(classroom.schoolMeeting);
  const [schoolFirstContact, setSchoolFirstContact] = useState(classroom.schoolFirstContact);
  const [semester, setSemester] = useState(classroom.semester);
  const [year, setYear] = useState(classroom.year);
  const [moneyGoalAmount, setMoneyGoalAmount] = useState(
    classroom.moneyGoalAmount || ""
  );
  const [companyName, setCompanyName] = useState(classroom.companyName || "");
  const [businessPurpose, setBusinessPurpose] = useState(
    classroom.businessPurpose || ""
  );
  const [businessDescription, setBusinessDescription] = useState(
    classroom.businessDescription || ""
  );
  const [excursionDate, setExcursionDate] = useState(classroom.excursionDate);
  const [visitInProduction, setVisitInProduction] = useState(
    classroom.visitInProduction
  );
  const [coffeeWithTeacher, setCoffeeWithTeacher] = useState(
    classroom.coffeeWithTeacher
  );
  const [forgiveLoan, setForgiveLoan] = useState(classroom.forgiveLoan || "");
  const [forgiveLoanCause, setForgiveLoanCause] = useState(
    classroom.forgiveLoanCause || ""
  );
  const [nps, setNps] = useState(classroom.nps || "");
  const [area, setArea] = useState(classroom.area || "");
  const [eFairProfit, setEFairProfit] = useState(
    classroom.eFairProfit ? classroom.eFairProfit : ""
  );

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        updateClassroomMutation({
          variables: {
            id: classroom.id,
            classroomName,
            schoolMeeting,
            schoolFirstContact,
            semester,
            year,
            eFairProfit,
            moneyGoalAmount,
            companyName,
            businessPurpose,
            businessDescription,
            excursionDate,
            visitInProduction,
            coffeeWithTeacher,
            forgiveLoan,
            forgiveLoanCause,
            nps,
            area
          }
        })
          .then(() => {
            enqueueSnackbar("Projekt byl úspěšně uložen", {
              variant: "success",
              autoHideDuration: 4000,
              anchorOrigin: {
                horizontal: "center",
                vertical: "top"
              }
            });
            onClose(true);
          })
          .catch((ex) => {
            console.error("ERROR", ex);
          });
      }}
    >
      {archiveConfirmModal ? (
        <ArchiveConfirmationModal
          onClose={(refetch) => {
            onClose(refetch);
            setArchiveConfirmModal(false);
          }}
          classroom={classroom}
        />
      ) : null}
      {deleteConfirmModal ? (
        <DeleteConfirmationModal
          onClose={(refetch) => {
            onClose(refetch);
            setDeleteConfirmModal(false);
          }}
          classroom={classroom}
        />
      ) : null}
      {finishConfirmModal ? (
        <FinishConfirmationModal
          onClose={(success = false) => {
            setFinishConfirmModal(false);
            if (!success) return;
            onClose(false);
            enqueueSnackbar("Projekt byl úspěšně ukončen", {
              variant: "success",
              autoHideDuration: 4000,
              anchorOrigin: {
                horizontal: "center",
                vertical: "top"
              }
            });
          }}
          classroom={classroom}
        />
      ) : null}
      {classroom.projectType === "ZVS" ? (
        <div style={{ marginBottom: "30px" }}>
          <SemesterSelectInput
            semester={semester}
            year={year}
            setSemester={setSemester}
            setYear={setYear}
            create={false}
          />
          <FormControl margin="normal" fullWidth>
            <DatePicker
              id="schoolFirstContact"
              name="schoolFirstContact"
              value={schoolFirstContact}
              onChange={setSchoolFirstContact}
              label="Oslovení školy"
              format="DD.MM.YYYY"
            />
          </FormControl>
        </div>
      ) : (
        <div>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="classroomName">Jméno třídy</InputLabel>
            <Input
              id="classroomName"
              name="classroomName"
              autoFocus
              value={classroomName}
              onChange={(e) => setClassroomName(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <DatePicker
              id="schoolMeeting"
              name="schoolMeeting"
              value={schoolMeeting}
              onChange={setSchoolMeeting}
              label="Schůzka ve škole"
              format="DD.MM.YYYY"
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <DatePicker
              id="excursionDate"
              name="excursionDate"
              value={excursionDate}
              onChange={setExcursionDate}
              label="Termín exkurze"
              format="DD.MM.YYYY"
            />
          </FormControl>
          {classroom.type === "CORE"
            ? [
              <FormControl margin="normal" fullWidth key="visitInProduction">
                <DatePicker
                  id="visitInProduction"
                  name="visitInProduction"
                  value={visitInProduction}
                  onChange={setVisitInProduction}
                  label="Návštěva při výrobě"
                  format="DD.MM.YYYY"
                />
              </FormControl>,
              <FormControl margin="normal" fullWidth key="coffeeWithTeacher">
                <DatePicker
                  id="coffeeWithTeacher"
                  name="coffeeWithTeacher"
                  value={coffeeWithTeacher}
                  onChange={setCoffeeWithTeacher}
                  label="Káva s učitelem"
                  format="DD.MM.YYYY"
                />
              </FormControl>
            ]
            : null}
          <SemesterSelectInput
            semester={semester}
            year={year}
            setSemester={setSemester}
            setYear={setYear}
            create={false}
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="moneyGoalAmount">Výdělek</InputLabel>
            <Input
              id="moneyGoalAmount"
              name="moneyGoalAmount"
              value={moneyGoalAmount}
              onChange={(e) => setMoneyGoalAmount(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="profit">Výdělek e-jarmarku</InputLabel>
            <Input
              id="profit"
              name="profit"
              value={eFairProfit}
              onChange={(e) => setEFairProfit(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="companyName">Název firmy</InputLabel>
            <Input
              id="companyName"
              name="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="businessDescription">
              V čem děti podnikají
            </InputLabel>
            <Input
              id="businessDescription"
              name="businessDescription"
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="businessPurpose">Výdělek - použití</InputLabel>
            <Input
              id="businessPurpose"
              name="businessPurpose"
              value={businessPurpose}
              onChange={(e) => setBusinessPurpose(e.target.value)}
            />
          </FormControl>
          {isCore || isAdmin ? (
            <>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="semester">Odpuštění půjčky</InputLabel>
                <Select
                  inputProps={{
                    id: "forgiveLoan",
                    name: "forgiveLoan"
                  }}
                  value={forgiveLoan}
                  onChange={(e) => setForgiveLoan(e.target.value)}
                >
                  <MenuItem value="ANO">ANO</MenuItem>
                  <MenuItem value="NE">NE</MenuItem>
                </Select>
              </FormControl>
              {forgiveLoan === "ANO" ? (
                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="forgiveLoanCause">Důvod</InputLabel>
                  <Input
                    id="forgiveLoanCause"
                    name="forgiveLoanCause"
                    multiline
                    rows={3}
                    rowsMax={5}
                    value={forgiveLoanCause}
                    onChange={(e) => setForgiveLoanCause(e.target.value)}
                  />
                </FormControl>
              ) : null}
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="nps">NPS</InputLabel>
                <Input
                  id="nps"
                  name="nps"
                  multiline
                  rows={3}
                  rowsMax={5}
                  value={nps}
                  onChange={(e) => setNps(e.target.value)}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="area" style={{ marginBottom: 130 }}>
                  Oblast
                </InputLabel>
                <Input
                  id="area"
                  name="area"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </FormControl>
            </>
          ) : null}
        </div>
      )}
      <FixedButtons>
        <div
          style={{
            justifyContent: "flex-start",
            marginRight: "auto"
          }}
        >
          <Button
            variant="contained"
            className={classes.submit}
            color="primary"
            onClick={() => {
              exportMutation({
                variables: {
                  id: classroom.id
                }
              });
              enqueueSnackbar("Projekt byl odeslaný na e-mail");
            }}
            disabled={editDisabled}
          >
            Export
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.submit}
            style={{ marginLeft: "16px" }}
            disabled={editDisabled}
            onClick={() => setDeleteConfirmModal(true)}
            key="trash"
          >
            {classroom.deleted ? "Obnovit" : "Smazat"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{ marginLeft: "16px" }}
            disabled={editDisabled}
            onClick={() => setArchiveConfirmModal(true)}
            key="archive"
          >
            {classroom.archived ? "Obnovit" : "Archivovat"}
          </Button>
        </div>

        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          type="submit"
          disabled={editDisabled}
        >
          Uložit
        </Button>
      </FixedButtons>
    </form>
  );
};

// const getActivePhase = (classroom) => find((phase) => !phase.finished)(classroom.phases || []);

const exportMutation = graphql(
  gql`
    mutation Export($id: ID!) {
      export(id: $id)
    }
  `,
  {
    name: "exportMutation"
  }
);

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(
  graphql(updateClassroomMutationNode, {
    name: "updateClassroomMutation"
  }),
  exportMutation,
  withStyles(styles)
)(ProjectForm as any) as any as ComponentType<Props>;

const FixedButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  padding: 10px 20px;
  border-radius: 4px;
`;
