export interface SchoolAttributes {
  id: string;
  name: string;
  region: string;
  note: string;
  status;
  street: string;
  city: string;
  director: {
    id: string;
    name: string;
    email: string;
    phone: string;
  };
  alternate: {
    id: string;
    name: string;
    email: string;
    phone: string;
  };
  classrooms: {
    id: string;
    classroomName: string;
    semester;
    year;
    deleted;
    projectType;
    type: "AP" | "ZVS";
    creator: {
      id: string;
      firstname: string;
      lastname: string;
    };
    teacher: {
      id: string;
      name: string;
      email: string;
    };
    teacherName: string;
    teacherEmail: string;
  };
}

export default `
    id
    name
    region
    note
    status
    street
    city
    director {
        id
        name
        email
        phone
    }
    alternate {
        id
        name
        email
        phone
    }
    classrooms {
      id
      classroomName
      semester
      year
      deleted
      projectType
      type
      creator {
        id
        firstname
        lastname
      }
      teacher {
        id
        name
        email
      }
      teacherName
      teacherEmail
    }
`;
