import Button from "@material-ui/core/Button";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { withStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { FC, useState } from "react";
import { DataValue, graphql } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import UploadPhotoDialog from "./UploadPhotoDialog";

interface Props {
  classroom: ClassroomAttributes;
  editDisabled?: boolean;
}

interface InnerProps {
  classroomQuery: DataValue<{ classroom: ClassroomAttributes }>;
  classes: any;
}

const ProjectFiles: FC<Props & InnerProps> = ({ classroomQuery, classes, editDisabled }) => {
  const [uploadPhoto, setUploadPhoto] = useState(false);

  if (!classroomQuery.classroom) return null;

  const { classroom } = classroomQuery;

  return (
    <div>
      {uploadPhoto ? (
        <UploadPhotoDialog
          classroom={classroom}
          onClose={() => {
            setUploadPhoto(false);
          }}
        />
      ) : null}
      <Button
        variant="contained"
        color="primary"
        style={{
          margin: "10px 0"
        }}
        onClick={() => {
          setUploadPhoto(true);
        }}
        disabled={editDisabled}
      >
        Nahrát fotky
      </Button>
      <div className={classes.root}>
        <ImageList cellHeight={200} className={classes.gridList} cols={5}>
          {(classroom.phases ?? []).map((phase) =>
            phase.files.filter(f => f.fileType === "PHOTO").map(file => (
              <ImageListItem key={file.id} cols={1}>
                <img src={`https://abeceda.adane.cz${file.path}`} alt={file.name} />
              </ImageListItem>
            ))
          )}
        </ImageList>
      </div>
    </div>
  );
};

const classroomQuery = graphql<Props>(gql`
    query Classroom($id: ID!) {
        classroom(id: $id) {
            id
            phases {
                id
                files {
                    id
                    name
                    fileType
                    path
                    author {
                        id
                        firstname
                        lastname
                    }
                }
            }
        }
    }
`, {
  name: "classroomQuery",
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      id: props.classroom.id
    }
  })
});

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: "100%"
  }
});

export default compose(classroomQuery, withStyles(styles as any))(ProjectFiles);
