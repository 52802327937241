import Button from "@material-ui/core/Button";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import contains from "ramda/src/contains";
import pathOr from 'ramda/src/pathOr';
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { DataValue } from "react-apollo";
import styled from "styled-components";

import EFairModalTabs from "../constants/EFairModalTabs";
import EFairModal from "../containers/EFairModal.container";
import { all } from "../utils/permissions";
import { sortMomentInput, sortStringInput } from "../utils/sort";
import { DataGrid } from "./DataGrid";
import { useDataGrid } from "./DataGrid/useDataGrid";
import { useUser } from "./UserContext";

const EFairModal2: any = EFairModal;
type EFair = any;

interface Props {
  id: string;
  query: DataValue<{ eFairs: EFair[] }>;
  dataSelector: (q: DataValue<{ eFairs: EFair[] }>) => EFair[];
  defaultDetail?: EFair;
  toolbar?: ReactNode;
}

interface InnerProps {
  classes: any;
}

const EFairsTable: FC<Props & InnerProps> = ({
  id,
  classes,
  query,
  dataSelector,
  defaultDetail,
  toolbar
}) => {
  const user = useUser();
  const isCoreUser = all(["CORE"], user);

  const [detail, setDetail] = useState<readonly [EFair, keyof typeof EFairModalTabs] | undefined>();

  const { state, ...dataGrid } = useDataGrid({
    id,
    columns: initialCols(isCoreUser),
    options: {
      onCellClick: (colData, colMetadata) => {
        const classroom = dataSelector(query)[colMetadata.dataIndex];
        setDetail([classroom, EFairModalTabs.DATA] as const);
      }
    }
  });

  useEffect(() => {
    defaultDetail && setDetail([defaultDetail, EFairModalTabs.DATA] as const);
  }, [defaultDetail]);

  const sortIndex = state.sort && dataGrid.columns.map(c => c.name).findIndex(c => c === state.sort?.name);
  const data = useMemo(() =>
    // state.sort
    //   ? customSort(dataSelector(query) || [], sortIndex, state.sort.direction)
    dataSelector(query) || [],
  [dataSelector, query]);

  const userRoles = user.Role.map(r => r.name);

  const editDisabled = (eFair) => {
    if (userRoles.includes("ADMIN")) {
      return false;
    }
    const classroomType = pathOr("", ["classroom", "type"])(eFair);
    const classroomSchoolRegion = pathOr("", ["classroom", "school", "region"])(
      eFair
    );
    const classroomCreatorRegion = pathOr("", ["classroom", "creator", "region"])(
      eFair
    );
    if (userRoles.includes('CORE')) {
      if (classroomType !== "CORE") return true;
      if (!eFair.classroom.school) return false;
      return !(
        classroomCreatorRegion === user.region ||
        classroomSchoolRegion === user.region
      );
    }
  };

  if (query.error) {
    return (
      <SnackbarContent
        className={classes.errorMessage}
        message="Načtení se nezdařilo"
      />
    );
  }

  return (
    <>
      {detail ? (
        <EFairModal2
          eFair={detail[0]}
          defaultTab={detail[1]}
          onClose={(refetch) => {
            if (refetch) {
              query.refetch();
            }
            setDetail(null);
          }}
        />
      ) : null}
      <DataGrid
        {...dataGrid}
        loading={query.loading}
        toolbar={toolbar}
        data={data.map((eFair) => {
          return [
            !editDisabled(eFair) ? (
            <Button key={0}>
              <Edit />
            </Button>) : null,
            `${eFair?.classroom?.classroomName || "-"}, ${eFair?.classroom?.school?.name || "-"}`,
            eFair?.classroom?.semester
              ? `${eFair?.classroom?.semester}. pololetí ${eFair?.classroom?.year}/${eFair?.classroom?.year + 1}`
              : "-",
            `${eFair?.classroom?.school?.street || "-"}, ${eFair?.classroom?.school?.city || "-"}`,
            eFair?.state || "-",
            eFair?.approval || "-",
            eFair?.to
              ? moment(eFair?.from).format("DD/MM/YYYY")
              : "-",
            eFair?.to
              ? moment(eFair?.to).format("DD/MM/YYYY")
              : "-"
          ];
        })}
      />
    </>
  );
};

const styles = (theme) => ({
  table: {
    minWidth: 500
  },
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing()
  },
  errorProjectRow: {
    backgroundColor: theme.palette.error.light
  }
});

export default withStyles(styles)(EFairsTable);

const customSort = (data, colIndex, order) => {
  switch (colIndex) {
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
      return sortStringInput(colIndex, data, order);
    case 6:
    case 7:
      return sortMomentInput(colIndex, data, order, "DD/MM/YYYY");
    default:
      break;
  }
  const sorted = data.sort((a, b) => {
    const intA = +a[colIndex] || 0;
    const intB = +b[colIndex] || 0;
    return intA - intB;
  });
  if (order === "asc") return sorted;
  return [...sorted].reverse();
};

const StateColumn = styled.div`
  background-color: ${(props) => {
    if (props.value === "Schváleno") {
      return "lightgreen";
    } else if (props.value === "Čeká na schválení") {
      return "#FEC000";
    } else if (props.value === "Zamítnuto") {
      return "#FEEEED";
    } else {
      return "#F2F2F2";
    }
  }};
  padding: 24px;
  text-align: center;
  color: ${(props) => {
    if (props.value === "Schváleno") {
      return "green";
    } else if (props.value === "Čeká na schválení") {
      return "black";
    } else if (props.value === "Zamítnuto") {
      return "red";
    } else {
      return "grey";
    }
  }};
`;

const initialCols = (isCoreUser: boolean) => [
  {
    name: "",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Projekt",
    options: {
      filter: false
    }
  },
  {
    name: "Pololetí",
    options: {
      filter: true
    }
  },
  {
    name: "Škola",
    options: {
      filter: false
    }
  },
  {
    name: "Stav",
    options: {
      filter: true
    }
  },
  {
    name: "Stav ZF",
    options: {
      filter: true,
      sortDirection: "asc" as const,
      customBodyRender: (value) => {
        return <StateColumn value={value}>{value}</StateColumn>;
      }
    }
  },
  {
    name: "Začátek e-jarmarku",
    options: {
      filter: false
    }
  },
  {
    name: "Konec e-jarmarku",
    options: {
      filter: false
    }
  },
  ...isCoreUser
    ? [
      {
        name: "Datum návštěvy při výrobě",
        options: {
          filter: false
        }
      },
      {
        name: "Datum kávy s učitelem",
        options: {
          filter: false
        }
      }
    ]
    : []
];
