import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import compose from "ramda/src/compose";
import join from "ramda/src/join";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { setCreateSchool, setEditSchool } from "../model/school/actions";
import { DataGrid } from "./DataGrid";
import { useDataGrid } from "./DataGrid/useDataGrid";

const SchoolsTable = ({ id, schoolsQuery, setCreateSchool, setEditSchool }) => {
  // const options = {
  //   onCellClick: (colData, { colIndex, dataIndex }) => {
  //     // TODO
  //   }
  //   // customSort: (data, colIndex, order) => {
  //   //   return sortStringInput(colIndex, data, order);
  //   // }
  // };

  const dataGrid = useDataGrid({ id, columns, options: {} });

  return (
    <>
      <DataGrid
        {...dataGrid}
        loading={schoolsQuery.loading}
        data={(schoolsQuery.schools || []).map((school) => {
          return [
            <Edit key={0} onClick={() => { setEditSchool(school.id) }} />,
            pathOr("-", ["name"])(school),
            pathOr("-", ["region"])(school),
            pathOr("-", ["note"])(school),
            join(", ")([
              prop("street")(school),
              prop("city")(school)
            ]),
            pathOr("-", ["status"])(school)
          ];
        })}
        toolbar={(
          <Button
            onClick={() => setCreateSchool(true)}
            variant="contained"
            color="primary"
          >
            Vytvořit školu
          </Button>
        )}
      />
    </>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = { setEditSchool, setCreateSchool };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApollo
)(SchoolsTable);

const columns = [
  {
    name: "Upravit",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Název",
    options: {
      filter: false,
      sortDirection: "asc"
    }
  },
  {
    name: "Region",
    options: {
      filter: true
    }
  },
  {
    name: "Poznámka",
    options: {
      filter: false
    }
  },
  {
    name: "Adresa",
    options: {
      filter: false
    }
  },
  {
    name: "Stav",
    options: {
      filter: true
    }
  }
];
