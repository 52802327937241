import React, { useEffect, useState } from "react";
import { getSelectValue, getYear } from "../utils/year";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";

const SemesterSelectInput = ({ setSemester, setYear, year, semester, create }) => {
  let initialYear;
  let initialValue;
  const [isChangedInitialValue, setIsChangedInitialValue] = useState(false);

  if (create && !isChangedInitialValue) {
    initialValue = 3;
  } else {
    initialValue = getSelectValue(semester, year);
  }
  const now = moment();

  if (now.month() < 8) {
    initialYear = now.year() - 2;
  } else {
    initialYear = now.year() - 1;
  }

  useEffect(() => {
    if (initialValue) {
      setSemester(initialValue % 2 === 1 ? 1 : 2);
      setYear(getYear(initialValue));
    }
  }, [initialValue, setSemester, setYear]);

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor="semester">Pololetí</InputLabel>
      <Select
        inputProps={{
          id: "semester",
          name: "semester"
        }}
        value={initialValue}
        onChange={(e) => {
          setSemester(e.target.value % 2 === 1 ? 1 : 2);
          setYear(getYear(e.target.value));
          setIsChangedInitialValue(true);
        }}
      >
        <MenuItem value={1}>
          1. pololetí {initialYear}/{initialYear + 1}
        </MenuItem>
        <MenuItem value={2}>
          2. pololetí {initialYear}/{initialYear + 1}
        </MenuItem>
        <MenuItem value={3}>
          1. pololetí {initialYear + 1}/{initialYear + 2}
        </MenuItem>
        <MenuItem value={4}>
          2. pololetí {initialYear + 1}/{initialYear + 2}
        </MenuItem>
        <MenuItem value={5}>
          1. pololetí {initialYear + 2}/{initialYear + 3}
        </MenuItem>
        <MenuItem value={6}>
          2. pololetí {initialYear + 2}/{initialYear + 3}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SemesterSelectInput;
