import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import DatePicker from "material-ui-pickers/DatePicker";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import React, { Fragment, useState } from "react";
import styled from "styled-components";

import approveEFairMutation from "../../utils/approveEFairMutation";
import updateEFairMutation from "../../utils/updateEFairMutation";
import EFairApprovalText from "../EFairApprovalText.component";
import EFairMedia from "../EFairMedia.component";
import IssuingDay from "../IssuingDay.component";
import ProductCard from "../ProductCard.component";

const Title = styled.p`
  color: #393939;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: "Helvetica Neue";
  font-size: 1.3em;
  font-weight: bold;
`;
const Subtitle = styled.p`
  color: #393939;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: "Helvetica Neue";
  font-size: 1.2em;
`;

const DataPage = styled.form`
  width: 100%;
  height: auto;
`;
const Text = styled.p`
  color: #393939;
  margin: 30px auto;
  text-align: center;
  //margin-bottom: 30px;
  font-family: "Helvetica Neue";
  font-size: 1em;
  width: 75%;
`;
const DeleteButton = styled(Button)`
  background-color: #da2530;
  color: white;
  &:hover {
    background-color: #b42932;
  }
`;
const Centered = styled.div`
  margin: 10px auto;
  width: 70%;
  text-align: center;
`;

const handleDateChange = () => {

};
const EFairDataForm = ({
  eFair,
  approveEFairMutation,
  updateEFairMutation,
  isAdmin,
  onClose
}) => {
  const [notifyTeam, setNotifyTeam] = useState(false);
  const [reason, setReason] = useState(
    eFair.approvalText ? eFair.approvalText : ""
  );
  const { enqueueSnackbar } = useSnackbar();
  return (
    <DataPage>
      {eFair.approvalText !== "" && eFair.approvalText !== null ? (
        <>
          <EFairApprovalText title="Proč jsme neschválili poslední změny:" text={eFair.approvalText} />
        </>
      ) : null}
      {eFair.managerNote === "" || eFair.managerNote === null ? null : (
        <>
          <EFairApprovalText title="Poznámka správce" bgColor="#e1e1e1" color="#313131" text={eFair.managerNote} />
        </>
      )}
      <Title>Obecné</Title>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="introText">Úvodní text</InputLabel>
        <Input
          id="introText"
          name="introText"
          autoFocus
          value={eFair.introText}
          disabled
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="goal">Cíl podnikání</InputLabel>
        <Input id="goal" name="goal" value={eFair.goal} disabled />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="contactEmail">
          Kontaktní e-mail pro veřejnost
        </InputLabel>
        <Input
          id="contactEmail"
          name="contactEmail"
          disabled
          value={eFair.contactEmail}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="youtubeLink">Odkaz na YouTube video</InputLabel>
        <Input
          id="youtubeLink"
          name="youtubeLink"
          disabled
          value={eFair.videoLink}
        />
      </FormControl>
      <Title>Konání e-jarmarku</Title>
      <Subtitle>Začátek e-jarmarku</Subtitle>
      <FormControl margin="normal" fullWidth>
        <DatePicker
          id="dateFrom"
          name="dateFrom"
          disabled
          value={moment(eFair.from)._isValid === true ? moment(eFair.from) : null}
          onChange={handleDateChange}
          label="Datum"
          format="DD.MM.YYYY"
        />
      </FormControl>
      <Subtitle>Konec e-jarmarku</Subtitle>
      <FormControl margin="normal" fullWidth>
        <DatePicker
          id="dateTo"
          name="dateTo"
          value={moment(eFair.to)._isValid === true ? moment(eFair.to) : null}
          onChange={handleDateChange}
          label="Datum"
          format="DD.MM.YYYY"
          disabled
        />
      </FormControl>
      <Title>Místo a termíny pro vyzvednutí</Title>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="issuingPlace">Ulice a č.p.</InputLabel>
        <Input
          id="issuingPlace"
          name="issuingPlace"
          value={eFair.issuingPlace ? eFair.issuingPlace.streetAndNumber : ""}
          disabled
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="city">Město</InputLabel>
        <Input
          id="city"
          name="city"
          value={eFair.issuingPlace ? eFair.issuingPlace.city : ""}
          disabled
        />
      </FormControl>
      <FormControl margin="normal">
        <InputLabel htmlFor="zipCode">PSČ</InputLabel>
        <Input
          id="zipCode"
          name="zipCode"
          value={eFair.issuingPlace ? eFair.issuingPlace.zipCode : ""}
          disabled
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="note">Poznámka</InputLabel>
        <Input
          id="note"
          name="note"
          value={eFair.issuingPlace ? eFair.issuingPlace.note : ""}
          disabled
        />
      </FormControl>
      <Subtitle>Termíny pro vyzvednutí</Subtitle>
      {eFair.issuingTime
        ? eFair.issuingTime.map((el, i) => {
          return (
            <Fragment key={i}>
              <IssuingDay handleDateChange={handleDateChange} time={el} />
            </Fragment>
          );
        })
        : null}

      <br></br>
      <Title>Média</Title>
      <EFairMedia eFair={eFair} />
      <Title>Výrobky</Title>
      <ProductCard eFair={eFair} />
      {isAdmin && eFair.approval === "Čeká na schválení" ? (
        <>
          <Text>Pokud je vše v pořádku, můžete schválit změny</Text>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              approveEFairMutation({
                variables: {
                  id: eFair.id,
                  notifyTeam: notifyTeam
                }
              })
                .then((res) => {
                  enqueueSnackbar("E-jarmark byl schválen", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                  onClose(true);
                })
                .catch((err) => console.log(err));
            }}
          >
            Schválit změny
          </Button>
          <Centered>
            <Checkbox
              checked={notifyTeam}
              onClick={() => setNotifyTeam(!notifyTeam)}
              name="checkbox"
              color="primary"
            />
            <InputLabel
              htmlFor="checkbox"
              onClick={() => setNotifyTeam(!notifyTeam)}
            >
              Notifikovat o schválení změny tým, který se o projekt stará
            </InputLabel>
          </Centered>
          <Text>
            nebo je zamítnout a v odůvodnění poslat učiteli co potřebujeme
            doplnit/opravit
          </Text>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="reason">Důvod zamítnutí</InputLabel>
            <Input
              id="reason"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormControl>
          <DeleteButton
            disabled={reason.length < 5}
            variant="contained"
            fullWidth
            onClick={() => {
              updateEFairMutation({
                variables: {
                  id: eFair.id,
                  approval: "Zamítnuto",
                  approvalText: reason
                }
              })
                .then((res) => {
                  enqueueSnackbar("Změny byly zamítnuty", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                  onClose(true);
                })
                .catch((err) => console.log(err));
            }}
          >
            Zamítnout změny s uvedením důvodu
          </DeleteButton>
        </>
      ) : null}
    </DataPage>
  );
};

export default compose(
  approveEFairMutation,
  updateEFairMutation
)(EFairDataForm);
