import withStyles from "@material-ui/core/styles/withStyles";
import React, { ComponentType, FC } from "react";
import styled from "styled-components";

import { ClassroomAttributes } from "../constants/classroomAttributes";
import { useProjectDetail } from "./ProjectDetailModal";

interface Props {
  data: ClassroomAttributes[];
  refetch: () => unknown;
}

interface InnerProps {
  classes: any;
}

const ClassroomsTable: FC<Props & InnerProps> = ({ classes, data, refetch }) => {
  const { setProjectDetail } = useProjectDetail();
  // if (data.error) {
  //   return (
  //     <SnackbarContent
  //       className={classes.errorMessage}
  //       message="Načtení se nezdařilo"
  //     />
  //   );
  // }
  data = data.filter(classroom => classroom.deleted === false);
  const table = (
    <Table>
      <thead>
        <Tr>
          <Th>Třída</Th>
          <Th>Typ projektu</Th>
          <Th>Typ</Th>
          <Th>Zakladatel</Th>
          <Th>Pololetí</Th>
          <Th>Učitel</Th>
          <Th>Email učitele</Th>
        </Tr>
      </thead>
      <tbody>
        {data.map(classroom => {
          return (
            <Tr
              key={classroom.id}
              className={classes.classroomItem}
              onClick={() => {
                setProjectDetail(classroom.id, { refetch: refetch });
              }}
            >
              <Td>{classroom.classroomName ? classroom.classroomName : "-"}</Td>
              <Td>{classroom.projectType ? classroom.projectType === "AP" ? "PT" : classroom.projectType : "-"}</Td>
              <Td>{classroom.type ? classroom.type === "CORE" ? "RMKT" : "Dobrovolník" : "-"}</Td>
              <Td>{classroom.creator ? classroom.creator.firstname + " " + classroom.creator.lastname : "-"}</Td>
              <Td>{`${classroom.semester}. pololetí ${classroom.year}/${parseInt(`${classroom.year}`) + 1}`}</Td>
              <Td>{classroom.teacherName ? classroom.teacherName : classroom.teacher ? classroom.teacher.name : "-"}</Td>
              <Td>{classroom.teacherEmail ? classroom.teacherEmail : classroom.teacher ? classroom.teacher.email : "-"}</Td>
            </Tr>
          );
        })}
      </tbody>
    </Table>
  );

  return (
    <>
      {data.length > 0 ? table : <NoRecord>Nejsou vedeny žádné třídy k této škole</NoRecord>}
    </>
  );
};

const styles = (theme) => {
  return {
    classroomItem: {
      "cursor": "pointer",
      "&:hover": {
        backgroundColor: theme.palette.action.hover
      }
    }
  };
};

export default withStyles(styles)(ClassroomsTable) as ComponentType<Props>;

const NoRecord = styled.div`
text-align: center;
color: red;
margin-top: 30px;
`;

const Table = styled.table`
font-family: arial, sans-serif;
border-collapse: collapse;
width: 100%;
margin-top: 30px;
`;

const Th = styled.th`
border-bottom: 1px solid #dddddd;
text-align: left;
padding: 8px;
`;

const Tr = styled.tr`
&:nth-child(even) {
  background-color: #dddddd;
}
`;

const Td = styled.td`
border-bottom: 1px solid #dddddd;
text-align: left;
padding: 8px;
`;
