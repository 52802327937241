import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import { ComponentType, FC, useState } from "react";
import { DataValue, graphql } from "react-apollo";

import classroomAttributes, { ClassroomAttributes } from "../../constants/classroomAttributes";
import ProjectModalTabs from "../../constants/ProjectModalTabs";
import SchoolForms from "../../containers/SchoolForms";
import { any } from "../../utils/permissions";
import AdminNoteForm from "../forms/AdminNoteForm";
import BranchForm from "../forms/BranchForm";
import FairForm from "../forms/FairForm";
import MessagesForm from "../forms/MessagesForm";
import TeamUsersForm from "../forms/TeamUsersForm";
import ToolboxForm from "../forms/ToolboxForm";
import TabPanel from "../TabPanel";
import { useUser } from "../UserContext";
import ProjectFiles from "./ProjectFiles";
import ProjectForm from "./ProjectForm";
import ProjectState from "./ProjectState";
import SubclassroomsTab from "./SubclassroomsTabs";

interface Props {
  classroomId: string;
  onClose: (refetch?: boolean) => unknown;
  defaultTab?: keyof typeof ProjectModalTabs;
}

interface InnerProps {
  classes: any;
  classroomQuery: unknown;
  projectModalQuery: DataValue<ProjectModalQuery>;
}

const ProjectModal: FC<Props & InnerProps> = ({
  classes,
  defaultTab,
  projectModalQuery: { classroom, loading, ...query },
  onClose
}) => {
  const { Role, region } = useUser();
  const [activeTab, setActiveTab] = useState(defaultTab);

  const renderLoaded = () => {
    const roles = Role.map(r => r.name);
    const isCoreUser = any(["CORE"], Role);

    const classroomSchoolRegion = classroom.school?.region;
    const classroomCreatorRegion = classroom.creator?.region;
    const classroomType = classroom.type;

    const editDisabled = (() => {
      if (any(["ADMIN"], Role)) return false;
      if (classroom.projectType === "ZVS" && any(["CORE"], Role)) return false;
      if (any(["CORE"], Role)) {
        if (classroomType !== "CORE") return true;
        if (!classroom.school) return false;
        return !(classroomCreatorRegion === region || classroomSchoolRegion === region);
      }
      return true;
    })();

    return (
      <>
        <Tabs
          value={activeTab}
          onChange={(e, value) => setActiveTab(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Detail projektu"
            value={ProjectModalTabs.PROJECT_DETAIL}
          />
          {!isCoreUser ? (
            <Tab
              label="Stav projektu"
              value={ProjectModalTabs.PROJECT_STATE}
            />
          ) : null}
          <Tab label="Pobočka" value={ProjectModalTabs.BRANCH} />
          <Tab label="Škola" value={ProjectModalTabs.SCHOOL} />
          {classroom.projectType === "ZVS"
            ? <Tab label="Třídy" value={ProjectModalTabs.CLASSROOMS} />
            : null}
          <Tab label="Toolbox" value={ProjectModalTabs.TOOLBOX} />
          {classroom.projectType === "AP"
            ? <Tab label="Jarmark" value={ProjectModalTabs.FAIR} />
            : null}
          {!isCoreUser
            ? <Tab label="Uživatelé" value={ProjectModalTabs.USERS} />
            : null}
          {!isCoreUser && classroom.type !== "CORE"
            ? <Tab label="Zprávy" value={ProjectModalTabs.MESSAGES} />
            : null}
          <Tab label="Poznámka" value={ProjectModalTabs.NOTE} />
          <Tab label="Fotografie" value={ProjectModalTabs.PHOTOS} />
        </Tabs>
        <TabPanel value={activeTab} id={ProjectModalTabs.PROJECT_DETAIL}>
          <ProjectForm
            classroom={classroom}
            onClose={onClose}
            editDisabled={editDisabled}
          />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.PROJECT_STATE}>
          <ProjectState classroom={classroom} userRoles={roles} />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.BRANCH}>
          <BranchForm
            classroom={classroom}
            onClose={onClose}
            editDisabled={editDisabled}
          />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.SCHOOL}>
          <SchoolForms
            classes={classes}
            classroomId={classroom.id}
            editDisabled={editDisabled}
          />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.CLASSROOMS}>
          <SubclassroomsTab classroom={classroom} />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.TOOLBOX}>
          {classroom?.projectType === "ZVS"
            ? (classroom?.subclassrooms || []).map((subclassroom) => (
              <div key={subclassroom.id} style={{ marginTop: "30px" }}>
                <h3>{subclassroom.name}</h3>
                <ToolboxForm
                  toolbox={subclassroom.toolboxOrder}
                  classroomId={classroom.id}
                  classroomQuery={query}
                  editDisabled={editDisabled}
                  classroom={classroom}
                  subclassroomId={subclassroom.id}
                />
                <hr />
              </div>
            ))
            : (
              <ToolboxForm
                toolbox={classroom.toolboxOrder}
                classroomId={classroom.id}
                classroomQuery={query}
                editDisabled={editDisabled}
                classroom={classroom}
              />
            )}
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.FAIR}>
          <FairForm
            classroom={classroom}
            onClose={onClose}
            editDisabled={editDisabled}
          />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.USERS}>
          <TeamUsersForm team={classroom.team} />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.MESSAGES}>
          <MessagesForm team={classroom.team} refetch={() => query.refetch()} />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.NOTE}>
          <AdminNoteForm
            classroom={classroom}
            editDisabled={editDisabled}
          />
        </TabPanel>
        <TabPanel value={activeTab} id={ProjectModalTabs.PHOTOS}>
          <ProjectFiles
            classroom={classroom}
            editDisabled={editDisabled}
          />
        </TabPanel>
      </>
    );
  };

  return (
    <Dialog
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="xl"
      classes={{ paperWidthXl: classes.paper }}
    >
      <DialogTitle>Detail projektu</DialogTitle>
      <DialogContent>
        {loading ? <CircularProgress /> : renderLoaded()}
      </DialogContent>
    </Dialog>
  );
};

interface ProjectModalQuery {
  classroom: ClassroomAttributes;
}

const projectModalQuery = graphql<Props>(gql`
  query Classroom($id: ID!) {
    classroom(id: $id) {
      ${classroomAttributes}
    }
  }
`,
{
  name: "projectModalQuery",
  options: (props) => ({
    variables: {
      id: props.classroomId
    }
  })
}
);

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(withStyles(styles), projectModalQuery)(ProjectModal as any) as ComponentType<Props>;
