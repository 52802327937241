import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import gql from "graphql-tag";
import React, { ComponentType, FC } from "react";
import { graphql, MutationFunc } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";

interface Props {
  onClose: (refetch?: boolean) => unknown;
  classroom: ClassroomAttributes;
}

interface InnerProps {
  finishMutation: MutationFunc<{ error?: unknown }, { id: string }>;
}

const FinishConfirmationModal: FC<Props & InnerProps> = ({ onClose, classroom, finishMutation }) => (
  <Dialog
    open
    onClose={() => onClose(false)}
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle>Skutečně chcete projekt ukončit?</DialogTitle>
    <DialogContent>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={() => onClose(false)}>NE</Button>
        <Button
          onClick={() => {
            finishMutation({
              variables: {
                id: classroom.id
              }
            }).then((res) => {
              if (!(res as any).error) {
                onClose(true);
              }
            }).catch(() => {
              onClose(false);
            });
          }}
        >
          ANO
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

const finishMutation = graphql<Props>(gql`
    mutation Finish($id: ID!) {
        finishClassroom(id: $id) {
            id
            phases {
                id
                finished
                finishDate
                checklist {
                    id
                    checked
                }
            }
        }
    }
`, {
  name: "finishMutation"
});

export default finishMutation(FinishConfirmationModal as any) as ComponentType<Props>;
