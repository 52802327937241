import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { DropzoneArea } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import React, { ComponentType, FC, useState } from "react";
import { graphql, MutationFunc } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";

interface Props {
  classroom: ClassroomAttributes;
  onClose: (refetch?: boolean) => unknown;
}

interface InnerProps {
  classes: any;
  uploadFileMutation: MutationFunc<{}, { file: any; phaseId: string; fileExt: string }>;
}

const UploadPhotoDialog: FC<Props & InnerProps> = ({
  onClose,
  classes,
  classroom,
  uploadFileMutation
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      classes={{
        paperWidthXl: classes.paper
      }}
    >
      <DialogTitle>Detail projektu</DialogTitle>
      <DialogContent>
        <DropzoneArea
          acceptedFiles={["image/*"]}
          dropzoneText="Obrázky přetáhněte sem nebo kliknutím vyberte"
          onChange={setFiles}
          dropzoneParagraphClass={classes.dropzoneParagraph}
          filesLimit={300}
          maxFileSize={10000000}
        />
        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => {
              // console.log("Upload files", files);
              setLoading(true);
              const uploadPromises = files?.map((file) => uploadFileMutation({
                variables: {
                  phaseId: classroom.phases[0].id,
                  file,
                  fileExt: file.type.replace("image/", "")
                }
              }));
              Promise.all(uploadPromises ?? []).then(() => {
                setLoading(false);
                enqueueSnackbar("Vsechny soubory nahrany", { variant: "success" });
                onClose();
              });
            }}
          >
            {loading ? <CircularProgress /> : null} Nahrát
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const styles = theme => ({
  paper: {
    alignSelf: "flex-start"
  },
  dropzoneParagraph: {
    fontFamily: theme.typography.fontFamily
  }
});

export default compose(
  graphql(gql`
        mutation UploadFileMutation($file: Upload!, $phaseId: ID!, $fileExt: String!) {
            uploadFile(file: $file, phaseId: $phaseId, fileType: PHOTO, fileExt: $fileExt) {
                id
                files {
                    id
                    name
                    fileType
                    path
                    author {
                        id
                        firstname
                        lastname
                    }
                }
            }
        }
    `, {
    name: "uploadFileMutation"
  }),
  withStyles(styles)
)(UploadPhotoDialog) as any as ComponentType<Props>;
