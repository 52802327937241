import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  height: auto;
`;
const Card = styled.div`
  width: 400px;
  padding: 12px;
  margin: 10px;
  box-shadow: 1px 1px 5px 1px grey;
`;
const Image = styled.img`
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const Body = styled.div`
`;
const ProductCard = ({ eFair }) => {
  return (
    <>
      <Wrap>
        {eFair.products
          ? eFair.products.map((product, i) => {
            return (
              <Card key={i}>
                <Image src={`/api/files/${product.photoLink}`}></Image>
                <Body>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="productName">
                      Název výrobku
                    </InputLabel>
                    <Input
                      id="productName"
                      name="productName"
                      value={product.name}
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="description">Popisek</InputLabel>
                    <Input
                      id="description"
                      name="description"
                      value={product.description}
                    />
                  </FormControl>
                  <FormControl margin="normal">
                    <InputLabel htmlFor="price">Cena za kus</InputLabel>
                    <Input id="price" name="price" value={product.price} />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    style={{
                      float: "right"
                    }}
                  >
                    <InputLabel htmlFor="amount">Počet kusů</InputLabel>
                    <Input id="amount" name="amount" value={product.amount} />
                  </FormControl>
                </Body>
              </Card>
            );
          })
          : "Žádné produkty"}
      </Wrap>
    </>
  );
};
export default ProductCard;
