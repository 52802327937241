import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import prop from "ramda/src/prop";

import ToolboxForm from "./forms/ToolboxForm";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const ToolboxModal = ({
  onClose,
  classes,
  toolbox
}) => {
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{
        paperWidthMd: classes.paper
      }}
    >
      <DialogTitle>Detail toolboxu</DialogTitle>
      <DialogContent>
        <ToolboxForm
          toolbox={toolbox}
          onClose={onClose}
          classroom={prop("classroom")(toolbox) ?? prop("subclassroom")(toolbox)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ToolboxModal);
