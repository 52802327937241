import gql from "graphql-tag";
import classroomAttributes from "../constants/classroomAttributes";

export type UpdateClassroomMutationParams = Partial<{
  id: string;
  classroomName: string;
  schoolAddress: string;
  schoolMeeting: unknown;
  excursionDate: unknown;
  visitInProduction: unknown;
  coffeeWithTeacher: unknown;
  directorName: string;
  directorPhone: string;
  directorEmail: string;
  teacherName: string;
  teacherEmail: string;
  teacherPhone: string;
  branchRepresentativeName: string;
  branchRepresentativeEmail: string;
  branchRepresentativePhone: string;
  branchAddress: string;
  fairDate: unknown;
  fairTime: unknown;
  semester: number;
  year: number;
  moneyGoalAmount: string;
  companyName: string;
  businessPurpose: string;
  businessDescription: string;
  childrenCount: string;
  kioskReadyTime: unknown;
  fairEnd: unknown;
  kioskPlace: string;
  fairNote: string;
  fairElectricity: string;
  fairAnnexationState: string;
  fairAnnexationNote: string;
  fairAgency: any;
  forgiveLoan: string;
  forgiveLoanCause: string;
  nps: string;
  area: string;
  eFairProfit: string;
  schoolFirstContact: unknown;
}>;

const updateClassroomMutation = gql`
  mutation UpdateClassroomMutation(
    $id: ID!
    $classroomName: String
    $schoolAddress: String
    $schoolMeeting: DateTime
    $excursionDate: DateTime
    $visitInProduction: DateTime
    $coffeeWithTeacher: DateTime
    $directorName: String
    $directorPhone: String
    $directorEmail: String
    $teacherName: String
    $teacherEmail: String
    $teacherPhone: String
    $branchRepresentativeName: String
    $branchRepresentativeEmail: String
    $branchRepresentativePhone: String
    $branchAddress: String
    $fairDate: DateTime
    $fairTime: DateTime
    $semester: Int
    $year: Int
    $moneyGoalAmount: String
    $companyName: String
    $businessPurpose: String
    $businessDescription: String
    $childrenCount: String
    $kioskReadyTime: DateTime
    $fairEnd: DateTime
    $kioskPlace: String
    $fairNote: String
    $fairElectricity: String
    $fairAnnexationState: String
    $fairAnnexationNote: String
    $fairAgency: FairAgencyInput
    $forgiveLoan: String
    $forgiveLoanCause: String
    $nps: String
    $area: String
    $eFairProfit: String
    $schoolFirstContact: DateTime
  ) {
    updateClassroom(
      data: {
        id: $id
        schoolAddress: $schoolAddress
        classroomName: $classroomName
        teacherName: $teacherName
        teacherPhone: $teacherPhone
        branchAddress: $branchAddress
        semester: $semester
        year: $year
        moneyGoalAmount: $moneyGoalAmount
        schoolMeeting: $schoolMeeting
        visitInProduction: $visitInProduction
        coffeeWithTeacher: $coffeeWithTeacher
        excursionDate: $excursionDate
        directorName: $directorName
        directorPhone: $directorPhone
        directorEmail: $directorEmail
        teacherEmail: $teacherEmail
        branchRepresentativeName: $branchRepresentativeName
        branchRepresentativeEmail: $branchRepresentativeEmail
        branchRepresentativePhone: $branchRepresentativePhone
        fairDate: $fairDate
        companyName: $companyName
        businessPurpose: $businessPurpose
        businessDescription: $businessDescription
        childrenCount: $childrenCount
        kioskReadyTime: $kioskReadyTime
        fairEnd: $fairEnd
        fairTime: $fairTime
        kioskPlace: $kioskPlace
        fairNote: $fairNote
        fairElectricity: $fairElectricity
        fairAnnexationState: $fairAnnexationState
        fairAnnexationNote: $fairAnnexationNote
        fairAgency: $fairAgency
        forgiveLoanCause: $forgiveLoanCause
        forgiveLoan: $forgiveLoan
        nps: $nps
        area: $area
        eFairProfit: $eFairProfit
        schoolFirstContact: $schoolFirstContact
      }
    ) {
      ${classroomAttributes}
    }
  }
`;

export default updateClassroomMutation;
