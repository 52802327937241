import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import descend from "ramda/src/descend";
import React, { FC, useState } from "react";

import { any } from "../../utils/permissions";
import CreateMessageModal from "../CreateMessageModal";
import { useUser } from "../UserContext";

interface Props {
  team: { id: string } & ({ Message: Message[] } | { messages: Message[] });
  refetch: () => unknown;
}

type Message = {
  id: string;
  createdAt: string;
  text: string;
} & ({
  User: {
    firstname: string;
    lastname: string;
  };
} | {
  author: {
    firstname: string;
    lastname: string;
  };
});

const MessagesForm: FC<Props> = ({ team, refetch }) => {
  const { Role } = useUser();
  const [messageTeamId, setMessageTeamId] = useState(null);
  const isAdmin = any(["SUPER_ADMIN", "ADMIN"], Role);

  const normalizedTeam = {
    ...team,
    Message: "Message" in team ? team.Message : team.messages
  };

  return (
    <>
      {messageTeamId ? (
        <CreateMessageModal
          teamId={messageTeamId}
          onSubmit={refetch}
          onClose={() => {
            setMessageTeamId(null);
          }}
        />
      ) : null}
      {isAdmin ? (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={() => {
            setMessageTeamId(normalizedTeam.id);
          }}
        >
          Odeslat novou zprávu
        </Button>
      ) : null}
      <List>
        {[...normalizedTeam.Message || []]
          .sort(descend(x => x.createdAt))
          .map((m) => {
            const nm = { ...m, User: "User" in m ? m.User : m.author };
            return (
              <React.Fragment key={nm.id}>
                <ListItem>
                  <ListItemText
                    primary={nm.text}
                    secondary={`${nm.User.firstname} ${nm.User.lastname} (${moment(m.createdAt).format("L LT")})`}
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            );
          })}
      </List>
    </>
  );
};

export default MessagesForm;
