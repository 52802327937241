import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { scrollToTop } from "../../utils/table";
import { DataGridState } from "./useDataGridLocalStorage";

interface Options {
  state: DataGridState;
  columns: MUIDataTableColumn[];
  setState: Dispatch<SetStateAction<DataGridState>>;
  options: MUIDataTableOptions;
}

export const useDataGridOptions = ({ state, columns, setState, options }: Options): MUIDataTableOptions => {
  const onFilterChange = useCallback<MUIDataTableOptions["onFilterChange"]>((name, values) => {
    const index = columns.findIndex(c => c.name === name);
    setState(s => ({ ...s, filters: { ...s.filters, [name as any]: values[index] } }));
  }, [columns, setState]);

  const onTableChange = useCallback<MUIDataTableOptions["onTableChange"]>((actionName, tableData) => {
    if (actionName === "changePage") {
      scrollToTop();
    }

    if (actionName === "viewColumnsChange") {
      setState(s => ({ ...s, columns: tableData.columns.filter(c => (c as any).display !== "false").map(c => c.name) }));
    }

    if (actionName === "sort") {
      setState(s => ({ ...s, sort: tableData.sortOrder }));
    }
  }, [setState]);

  return useMemo(() => ({
    ...staticOptions,
    onFilterChange,
    onTableChange,
    sortOrder: state.sort,
    ...options
  }), [onFilterChange, onTableChange, options, state.sort]);
};

const staticOptions: MUIDataTableOptions = {
  filterType: "multiselect" as const,
  selectableRows: "none" as const,
  fixedHeader: true,
  download: false,
  print: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [10, 50, 100, 200, 500, 1000],
  customSearch: (searchQuery, row, columns) => {
    return row.some((c: any, i) => {
      if (columns[i].search) {
        return columns[i].search(searchQuery, c);
      }
      if (typeof c === "string") {
        return c.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase());
      }
      if (Array.isArray(c)) {
        return c.filter(item => !!item && typeof item === "string")
          .findIndex(item => item.toLowerCase().startsWith(searchQuery.toLowerCase())) !== -1;
      }
      return false;
    });
  }
};
