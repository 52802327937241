import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import pathOr from "ramda/src/pathOr";
import { useState } from "react";
import { graphql } from "react-apollo";

import EFairManagement from "../components/EFairManagement.component";
import FairState from "../components/FairState.component";
import EFairDataForm from "../components/forms/EFairDataForm.component";
import EFairManager from "../components/forms/EFairManager.component";
import TabPanel from "../components/TabPanel";
import { useUser } from "../components/UserContext";
import EFairModalTabs from "../constants/EFairModalTabs";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const EFairModal = ({
  onClose,
  classes,
  eFairQuery: { eFair = {}, ...eFairQuery },
  defaultTab,
}) => {
  const user = useUser();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const userRegion = user.region;
  const userRoles = user.Role.map(r => r.name);
  const classroomSchoolRegion = pathOr("", ["classroom", "school", "region"])(
    eFair
  );
  const classroomCreatorRegion = pathOr("", ["classroom", "creator", "region"])(
    eFair
  );
  const classroomType = pathOr("", ["classroom", "type"])(eFair);
  const editDisabled = () => {
    if (contains("ADMIN")(userRoles)) {
      return false;
    }
    if (contains("CORE")(userRoles)) {
      if (classroomType !== "CORE") return true;
      if (!eFair.classroom.school) return false;
      return !(
        classroomCreatorRegion === userRegion ||
        classroomSchoolRegion === userRegion
      );
    }
  };
  const editable = !editDisabled();

  return (
    <Dialog
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      classes={{
        paperWidthXl: classes.paper
      }}
    >
      <FairState formState={eFair ? eFair.approval : null} />
      <DialogTitle>Detail e-jarmarku</DialogTitle>
      <DialogContent>
        {eFairQuery.loading
          ? <CircularProgress />
          : (
            <>
              <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Data" value={EFairModalTabs.DATA} />
                <Tab label="Správce" value={EFairModalTabs.MANAGER} />
                {editable ? (
                  <Tab
                    label="Správa e-jarmarku"
                    value={EFairModalTabs.EFAIR_MANAGEMENT}
                  />
                ) : null}
              </Tabs>
              <TabPanel value={activeTab} id={EFairModalTabs.DATA}>
                <EFairDataForm
                  eFair={eFair}
                  isAdmin={editable}
                  onClose={onClose}
                />
              </TabPanel>
              <TabPanel value={activeTab} id={EFairModalTabs.MANAGER}>
                <EFairManager eFair={eFair} />
              </TabPanel>
              <TabPanel value={activeTab} id={EFairModalTabs.EFAIR_MANAGEMENT}>
                <EFairManagement
                  eFair={eFair}
                  onClose={onClose}
                />
              </TabPanel>
            </>
          )}
      </DialogContent>
    </Dialog>
  );
};

const eFairQuery = graphql(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        fairManager {
          id
          fullName
          email
        }
        state
        approval
        approvalText
        videoLink
        contactEmail
        goal
        managerNote
        images {
          id
          path
        }
        introText
        from
        to
        consent
        issuingPlace {
          id
          streetAndNumber
          city
          zipCode
          note
        }
        products {
          photoLink
          name
          description
          price
          amount
        }
        issuingTime {
          from
          to
        }
        classroom {
          id
          type
          school {
            id
            region
          }
          creator {
            id
            region
          }
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.eFair.id
      }
    })
  }
);

export default compose(withStyles(styles), eFairQuery)(EFairModal);
