import { withStyles } from "@material-ui/core";
import Archive from "@material-ui/icons/Archive";
import Assessment from "@material-ui/icons/Assessment";
import Domain from "@material-ui/icons/Domain";
import Money from "@material-ui/icons/Money";
import People from "@material-ui/icons/People";
import Subject from "@material-ui/icons/Subject";
import Today from "@material-ui/icons/Today";
import Work from "@material-ui/icons/Work";
import React, { FC } from "react";

import Regions from "../../constants/Regions";
import { MenuItem } from "./MenuItem";
import styles from "./styles";

interface Props {
  classes: Record<string, string>;
}

const CoreMenu: FC<Props> = ({ classes }) => {
  return (
    <>
      <MenuItem icon={<Subject />} label="Roadmapy" classes={classes}>
        <MenuItem icon={<Today />} label="Akce a eventy" to="/roadmap" nested classes={classes} />
        <MenuItem icon={<Money />} label="Budgety" to="/budgets" nested classes={classes} />
      </MenuItem>

      <MenuItem icon={<Subject />} label="Podnikavá třída" classes={classes}>
        <MenuItem icon={<Subject />} label="Projekty" to="/classrooms-management" nested classes={classes} />
        <MenuItem icon={<Subject />} label="eJarmarky" to="/e-fairs" nested classes={classes} />
      </MenuItem>

      <MenuItem icon={<Subject />} label="ZVŠ" to="/classrooms-management-zvs" classes={classes} />

      <MenuItem icon={<People />} label="AP regiony" classes={classes}>
        <MenuItem icon={<Subject />} label="Podnikavá třída" nested classes={classes}>
          {Regions.map(r => (
            <MenuItem
              key={r}
              label={r}
              to={`/core-region-ap/${r}`}
              nested
              classes={classes}
            />
          ))}
        </MenuItem>
        <MenuItem icon={<Subject />} label="ZVŠ" nested classes={classes}>
          {Regions.map(r => (
            <MenuItem
              key={r}
              label={r}
              to={`/core-region-zvs/${r}`}
              nested
              classes={classes}
            />
          ))}
        </MenuItem>
      </MenuItem>

      <MenuItem icon={<Domain />} label="Školy" to="/schools" classes={classes} />
      <MenuItem icon={<Work />} label="Toolboxy" to="/toolboxes" classes={classes} />
      <MenuItem icon={<Assessment />} label="Reporty" to="/statistics" classes={classes} />

      <MenuItem icon={<Archive />} label="Archiv" classes={classes}>
        <MenuItem icon={<Archive />} label="Podnikavá třída" to="/archive" nested classes={classes} />
        <MenuItem icon={<Archive />} label="ZVŠ" to="/archive-zvs" nested classes={classes} />
      </MenuItem>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(CoreMenu);
