import moment from "moment";
import reverse from "ramda/src/reverse";
import sort from "ramda/src/sort";

import { getActivePhase } from "../components/ProjectsTable/getInitialColumns";

const extractItem = (inputData, defaultReturnValue) => {
  let item;
  if (inputData.length > 0) {
    if (Array.isArray(inputData)) {
      item = inputData[0];
    } else {
      item = inputData;
    }
  } else {
    item = defaultReturnValue;
  }
  return item;
};

export const sortStringInput = (colIndex, data, order) => {
  const sortedData = sort(
    (a, b) => {
      const itemA = extractItem(a.data[colIndex], "-");
      const itemB = extractItem(b.data[colIndex], "-");
      if (!!itemA && !!itemB) {
        if (order === "asc") {
          return itemA.localeCompare(itemB);
        } else {
          return itemB.localeCompare(itemA);
        }
      }
      return 1;
    },
    data
  );
  return reverse(sortedData);
};

export const sortMomentInput = (colIndex, data, order, format = "DD.MM.YYYY") => {
  const sortedData = sort((a, b) => {
    const itemA = extractItem(a.data[colIndex], "-");
    const itemB = extractItem(b.data[colIndex], "-");

    if (!!itemA && itemA !== "-" && !!itemB && itemB !== "-") {
      if (order === "asc") {
        return moment(itemA, format) - moment(itemB, format);
      } else {
        return moment(itemB, format) - moment(itemA, format);
      }
    }
    return 1;
  }, data);
  return reverse(sortedData);
};

export const sortProjectStatusInput = (colIndex, data, order) => {
  const sortedData = sort(
    (a, b) => {
      const activePhaseA = getActivePhase({ phases: a.data[colIndex] });
      const activePhaseB = getActivePhase({ phases: b.data[colIndex] });
      const itemA = activePhaseA ? `${activePhaseA.number}/${activePhaseA.length}: ${activePhaseA.name}` : "Dokončeno";
      const itemB = activePhaseB ? `${activePhaseB.number}/${activePhaseB.length}: ${activePhaseB.name}` : "Dokončeno";
      if (!!itemA && !!itemB) {
        if (order === "asc") {
          return itemA.localeCompare(itemB);
        } else {
          return itemB.localeCompare(itemA);
        }
      }
      return 1;
    },
    data
  );
  return reverse(sortedData);
};
