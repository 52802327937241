import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import { FC, useState } from "react";
import { graphql, MutationFn } from "react-apollo";

interface Props {
  classroom: { id: string; adminNote?: string };
  editDisabled?: boolean;
}

interface InnerProps {
  updateClassroomMutation: MutationFn<unknown, { id: string; adminNote: string }>;
}

const AdminNoteForm: FC<Props & InnerProps> = ({ updateClassroomMutation, editDisabled, classroom }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [adminNote, setAdminNote] = useState(classroom.adminNote || "");

  return (
    <>
      <FormControl margin="normal" required fullWidth>
        <Input
          id="adminNote"
          name="adminNote"
          autoFocus
          autoComplete="off"
          value={adminNote}
          multiline
          rowsMax="8"
          rows="4"
          onChange={(e) => setAdminNote(e.target.value)}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={() => {
          if (classroom) {
            updateClassroomMutation({
              variables: {
                id: classroom.id,
                adminNote
              }
            }).then(() => {
              enqueueSnackbar("Projekt byl úspěšně uložen", {
                variant: "success",
                autoHideDuration: 4000,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              });
            });
          }
        }}
        disabled={editDisabled}
      >
        Uložit
      </Button>
    </>
  );
};

export default compose(
  graphql(
    gql`
      mutation UpdateClassroom($id: ID!, $adminNote: String!) {
        updateClassroom(data: { id: $id, adminNote: $adminNote }) {
          id
          adminNote
        }
      }
      `,
    {
      name: "updateClassroomMutation"
    }
  )
)(AdminNoteForm as any) as any as FC<Props>;
