import gql from "graphql-tag";
import { graphql } from "react-apollo";

const approveEFairMutation = graphql(
  gql`
    mutation ApproveEFairMutation($id: ID!, $notifyTeam: Boolean) {
      approveEFair(data: { id: $id, notifyTeam: $notifyTeam }) {
        id
      }
    }
  `,
  {
    name: "approveEFairMutation"
  }
);

export default approveEFairMutation;
