import "./styles/index.css";
import "moment/locale/cs";

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import { useEffect, useMemo } from "react";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Authenticated from "./Authenticated";
import initApolloClient from "./initApolloClient";
import ForgotPassword from "./screens/ForgotPassword";
import Login from "./screens/Login";
import store from "./store";
import { ApolloLink, concat } from "apollo-link";

const history = createBrowserHistory();
Sentry.init({
  dsn: "https://42d43eed85fa48bdad334f617aa0144b@o321242.ingest.sentry.io/6525165",
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
  })],
  tracesSampleRate: 0
});

const link = new HttpLink({ uri: process.env.NODE_ENV === "development" ? "http://localhost:4001/graphql" : "/api/v2" });

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      Authorization: token
    }
  });
  return forward(operation);
});

export const apollo2Client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(middlewareLink, link)
});

const theme = createTheme({
  overrides: {
    MuiPopover: {
      root: {
        "z-index": "99999999 !important"
      }
    },
    MuiSnackbar: {
      root: {
        "z-index": "99999999 !important"
      }
    },
    MuiDialog: {
      root: {
        "z-index": "300000 !important"
      }
    },
    MuiTableCell: {
      root: {
        padding: "3px 6px"
      }
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "100%"
      }
    }
  }
});

const App = () => {
  const client = useMemo(() => initApolloClient(), []);

  useEffect(() => void moment.locale("cs"), []);

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="cs">
          <Provider store={store}>
            <SnackbarProvider maxSnack={3}>
              <Router>
                <Switch>
                  <SentryRoute path="/forgot-password" component={ForgotPassword} />
                  <SentryRoute path="/login" component={Login} />
                  <SentryRoute path="/" component={Authenticated} />
                </Switch>
              </Router>
            </SnackbarProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default App;

export const SentryRoute = Sentry.withSentryRouting(Route);
