import { PropTypes } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FC, MouseEventHandler, ReactNode } from "react";

interface Props {
  value: ReactNode;
  classes?: any;
  editDisabled?: boolean;
  actionHandler?: MouseEventHandler<HTMLButtonElement>;
  color: PropTypes.Color;
}

const SubmitButton: FC<Props> = ({ value, classes, editDisabled, actionHandler, color }) => {
  return (
    <Button
      style={{
        margin: 5
      }}
      fullWidth
      variant="contained"
      color={color}
      className={classes ? classes.submit : null}
      onClick={actionHandler}
      disabled={editDisabled}
    >
      {value}
    </Button>
  );
};

export default SubmitButton;
