import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;
const Image = styled.img`
  margin: 10px;
  height: 45%;
  width: 45%;
`;
const EFairMedia = ({ eFair }) => {
  return (
    <>
      <Wrap>
        {eFair.images
          ? eFair.images.map((image) => {
            return (
              <>
                <Image src={`/api/files/${image.path}`}></Image>
              </>
            );
          })
          : null}
      </Wrap>
    </>
  );
};
export default EFairMedia;
