import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "../../utils/useLocalStorage";

interface Props {
  icon?: ReactElement;
  label: string;
  classes: Record<string, string>;
  nested?: boolean;

  initiallyOpen?: boolean;
}

interface CollapseProps {
  children: ReactElement[];
}

interface ChildProps {
  to: string;
}

export const MenuItem = ({ icon, label, classes, initiallyOpen, ...p }: Props & (CollapseProps | ChildProps)): ReactElement => {
  const [collapse] = useLocalStorage("menu-collapse", false);
  const [state, setState] = useState(!!initiallyOpen);

  return (
    <>
      {"children" in p && (
        <ListItem
          button
          onClick={() => setState(s => !s)}
          {...p.nested && { className: classes?.nested }}
        >
          {icon && (
            <ListItemIcon>
              {icon}
            </ListItemIcon>
          )}
          {!collapse && <ListItemText primary={label} />}
          {!collapse && (state ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      )}
      {"to" in p && (
        <ListItem
          button
          component={NavLink}
          {...{ exact: true } as any}
          to={p.to}
          {...p.nested && { className: classes?.nested }}
          activeClassName={classes?.active}
        >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          {!collapse && <ListItemText primary={label} />}
        </ListItem>
      )}

      {"children" in p && !collapse && (
        <Collapse in={state} timeout="auto" unmountOnExit>
          <List {...{ component: "div" } as any} disablePadding>
            {p.children}
          </List>
        </Collapse>
      )}
    </>
  );
};
