import { withStyles } from "@material-ui/core";
import Archive from "@material-ui/icons/Archive";
import Assessment from "@material-ui/icons/Assessment";
import Delete from "@material-ui/icons/Delete";
import Domain from "@material-ui/icons/Domain";
import Message from "@material-ui/icons/Message";
import Money from "@material-ui/icons/Money";
import Mood from "@material-ui/icons/Mood";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Subject from "@material-ui/icons/Subject";
import Today from "@material-ui/icons/Today";
import Work from "@material-ui/icons/Work";
import React, { FC, useState } from "react";

import { MenuItem } from "./MenuItem";
import styles from "./styles";

interface Props {
  classes: Record<string, string>;
}

const AdminMenu: FC<Props> = React.memo(({ classes }) => {
  return (
    <>
      <MenuItem icon={<Subject />} label="AP Dobrovolníci" classes={classes} initiallyOpen>
        <MenuItem icon={<Subject />} label="Podnikavá třída" to="/" nested classes={classes} />
        <MenuItem icon={<Subject />} label="ZVŠ" to="/zvs-projects" nested classes={classes} />
      </MenuItem>
      <MenuItem icon={<Domain />} label="Školy" to="/schools" classes={classes} />
      <MenuItem icon={<Work />} label="Toolboxy" to="/toolboxes" classes={classes} />
      <MenuItem icon={<Mood />} label="Jarmarky" to="/fairs" classes={classes} />
      <MenuItem icon={<Subject />} label="eJarmarky" to="/e-fairs" classes={classes} />

      <MenuItem icon={<Subject />} label="AP RMKT tým" classes={classes}>
        <MenuItem icon={<Subject />} label="Podnikavá třída" to="/core-projects-ap" nested classes={classes} />
        <MenuItem icon={<Subject />} label="ZVŠ" to="/core-projects-zvs" nested classes={classes} />
      </MenuItem>

      <MenuItem icon={<Subject />} label="Roadmapy" classes={classes}>
        <MenuItem icon={<Today />} label="Akce a eventy" to="/roadmap" nested classes={classes} />
        <MenuItem icon={<Money />} label="Budgety" to="/budgets" nested classes={classes} />
      </MenuItem>

      <MenuItem icon={<People />} label="Správa týmů" to="/teams" classes={classes} />
      <MenuItem icon={<Person />} label="Uživatelé" to="/users" classes={classes} />
      <MenuItem icon={<Message />} label="Forum" to="/forum" classes={classes} />
      <MenuItem icon={<Assessment />} label="Reporty" to="/statistics" classes={classes} />

      <MenuItem icon={<Archive />} label="Archiv" classes={classes}>
        <MenuItem icon={<Archive />} label="Podnikavá třída" to="/archive" nested classes={classes} />
        <MenuItem icon={<Archive />} label="ZVŠ" to="/archive-zvs" nested classes={classes} />
      </MenuItem>

      <MenuItem icon={<Delete />} label="Koš" to="/deleted" classes={classes} />
    </>
  );
});

export default withStyles(styles, { withTheme: true })(AdminMenu);
