import gql from "graphql-tag";
import { graphql } from "react-apollo";

import TableLayout from "../components/Layout/TableLayout";
import RoadmapEventModals from "../components/RoadmapEventModals";
import RoadmapEventsTable from "../components/RoadmapEventsTable";
import roadmapEventAttributes from "../constants/roadmapEventAttributes";

const Roadmap = ({ roadmapQuery }) => (
  <TableLayout title="Akce RMKT">
    <RoadmapEventModals roadmapQuery={roadmapQuery} />
    <RoadmapEventsTable id="roadmap" query={roadmapQuery} />
  </TableLayout>
);

const roadmapQuery = graphql(gql`
query RoadmapEvents {
    roadmapEvents {
        ${roadmapEventAttributes}
    }
}
`, {
  name: "roadmapQuery",
  options: {
    fetchPolicy: "network-only"
  }
});

export default roadmapQuery(Roadmap);
