import { SnackbarContent } from "@material-ui/core";
import { Component, FC, ComponentType } from "react";
import * as Sentry from "@sentry/react";

export const ErrorBoundary: FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(
        <SnackbarContent
          message="Nastala nečekaná chyba"
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const withErrorBoundary = <P extends object>(C: ComponentType<P>): ComponentType<P> => p =>
  <ErrorBoundary><C {...p} /></ErrorBoundary>
;
