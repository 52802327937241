import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import { ComponentType, FC, useMemo } from "react";
import { DataValue } from "react-apollo";
import { connect } from "react-redux";

import { RoadmapAttributes } from "../constants/roadmapEventAttributes";
import { setCreateEventModal, setDataSorted, setEditEventModal } from "../model/roadmap/actions";
import { sortMomentInput, sortStringInput } from "../utils/sort";
import { DataGrid } from "./DataGrid";
import { useDataGrid } from "./DataGrid/useDataGrid";

interface Props {
  id: string;
  query: DataValue<{ roadmapEvents: RoadmapAttributes[] }>;
}

interface InnerProps {
  setCreateEventModal: (refetch: boolean) => unknown;
  setEditEventModal: (eventId: string) => unknown;
}

const RoadmapEventsTable: FC<Props & InnerProps> = ({
  id,
  query,
  setCreateEventModal,
  setEditEventModal
}) => {
  const options: MUIDataTableOptions = {
    customSort: (data, colIndex, order) => {
      setDataSorted(colIndex, order);
      switch (colIndex) {
        case 1:
        case 2:
        case 3:
        case 10:
        case 11:
        case 12:
        case 14:
        case 15:
        case 16:
          return sortStringInput(colIndex, data, order);
        case 4:
        case 13:
          return sortMomentInput(colIndex, data, order);
        default:
          break;
      }
      const sorted = [...data].sort((a, b) => {
        let intA = 0;
        let intB = 0;
        try {
          intA = parseInt(a.data[colIndex], 10);
          intA = isNaN(intA) ? 0 : intA;
        } catch (e) {
          // nothing..
        }
        try {
          intB = parseInt(b.data[colIndex], 10);
          intB = isNaN(intB) ? 0 : intB;
        } catch (e) {
          // nothing..
        }
        return intA - intB;
      });
      if (order === "asc") {
        return sorted;
      }
      return [...sorted].reverse();
    },
    onCellClick: (colData, { colIndex, dataIndex }) => {
      if (colIndex === 12) return;
      if (colIndex === 13) return;
      if (colIndex === 14) return;

      setEditEventModal(query.roadmapEvents[dataIndex].id);
    }
  };

  const dataGrid = useDataGrid({ id, columns, options });

  const data = useMemo(() => (query.roadmapEvents ?? []).map((event) => {
    return [
      <Edit key={0} />,
      event.region || "-",
      event.segment || "-",
      event.name || "-",
      [
        event.from ? moment(event.from) : "?",
        event.to ? moment(event.to) : "?"
      ],
      (event.budgetMMA || "-") + "Kč",
      (event.budgetMSE || "-") + "Kč",
      (event.budgetEXHYP || "-") + "Kč",
      (event.overBudget || "-") + "Kč",
      event.nps || "-",
      event.evaluation || "-",
      event.internalClient || "-",
      event.address || "-",
      [event.from ? moment(event.from) : "?"],
      event.finMaterial || "-",
      event.photoLink || "-",
      event.note || "-"
    ];
  }), [query.roadmapEvents]);

  return (
    <DataGrid
      {...dataGrid}
      loading={query.loading}
      data={data}
      toolbar={(
        <Button variant="contained" color="primary" onClick={() => setCreateEventModal(true)}>
          Vytvořit akci
        </Button>
      )}
    />
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = { setCreateEventModal, setEditEventModal };

export default connect(mapStateToProps, mapDispatchToProps)(RoadmapEventsTable as any) as ComponentType<Props>;

const columns = [
  {
    name: "",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Region",
    options: {
      filter: true
    }
  },
  {
    name: "Segment",
    options: {
      filter: true
    }
  },
  {
    name: "Název akce",
    options: {
      filter: false
    }
  },
  {
    name: "Datum od-do",
    options: {
      filter: false,
      customBodyRender: ([from, to]) =>
        `${from !== "?" ? moment(from).format("L LT") : from} - ${to !== "?" ? moment(to).format("L LT") : to
        }`
    }
  },
  {
    name: "Budget MMA",
    options: {
      filter: false
    }
  },
  {
    name: "Budget MSE",
    options: {
      filter: false
    }
  },
  {
    name: "Budget EXHYP",
    options: {
      filter: false
    }
  },
  {
    name: "Nad rámec budgetu",
    options: {
      filter: false
    }
  },
  {
    name: "NPS",
    options: {
      filter: false
    }
  },
  {
    name: "Hodnocení",
    options: {
      filter: false
    }
  },
  {
    name: "Interní klient",
    options: {
      filter: false
    }
  },
  {
    name: "Adresa",
    options: {
      filter: false,
      customBodyRender: (address) => (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://maps.google.com/?q=${address}`}
        >
          {address}
        </a>
      )
    }
  },
  {
    name: "Rok",
    options: {
      filter: true,
      customBodyRender: ([from, to]) =>
        `${from !== "?" ? moment(from).year() : from}`
    }
  },
  {
    name: "Finanční podklady",
    options: {
      filter: false,
      customBodyRender: (link) => {
        return link === ""
          ? "-"
          : (
            <a target="_blank" rel="noopener noreferrer" href={link}>
              ODKAZ
            </a>
          );
      }
    }
  },
  {
    name: "Foto link",
    options: {
      filter: false,
      customBodyRender: (link) => {
        return link.length < 3
          ? "-"
          : (
            <a rel="noopener noreferrer" target="_blank" href={link}>
              ODKAZ
            </a>
          );
      }
    }
  },
  {
    name: "Poznámka",
    options: {
      filter: false
    }
  }
] as const;
