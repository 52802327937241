import React from "react";
import styled from "styled-components";

const State = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  right: -95%;
  top: 40px;
  border-radius: 100px;
  border: ${(props) =>
    props.rejected && props.approved && props.waiting === false
      ? "1px solid #0078b4"
      : null};
  background-color: ${(props) => {
    if (props.rejected) {
      return "#be2321";
    } else if (props.waiting) {
      return "#FFB115";
    } else if (props.approved) {
      return "#05A404";
    } else {
      return "#0078b4";
    }
  }};
`;
const StateText = styled.span`
  color: ${(props) => {
    if (props.rejected) {
      return "#be2321";
    } else if (props.waiting) {
      return "#FFB115";
    } else if (props.approved) {
      return "#05A404";
    } else if ((props.rejected && props.approved && props.waiting) === false) {
      return "#0078b4";
    }
  }};
  margin-right: 10px;
  float: right;
  width: ${(props) => {
    if (props.rejected) {
      return "100px";
    } else if (props.waiting) {
      return "150px";
    } else if (props.approved) {
      return "100px";
    } else if ((props.rejected && props.approved && props.waiting) === false) {
      return "100px";
    }
  }};
  //background-color: orangered;
  font-family: "Helvetica Neue";
`;

const FairState = ({ formState }) => {
  return (
    <>
      <State
        rejected={formState === "Zamítnuto"}
        waiting={formState === "Čeká na schválení"}
        approved={formState === "Schváleno"}
      >
        <StateText
          rejected={formState === "Zamítnuto"}
          waiting={formState === "Čeká na schválení"}
          approved={formState === "Schváleno"}
        >
          {formState}
        </StateText>
      </State>
    </>
  );
};

export default FairState;
