import { withStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import compose from "ramda/src/compose";
import { FC } from "react";
import { withRouter } from "react-router-dom";

import Menu from "./menu/Menu";
import { useLocalStorage } from "../utils/useLocalStorage";

interface Props {
  classes: Record<string, string>;
}

const Layout: FC<Props> = ({ children, classes }) => {
  const [collapse] = useLocalStorage("menu-collapse", false);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav style={{ width: collapse ? "58px" : "280px", flexShrink: 0, zIndex: 200000, position: "relative" }}>
        <Drawer
          classes={!collapse && { paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          <Menu />
        </Drawer>
      </nav>

      <div style={{ flexGrow: 1 }}>
        {children}
      </div>
    </div>
  );
};

const drawerWidth = 280;

const styles = {
  root: {
    display: "flex"
  },
  drawerPaper: {
    width: drawerWidth
  }
};

export default compose(withStyles(styles), withRouter as any)(Layout) as any as FC<{}>;
