import gql from "graphql-tag";
import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import { graphql } from "react-apollo";

import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import classroomAttributes from "../constants/classroomAttributes";

const CoreRegionProjects = ({ match, regionClassroomsQuery, projectType }) => {
  return (
    <TableLayout title={`Třídy z regionu ${path(["params", "region"])(match)}`}>
      <ProjectsTable
        id="CoreRegionProjects"
        query={regionClassroomsQuery}
        dataSelector={d => d.regionClassrooms}
        projectType={projectType}
      />
    </TableLayout>
  );
};

const coreRegionClassrooms = graphql(gql`
    query RegionClassrooms($region: String!, $projectType: ProjectType!){
        regionClassrooms(region: $region, projectType: $projectType) {
            ${classroomAttributes}
        }
    }
`, {
  name: "regionClassroomsQuery",
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      region: pathOr("-", ["match", "params", "region"])(props),
      projectType: props.projectType
    }
  })
});

export default coreRegionClassrooms(CoreRegionProjects);
