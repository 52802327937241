import { CREATE_EVENT_MODAL, EDIT_EVENT_MODAL, SET_DATA_SORTED } from "./constants";

export const setCreateEventModal = (direction) => ({
  type: CREATE_EVENT_MODAL,
  direction
});

export const setEditEventModal = (eventId) => ({
  type: EDIT_EVENT_MODAL,
  eventId
});

export const setDataSorted = (colIndex, direction) => ({
  type: SET_DATA_SORTED,
  colIndex,
  direction
});
