import { CREATE_EVENT_MODAL, EDIT_EVENT_MODAL, SET_DATA_SORTED } from "./constants";

const initialState = {
  isCreateModalOpened: false,
  eventId: null,
  colIndex: null,
  direction: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EVENT_MODAL:
      return {
        ...state,
        isCreateModalOpened: action.direction
      };

    case EDIT_EVENT_MODAL:
      return {
        ...state,
        eventId: action.eventId
      };
    case SET_DATA_SORTED:
      return {
        ...state,
        colIndex: action.colIndex,
        direction: action.direction
      };
    default:
      return state;
  }
};

export default reducer;
