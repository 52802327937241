export interface RoadmapAttributes {
  id: string;
  createdAt;
  updatedAt;
  author: {
    id;
    firstname;
    lastname;
    email;
    region;
  };
  segment;
  name;
  from;
  to;
  description: string;
  address;
  budgetMMA;
  budgetMSE;
  budgetEXHYP;
  overBudget;
  nps;
  note: string;
  evaluation;
  internalClient;
  finMaterial;
  photoLink;
  region;
  photos: {
    id: string;
    name: string;
    path: string;
    fileType;
    author: {
      id: string;
      firstname: string;
      lastname: string;
      email: string;
    };
  }[];
}

export default `
    id
    createdAt
    updatedAt
    author {
        id
        firstname
        lastname
        email
        region
    }
    segment
    name
    from
    to
    description
    address
    budgetMMA
    budgetMSE
    budgetEXHYP
    overBudget
    nps
    note
    evaluation
    internalClient
    finMaterial
    photoLink
    region
    photos {
        id
        name
        path
        fileType
        author {
            id
            firstname
            lastname
            email
        }
    }
`;
