import compose from "ramda/src/compose";
import React from "react";
import { connect } from "react-redux";

import { setCreateEventModal, setEditEventModal } from "../model/roadmap/actions";
import CreateRoadmapEventModal from "./CreateRoadmapEventModal";
import EditRoadmapEventModal from "./EditRoadmapEventModal";

const RoadmapEventModals = ({ roadmapQuery, setCreateEventModal, setEditEventModal, isCreateModalOpened, eventId }) => {
  return (
    <>
      {isCreateModalOpened ? (
        <CreateRoadmapEventModal
          onClose={(refetch) => {
            setCreateEventModal(false);
            roadmapQuery.refetch();
          }}
        />
      ) : null}
      {eventId ? (
        <EditRoadmapEventModal
          roadmapQuery={roadmapQuery}
          onClose={(refetch) => {
            setEditEventModal(null);
            roadmapQuery.refetch();
          }}
          eventId={eventId}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  isCreateModalOpened: state.roadmapModal.isCreateModalOpened,
  eventId: state.roadmapModal.eventId
});

const mapDispatchToProps = { setCreateEventModal, setEditEventModal };

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RoadmapEventModals);
