import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { FC, useState } from "react";
import { graphql, MutationFn } from "react-apollo";
import { any } from "../../utils/permissions";
import CreateUser from '../CreateUser';

import SelectUnassignedUserModal from "../SelectUnassignedUserModal";
import { useUser } from "../UserContext";

interface Props {
  team: { id: string } & ({ User: User[] } | { users: User[] });
  refetch?: VoidFunction;
}

interface User {
  id: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  region?: string;
}

interface InnerProps {
  unassignUserMutation: MutationFn<unknown, { id: string; userId: string }>;
}

const TeamUsersForm: FC<Props & InnerProps> = ({ team, unassignUserMutation, refetch }) => {
  const { Role } = useUser();
  const [unassignLoading, setUnassignLoading] = useState(null);
  const [
    selectUnassignedUserVisible,
    setSelectUnassignedUserVisible
  ] = useState(false);
  const [createUserTeam, setCreateUserTeam] = useState<string>();
  const isAdmin = any(["SUPER_ADMIN", "ADMIN"], Role);

  const normalizedTeam = {
    ...team,
    User: "User" in team ? team.User : team.users
  };

  return (
    <>
      {selectUnassignedUserVisible ? (
        <SelectUnassignedUserModal
          onClose={() => {
            refetch?.();
            setSelectUnassignedUserVisible(false);
          }}
          teamId={team.id}
        />
      ) : null}
      {createUserTeam ? (
        <CreateUser
          teamId={createUserTeam}
          onClose={() => {
            refetch?.();
            setCreateUserTeam(null);
          }}
        />
      ) : null}
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="name1">Jméno</InputLabel>
        <Input
          id="name1"
          name="name1"
          value={`${normalizedTeam?.User?.[0]?.firstname ?? ""} ${normalizedTeam?.User?.[0]?.lastname ?? ""}`}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="phone1">Telefon</InputLabel>
        <Input
          id="phone1"
          name="phone1"
          value={normalizedTeam?.User?.[0]?.phone || ""}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="email1">E-mail</InputLabel>
        <Input
          id="email1"
          name="email1"
          value={normalizedTeam?.User?.[0]?.email || ""}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="region1">Region</InputLabel>
        <Input
          id="region1"
          name="region1"
          value={normalizedTeam?.User?.[0]?.region || ""}
        />
      </FormControl>
      {isAdmin
        ? normalizedTeam?.User?.[0]?.email ? (
          <Button
            variant="outlined"
            disabled={unassignLoading === 1}
            onClick={() => {
              setUnassignLoading(1);
              unassignUserMutation({
                variables: {
                  id: normalizedTeam.id,
                  userId: normalizedTeam?.User?.[0]?.id
                }
              }).then(() => {
                refetch?.();
                setUnassignLoading(null);
              });
            }}
          >
            {unassignLoading === 1 ? <CircularProgress /> : null} Odebrat
            uživatele z týmu
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectUnassignedUserVisible(true);
              }}
            >
              Vybrat uživatele
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setCreateUserTeam(team.id);
              }}
              variant="outlined"
              style={{ marginLeft: "8px" }}
            >
              Vytvořit člena
            </Button>
          </>
        )
        : null}
      <FormControl style={{ marginTop: "50px" }} margin="normal" fullWidth>
        <InputLabel htmlFor="name2">Jméno</InputLabel>
        <Input
          id="name2"
          name="name2"
          value={`${normalizedTeam?.User?.[1]?.firstname ?? ""} ${normalizedTeam?.User?.[1]?.lastname ?? ""}`}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="phone2">Telefon</InputLabel>
        <Input
          id="phone2"
          name="phone2"
          value={normalizedTeam?.User?.[1]?.phone || ""}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="email2">E-mail</InputLabel>
        <Input
          id="email2"
          name="email2"
          value={normalizedTeam?.User?.[1]?.email || ""}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="region2">Region</InputLabel>
        <Input
          id="region2"
          name="region2"
          value={normalizedTeam?.User?.[1]?.region || ""}
        />
      </FormControl>
      {isAdmin
        ? normalizedTeam?.User?.[1]?.email ? (
          <Button
            variant="outlined"
            disabled={unassignLoading === 2}
            onClick={() => {
              setUnassignLoading(2);
              unassignUserMutation({
                variables: {
                  id: normalizedTeam.id,
                  userId: normalizedTeam?.User?.[1]?.id
                }
              }).then(() => {
                setUnassignLoading(null);
              });
            }}
          >
            {unassignLoading === 2 ? <CircularProgress /> : null} Odebrat
            uživatele z týmu
          </Button>
        ) : (
          <>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectUnassignedUserVisible(true);
            }}
          >
            Vybrat uživatele
          </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setCreateUserTeam(team.id);
              }}
              variant="outlined"
              style={{ marginLeft: "8px" }}
            >
              Vytvořit člena
            </Button>
            </>
        )
        : null}
    </>
  );
};

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(
  graphql<any>(
    gql`
      mutation UnassignUserMutation($id: ID!, $userId: ID!) {
        updateTeam(data: { id: $id, users: { disconnect: { id: $userId } } }) {
          id
          users {
            id
            email
            firstname
            lastname
            phone
            region
          }
        }
      }
    `,
    {
      name: "unassignUserMutation"
    }
  ),
  withStyles(styles)
)(TeamUsersForm) as any as FC<Props>;
