import { MUISortOptions } from "mui-datatables";

import { useLocalStorage } from "../../utils/useLocalStorage";

export interface DataGridState {
  columns: string[];
  filters: Record<string, string[]>;
  sort?: MUISortOptions;
}

export const useDataGridLocalStorage = (id: string, defaultColumns: string[]) => {
  return useLocalStorage<DataGridState>(`${id}-dataGrid`, { columns: defaultColumns, filters: {}, sort: undefined });
};
