import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

import { SentryRoute } from "./App";
import Layout from "./components/Layout";
import { ProjectDetailModal } from "./components/ProjectDetailModal";
import { UserContextProvider } from "./components/UserContext";
import Archive from "./screens/Archive";
import Budgets from "./screens/Budgets";
import ClassroomsManagement from "./screens/ClassroomsManagement";
import CoreFairs from "./screens/CoreFairs";
import CoreProjects from "./screens/CoreProjects";
import CoreRegionProjects from "./screens/CoreRegionProjects";
import DeletedClassrooms from "./screens/DeletedClassrooms";
import EFairs from "./screens/eFairs";
import Fairs from "./screens/Fairs";
import Forum from "./screens/Forum";
import Projects from "./screens/Projects";
import Roadmap from "./screens/Roadmap";
import Schools from "./screens/Schools";
import SecondGradesManagement from "./screens/SecondGradesManagement";
import SeniorAPManagement from "./screens/SeniorAPManagement";
import Statistics from "./screens/Statistics";
import Teams from "./screens/Teams";
import Toolboxes from "./screens/Toolboxes";
import Users from "./screens/Users";
import ZVSProjects from "./screens/ZVSProjects";

const Authenticated = () => {
  return (
    <UserContextProvider>
      <Layout>
        <ProjectDetailModal>
          <SentryRoute path="/" exact component={Projects} />
          <SentryRoute
            path="/core-projects-ap"
            exact
            render={(props) => <CoreProjects {...props} projectType="AP" />}
          />
          <SentryRoute
            path="/core-projects-zvs"
            exact
            render={(props) => <CoreProjects {...props} projectType="ZVS" />}
          />
          <SentryRoute path="/e-fairs" exact component={EFairs} />
          <SentryRoute path="/teams" exact component={Teams} />
          <SentryRoute path="/users" exact component={Users} />
          <SentryRoute path="/toolboxes" exact component={Toolboxes} />
          <SentryRoute path="/fairs" exact component={Fairs} />
          <SentryRoute path="/core-fairs" exact component={CoreFairs} />

          <SentryRoute
            path="/archive"
            exact
            render={(props) => <Archive {...props} projectType="AP" />}

          />

          <SentryRoute
            path="/archive-zvs"
            exact
            render={(props) => <Archive {...props} projectType="ZVS" />}

          />
          <SentryRoute path="/deleted" exact component={DeletedClassrooms} />
          <SentryRoute
            path="/classrooms-management"
            exact
            render={(props) =>
              <ClassroomsManagement {...props} projectType="AP" />}
          />
          <SentryRoute
            path="/classrooms-management-zvs"
            exact
            render={(props) =>
              <ClassroomsManagement {...props} projectType="ZVS" />}
          />
          <SentryRoute
            path="/second-grades-management"
            exact
            component={SecondGradesManagement}
          />
          <SentryRoute path="/zvs-projects" exact component={ZVSProjects} />
          <SentryRoute
            path="/senior-ap-management"
            exact
            component={SeniorAPManagement}
          />
          <SentryRoute path="/forum" exact component={Forum} />
          <SentryRoute path="/statistics" exact component={Statistics} />
          <SentryRoute
            path="/core-region-ap/:region"
            exact
            render={(props) =>
              <CoreRegionProjects {...props} projectType="AP" />}
          />
          <SentryRoute
            path="/core-region-zvs/:region"
            exact
            render={(props) =>
              <CoreRegionProjects {...props} projectType="ZVS" />}
          />
          <SentryRoute path="/roadmap" exact component={Roadmap} />
          <SentryRoute path="/budgets" exact component={Budgets} />
          <SentryRoute path="/schools" exact component={Schools} />
        </ProjectDetailModal>
      </Layout>
    </UserContextProvider>
  );
};

export default Authenticated;
