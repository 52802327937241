import Button from "@material-ui/core/Button";
import React, { FC, useState } from "react";

import SubclassroomForm from "../forms/SubclassroomForm";
import { ClassroomAttributes } from "../../constants/classroomAttributes";

interface Props {
  classroom: ClassroomAttributes;
}

const SubclassroomsTab: FC<Props> = ({ classroom }) => {
  const [addClass, setAddClass] = useState(false);

  return (
    <div>
      {(classroom.subclassrooms || []).map((subclassroom) => (
        <SubclassroomForm
          key={subclassroom.id}
          subclassroom={subclassroom}
          classroomId={classroom.id}
        />
      ))}
      {addClass ? (
        <SubclassroomForm
          classroomId={classroom.id}
          onCreated={() => setAddClass(false)}
        />
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "40px" }}
          onClick={() => setAddClass(true)}
        >
          Vytvořit třídu
        </Button>
      )}
    </div>
  );
};

export default SubclassroomsTab;
