import ContentLayout from "../components/Layout/ContentLayout";
import UsersTable from "../components/UsersTable";

const Users = () => (
  <ContentLayout title="Uživatelé">
    <UsersTable id="users" />
  </ContentLayout>
);

export default Users;
