import React from "react";

import ContentLayout from "../components/Layout/ContentLayout";
import ToolboxesTable from "../components/ToolboxesTable";

const Toolboxes = () => (
  <ContentLayout title="Toolboxy">
    <ToolboxesTable />
  </ContentLayout>
);

export default Toolboxes;
