export interface SchoolAttributesV2 {
  id;
  name;
  region;
  note;
  status;
  street;
  city;
  Contact_ContactToSchool_director: {
    id;
    name;
    email;
    phone;
  };
  Contact_ContactToSchool_alternate: {
    id;
    name;
    email;
    phone;
  };
  Classroom: {
    id;
    classroomName;
    semester;
    year;
    deleted;
    projectType: "AP" | "ZVS";
    type;
    User: {
      id;
      firstname;
      lastname;
    };
    Contact: {
      id;
      name;
      email;
    };
    teacherName;
    teacherEmail;
  }[];
}

export const schoolAttributesV2 = `
  id
  name
  region
  note
  status
  street
  city
  Contact_ContactToSchool_director {
    id
    name
    email
    phone
  }
  Contact_ContactToSchool_alternate {
    id
    name
    email
    phone
  }
  Classroom {
    id
    classroomName
    deleted
    semester
    year
    projectType
    Contact {
      email
      name
    }
  }
`;
