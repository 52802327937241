import { CREATE_SCHOOL, EDIT_SCHOOL } from "./constants";

const initialState = {
  isCreateModalOpened: false,
  schoolId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SCHOOL:
      return {
        ...state,
        isCreateModalOpened: action.direction
      };

    case EDIT_SCHOOL:
      return {
        ...state,
        schoolId: action.schoolId
      };
    default:
      return state;
  }
};

export default reducer;
