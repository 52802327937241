import React from "react";

import ContentLayout from "../components/Layout/ContentLayout";

const SecondGradesManagement = () => (
  <ContentLayout title="AP 2. třídy">
    TO BE DONE
  </ContentLayout>
);

export default SecondGradesManagement;
