import ContentLayout from "../components/Layout/ContentLayout";
import TeamsTable from "../components/TeamsTable";

const Teams = () => {
  return (
    <ContentLayout title="Správa týmů">
      <TeamsTable id="teams" />
    </ContentLayout>
  );
};

export default Teams;
