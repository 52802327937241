import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import path from "ramda/src/path";
import { useState } from "react";
import { graphql } from "react-apollo";

import SemesterSelectInput from "./SemesterSelectInput";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const CreateClassroomModal = ({
  onClose,
  classes,
  createClassroomMutation,
  projectType
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [semester, setSemester] = useState();
  const [year, setYear] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{
        paperWidthMd: classes.paper
      }}
    >
      <DialogTitle>Vytvořit třídu</DialogTitle>
      <DialogContent>
        <form
          className={classes.form}
          onSubmit={(e) => {
            setLoading(true);
            e.preventDefault();
            createClassroomMutation({
              variables: {
                semester: semester,
                year: year,
                projectType
              }
            })
              .then((res) => {
                setLoading(false);
                onClose(path(["data", "createClassroom", "id"])(res));
              })
              .catch((ex) => {
                setLoading(false);
                console.error("ERROR", ex);
                enqueueSnackbar(
                  "Zkontrolujte, zda máte přiřazený tým, případně kontaktujte správce aplikace",
                  {
                    variant: "error",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  }
                );
              });
          }}
        >
          <SemesterSelectInput
            setSemester={setSemester}
            setYear={setYear}
            year={year}
            semester={semester}
            create
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Vytvořit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const createClassroomMutation = graphql(
  gql`
    mutation CreateClassroom($semester: Int!, $year: Int!, $projectType: ProjectType!) {
      createClassroom(semester: $semester, year: $year, projectType: $projectType) {
        id
      }
    }
  `,
  {
    name: "createClassroomMutation"
  }
);

export default createClassroomMutation(
  withStyles(styles)(CreateClassroomModal)
);
