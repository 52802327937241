import { SchoolAttributesV2, schoolAttributesV2 } from "./schoolAttributesV2";

export interface ClassroomAttributesV2 {
  id;
  excursionDate;
  schoolFirstContact;
  classroomName;
  User: {
    id;
    firstname;
    lastname;
    region;
  };
  schoolAddress;
  directorName;
  directorEmail;
  directorPhone;
  teacherName;
  teacherPhone;
  teacherEmail;
  schoolMeeting;
  semester;
  year;
  branchAddress;
  branchRepresentativeEmail;
  branchRepresentativePhone;
  branchRepresentativeName;
  fairDate;
  childrenCount;
  fairNote;
  fairElectricity;
  fairAnnexationState;
  fairAnnexationNote;
  fairTime;
  fairEnd;
  kioskReadyTime;
  kioskPlace;
  archived;
  deleted;
  adminNote;
  ToolboxOrder: {
    id;
    state;
    recipient;
    address;
    childrenCount;
    Classroom: {
      id;
    };
  };
  Phase: {
    id;
    name;
    finished;
    finishDate;
    number;
    ChecklistItem: {
      id;
      name;
      checked;
    };
  };
  companyName;
  businessPurpose;
  businessDescription;
  moneyGoalAmount;
  Team: {
    id;
    User: {
      id;
      firstname;
      lastname;
      activated;
      email;
      region;
    }[];
  };
  type;
  visitInProduction;
  coffeeWithTeacher;
  FairAgency: {
    id;
    name;
  };
  forgiveLoan;
  forgiveLoanCause;
  nps;
  area;
  School: SchoolAttributesV2;
  eFairProfit;
  EFair: {
    id;
  };
  Contact: {
    id;
    name;
    phone;
    email;
  };
  projectType;
  Subclassroom: {
    id;
    name;
    childrenCount;
    note;
    Contact: {
      id;
      name;
      email;
      phone;
    };
    year;
    exampleEducation;
    ToolboxOrder: {
      id;
      state;
      recipient;
      address;
      childrenCount;
      Classroom: {
        id;
      };
    };
  }[];
}

export const classroomAttributesV2 = `
  id
  excursionDate
  schoolFirstContact
  classroomName
  User {
    id
    firstname
    lastname
    region
  }
  schoolAddress
  directorName
  directorEmail
  directorPhone
  teacherName
  teacherPhone
  teacherEmail
  schoolMeeting
  semester
  year
  branchAddress
  branchRepresentativeEmail
  branchRepresentativePhone
  branchRepresentativeName
  fairDate
  childrenCount
  fairNote
  fairElectricity
  fairAnnexationState
  fairAnnexationNote
  fairTime
  fairEnd
  kioskReadyTime
  kioskPlace
  archived
  deleted
  adminNote
  ToolboxOrder {
    id
    state
    recipient
    address
    childrenCount
    Classroom {
      id
    }
  }
  Phase {
    id
    name
    finished
    finishDate
    number
    ChecklistItem {
      id
      name
      checked
    }
  }
  companyName
  businessPurpose
  businessDescription
  moneyGoalAmount
  Team {
    id
    User {
      id
      firstname
      lastname
      activated
      email
      region
    }
  }
  type
  visitInProduction
  coffeeWithTeacher
  FairAgency {
    id
    name
  }
  forgiveLoan
  forgiveLoanCause
  nps
  area
  School {
    ${schoolAttributesV2}
  }
  eFairProfit
  EFair {
    id
  }
  Contact {
    id
    name
    phone
    email
  }
  projectType
  Subclassroom {
    id
    name
    childrenCount
    note
    Contact {
      id
      name
      email
      phone
    }
    year
    exampleEducation
    ToolboxOrder {
      id
      state
      recipient
      address
      childrenCount
      Classroom {
        id
      }
    }
  }
`;
