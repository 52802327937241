import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Mood from "@material-ui/icons/Mood";
import Work from "@material-ui/icons/Work";
import { FC } from "react";

import logo from "../../assets/cs-logo.svg";
import { all, any } from "../../utils/permissions";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useUser } from "../UserContext";
import AdminMenu from "./AdminMenu";
import CoreMenu from "./CoreMenu";
import { MenuItem } from "./MenuItem";
import styles from "./styles";

interface MenuProps {
  classes: Record<string, string>;
}

const Menu: FC<MenuProps> = ({ classes }) => {
  const user = useUser();
  const [collapse, setCollapse] = useLocalStorage("menu-collapse", false);

  return (
    <div>
      <div className={classes.toolbar}>
        {!collapse && <img src={logo} alt="Abeceda penez logo" className={classes.logo} />}
      </div>
      <Divider />
      <List>
        {all(["ADMIN"], user) && <AdminMenu />}
        {all(["AGENCY"], user) && <StyledAgencyMenu />}
        {all(["CORE_AGENCY"], user) && <StyledCoreAgencyMenu />}
        {any(["CORE"], user) && <CoreMenu />}
      </List>
      <div style={{ position: "sticky", bottom: 0 }}>
        <ListItem button style={{ background: "white" }} onClick={() => setCollapse(s => !s)}>
          <ListItemIcon>
            {collapse ? <ArrowForward /> : <ArrowBack />}
          </ListItemIcon>
          {!collapse && <ListItemText primary="Skrýt menu" />}
        </ListItem>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Menu);

const AgencyMenu: FC<MenuProps> = ({ classes }) =>
  <MenuItem to="/toolboxes" classes={classes} icon={<Work />} label="Toolboxy" />;

const StyledAgencyMenu = withStyles(styles, { withTheme: true })(AgencyMenu);

const CoreAgencyMenu: FC<MenuProps> = ({ classes }) =>
  <MenuItem to="/fairs" classes={classes} icon={<Mood />} label="Jarmarky" />;

const StyledCoreAgencyMenu = withStyles(styles, { withTheme: true })(CoreAgencyMenu);
