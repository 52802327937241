import moment from "moment";

let initialYear;
const now = moment();
if (now.month() < 8) {
  initialYear = now.year() - 2;
} else {
  initialYear = now.year() - 1;
}

const getYear = (semester) => {
  if (semester <= 2) {
    return initialYear;
  }

  if (semester <= 4) {
    return initialYear + 1;
  }
  return initialYear + 2;
};

const getSelectValue = (semester, year) => {
  let value = 1;
  for (const possibleYear of [initialYear, initialYear + 1, initialYear + 2]) {
    if (possibleYear === year) {
      return semester % 2 === 1 ? value : value + 1;
    }
    value += 2;
  }
};

export { getYear, getSelectValue };
