import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import DatePicker from "material-ui-pickers/DatePicker";
import TimePicker from "material-ui-pickers/TimePicker";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import replace from "ramda/src/replace";
import { FC, useState } from "react";
import { DataValue, graphql, MutationFn } from "react-apollo";
import styled from "styled-components";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import { any } from "../../utils/permissions";
import updateClassroomMutation from "../../utils/updateClassroomMutation";
import SubmitButton from "../SubmitButton";
import { useUser } from "../UserContext";

interface Props {
  classroom: ClassroomAttributes;
  editDisabled?: boolean;
}

interface InnerProps {
  classes: any;
  fairAgenciesQuery: DataValue<any>;
  updateClassroomMutation: MutationFn<never>;
}

const FairModal: FC<Props & InnerProps> = ({
  classes,
  updateClassroomMutation,
  classroom,
  fairAgenciesQuery,
  editDisabled
}) => {
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [fairDate, setFairDate] = useState(classroom.fairDate);
  const [childrenCount, setChildrenCount] = useState(classroom.childrenCount || "");
  const [kioskReadyTime, setKioskReadyTime] = useState(classroom.kioskReadyTime);
  const [fairTime, setFairTime] = useState(classroom.fairTime);
  const [fairEnd, setFairEnd] = useState(classroom.fairEnd);
  const [fairNote, setFairNote] = useState(classroom.fairNote || "");
  const [fairElectricity, setFairElectricity] = useState(classroom.fairElectricity || "");
  const [fairAnnexationState, setFairAnnexationState] = useState(classroom.fairAnnexationState || "");
  const [fairAnnexationNote, setFairAnnexationNote] = useState(classroom.fairAnnexationNote || "");
  const [kioskPlace, setKioskPlace] = useState(classroom.kioskPlace || "");
  const [fairAgency, setFairAgency] = useState(classroom.fairAgency?.id || "");
  const isAgency = any(["AGENCY", "CORE_AGENCY"], user);
  return (
    <form className={classes.form}>
      {classroom.type === "CORE"
        ? fairAgenciesQuery.loading ? <CircularProgress /> : (
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="fairAgency">Regionální agentura</InputLabel>
            <Select
              inputProps={{
                id: "fairAgency",
                name: "fairAgency"
              }}
              value={fairAgency}
              onChange={(e) => setFairAgency(e.target.value as any)}
              disabled={isAgency}
            >
              {(fairAgenciesQuery.fairAgencies || [])
                .filter((agency) => agency.regions.includes(classroom?.team?.users?.[0]?.region))
                .map((f) =>
                  <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                )}
            </Select>
          </FormControl>
        )
        : null}
      <FormControl margin="normal" fullWidth>
        <DatePicker
          id="fairDate"
          name="fairDate"
          value={fairDate}
          onChange={setFairDate}
          label="Datum jarmarku"
          format="DD.MM.YYYY"
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="childrenCount">Počet dětí</InputLabel>
        <Input
          id="childrenCount"
          name="childrenCount"
          value={childrenCount}
          onChange={(e) => setChildrenCount(e.target.value)}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TimePicker
          id="kioskReadyTime"
          name="kioskReadyTime"
          value={kioskReadyTime}
          onChange={setKioskReadyTime}
          label="Postavení stánků"
          ampm={false}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TimePicker
          id="fairTime"
          name="fairTime"
          value={fairTime}
          onChange={setFairTime}
          label="Začátek jarmarku"
          ampm={false}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TimePicker
          id="fairEnd"
          name="fairEnd"
          value={fairEnd}
          onChange={setFairEnd}
          label="Konec jarmarku"
          ampm={false}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="fairNote">Poznámka</InputLabel>
        <Input
          id="fairNote"
          name="fairNote"
          multiline
          rows={3}
          rowsMax={5}
          value={fairNote}
          onChange={(e) => setFairNote(e.target.value)}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="fairNote">Elektřina</InputLabel>
        <Input
          id="fairElectricity"
          name="fairElectricity"
          multiline
          rows={3}
          rowsMax={5}
          value={fairElectricity}
          onChange={(e) => setFairElectricity(e.target.value)}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="fairAnnexationState">Stav záboru</InputLabel>
        <Select
          inputProps={{
            id: "fairAnnexationState",
            name: "fairAnnexationState"
          }}
          value={fairAnnexationState}
          onChange={(e) => setFairAnnexationState(e.target.value)}
        >
          <MenuItem value="V jednání">V jednání</MenuItem>
          <MenuItem value="Dojednáno">Dojednáno</MenuItem>
          <MenuItem value="Zamítnuto">Zamítnuto</MenuItem>
        </Select>
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="fairNote">Poznámka k záboru</InputLabel>
        <Input
          id="fairAnnexationNote"
          name="fairAnnexationNote"
          multiline
          rows={3}
          rowsMax={5}
          value={fairAnnexationNote}
          onChange={(e) => setFairAnnexationNote(e.target.value)}
          disabled={isAgency}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth style={{ marginBottom: 60 }}>
        <InputLabel htmlFor="kioskPlace">Prostor ČS?</InputLabel>
        <Select
          inputProps={{
            id: "kioskPlace",
            name: "kioskPlace"
          }}
          value={kioskPlace}
          onChange={(e) => setKioskPlace(e.target.value)}
          disabled={isAgency}
        >
          <MenuItem value="ANO">ANO</MenuItem>
          <MenuItem value="NE">NE</MenuItem>
          <MenuItem value="Pobočka je v OC">Pobočka je v OC</MenuItem>
        </Select>
      </FormControl>
      <FixedButtons>
        <SubmitButton
          color="primary"
          classes={classes}
          editDisabled={editDisabled && !isAgency}
          value="Uložit jarmark"
          actionHandler={(e) => {
            e.preventDefault();
            updateClassroomMutation({
              variables: isAgency ? {
                id: classroom.id,
                fairAnnexationState
              } : {
                id: classroom.id,
                fairDate,
                childrenCount,
                kioskReadyTime,
                fairTime,
                fairEnd,
                fairNote,
                fairElectricity,
                fairAnnexationState,
                fairAnnexationNote,
                kioskPlace,
                ...fairAgency ? {
                  fairAgency: {
                    connect: {
                      id: fairAgency
                    }
                  }
                } : {}
              }
            }).then(() => {
              enqueueSnackbar(
                "Jarmark byl úspěšně uložen",
                {
                  variant: "success",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                }
              );
            }).catch((ex) => {
              enqueueSnackbar(replace("GraphQL error: ", "")(ex.message), { variant: "error" });
              console.error("ERROR", ex);
            });
          }}
        />
        <SubmitButton
          color="secondary"
          classes={classes}
          editDisabled={editDisabled && !isAgency}
          value="Zrušit jarmark"
          actionHandler={(e) => {
            e.preventDefault();
            setFairDate(null);
            updateClassroomMutation({
              variables: {
                id: classroom.id,
                fairDate: null
              }
            }).then(() => {
              enqueueSnackbar(
                "Jarmark byl úspěšně zrušen",
                {
                  variant: "success",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                }
              );
            }).catch((ex) => {
              console.error("ERROR", ex);
            });
          }}
        />
      </FixedButtons>
    </form>
  );
};

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(
  graphql(
    updateClassroomMutation,
    {
      name: "updateClassroomMutation"
    }
  ),
  graphql(gql`
        {
            fairAgencies {
                id
                name
                regions
            }
        }
    `,
  {
    name: "fairAgenciesQuery",
    options: {
      fetchPolicy: "cache-and-network"
    }
  }),
  withStyles(styles)
)(FairModal) as any;

const FixedButtons = styled.div`
position: absolute;
bottom: 0;
left: 0;
right: 0;
background-color: white;
display: flex;
padding: 10px 20px;
border-radius: 4px;
`;
