import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { ComponentType, FC } from "react";
import { graphql, MutationFunc } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";

interface Props {
  onClose: (refetch: boolean) => unknown;
  classroom: ClassroomAttributes;
}

interface InnerProps {
  deleteMutation: MutationFunc<{}, { id: string }>;
  recoverMutation: MutationFunc<{}, { id: string }>;
}

const DeleteConfirmationModal: FC<Props & InnerProps> = ({ onClose, classroom, deleteMutation, recoverMutation }) => (
  <Dialog
    open
    onClose={() => onClose(false)}
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle>Skutečně chcete projekt {classroom.deleted ? "obnovit" : "smazat"}?</DialogTitle>
    <DialogContent>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={() => onClose(false)}>NE</Button>
        <Button
          onClick={async () => {
            if (classroom.deleted) {
              await recoverMutation({
                variables: {
                  id: classroom.id
                }
              });
              onClose(true);
            } else {
              await deleteMutation({
                variables: {
                  id: classroom.id
                }
              });
              onClose(true);
            }
          }}
        >
          ANO
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

const deleteMutation = graphql(gql`
    mutation Delete($id: ID!) {
        delete(id: $id) {
            id
            deleted
        }
    }
`, {
  name: "deleteMutation"
});

const recoverMutation = graphql(gql`
    mutation Recovery($id: ID!) {
        recover(id: $id) {
            id
            archived
        }
    }
`, {
  name: "recoverMutation"
});

export default compose(
  deleteMutation,
  recoverMutation
)(DeleteConfirmationModal as any) as any as ComponentType<Props>;
