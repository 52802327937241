import CreateSchoolModal from "./CreateSchoolModal";
import EditSchoolModal from "./EditSchoolModal";
import React from "react";
import compose from "ramda/src/compose";
import { connect } from "react-redux";
import { setCreateSchool, setEditSchool } from "../model/school/actions";

const SchoolDetailModals = ({ schoolsQuery, setCreateSchool, setEditSchool, isCreateModalOpened, schoolId }) => {
  return (
    <>
      {isCreateModalOpened ? (
        <CreateSchoolModal
          onClose={() => {
            schoolsQuery.refetch();
            setCreateSchool(false);
          }}
        />
      ) : null}
      {schoolId ? (
        <EditSchoolModal
          schoolsQuery={schoolsQuery}
          id={schoolId}
          onClose={(refetch) => {
            setEditSchool(null);
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  isCreateModalOpened: state.schoolModal.isCreateModalOpened,
  schoolId: state.schoolModal.schoolId
});

const mapDispatchToProps = { setEditSchool, setCreateSchool };

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SchoolDetailModals);
