import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import styled from "styled-components";

const DataPage = styled.form`
  width: 100%;
  height: auto;
`;

const EFairManager = ({ eFair }) => {
  return (
    <DataPage>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="fullName">Celé jméno</InputLabel>
        <Input
          id="fullName"
          name="fullName"
          autoFocus
          value={eFair.fairManager ? eFair.fairManager.fullName : ""}
          disabled
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="mail">Email</InputLabel>
        <Input
          id="email"
          name="email"
          autoFocus
          value={eFair.fairManager ? eFair.fairManager.email : ""}
          disabled
        />
      </FormControl>
    </DataPage>
  );
};

export default EFairManager;
