const styles = (theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    padding: "15px"
  },
  logo: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  active: {
    backgroundColor: "rgb(235, 235, 235)"
  }
});

export default styles;
