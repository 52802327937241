import gql from "graphql-tag";
import { FC } from "react";
import { DataValue, graphql } from "react-apollo";

import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import classroomAttributes, { ClassroomAttributes } from "../constants/classroomAttributes";

interface Props {
  classrooms?: DataValue<{ classrooms: ClassroomAttributes[] }>;
}

const ZVSProjects: FC<Props> = ({ classrooms }) => {
  return (
    <TableLayout title="ZVS">
      <ProjectsTable
        id="ZVSProjects"
        projectType="ZVS"
        query={classrooms}
        dataSelector={q => q.classrooms}
      />
    </TableLayout>
  );
};

const zvsClassroomsQuery = graphql<{}, { classrooms?: ClassroomAttributes[] }, {}, {}>(gql`
    {
        classrooms(projectType: ZVS) {
            ${classroomAttributes}
        }
    }
`, {
  name: "classrooms",
  options: {
    fetchPolicy: "cache-and-network"
  }
});

export default zvsClassroomsQuery(ZVSProjects);
