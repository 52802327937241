import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { useMemo } from "react";
import { useDataGridLocalStorage } from "./useDataGridLocalStorage";
import { useDataGridOptions } from "./useDataGridOptions";

interface UseDataGridOptions {
  id: string;
  defaultColumns?: string[];
  columns: readonly MUIDataTableColumn[];
  options: MUIDataTableOptions;
}

export const useDataGrid = ({ id, defaultColumns, columns, options }: UseDataGridOptions) => {
  const [state, setState] = useDataGridLocalStorage(id, defaultColumns ?? columns.map(c => typeof c === "string" ? c : c.name));

  const cols = useMemo(() => columns
    .map(c => state.columns.includes(c.name)
      ? c
      : { ...c, options: { ...c.options, display: "false" as const } }
    )
    .map(c => c.name === state.sort?.[0]
      ? { ...c, options: { ...c.options, sortDirection: state.sort[1] } }
      : c
    )
    .map(c => c.name in state.filters
      ? { ...c, options: { ...c.options, filterList: state.filters[c.name] } }
      : c
    ), [columns, state.columns, state.filters, state.sort]);

  const opts = useDataGridOptions({ columns: cols, state, setState, options });

  return {
    columns: cols,
    options: opts,
    state
  };
};
