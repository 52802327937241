import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import { FC, useMemo, useState } from "react";
import { graphql, MutationFunc } from "react-apollo";
import styled from "styled-components";

import { SchoolAttributesV2 } from "../constants/schoolAttributesV2";
import { matchError } from "../utils/matchError";
import SchoolDialog, { getSchoolInfo } from "./SchoolDialog";

interface Props {
  onClose?: (id?: string) => unknown;
}

interface InnerProps{
  classes: Record<string, string>;
  createEFairMutation: MutationFunc<any, { classroomId: string; email: string; fullName: string }>;
}

interface EFairInput {
  classroomId: string;
  email: string;
  fullName: string;
}

const CreateEFairModal: FC<Props & InnerProps> = ({ onClose, createEFairMutation, classes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [schoolSearchDialog, setSchoolSearchDialog] = useState(true);
  const [school, setSchool] = useState<SchoolAttributesV2>();

  const [efair, setEfair] = useState <Partial<EFairInput>>({});

  const schoolInfo = useMemo(() => school && getSchoolInfo(school), [school]);

  return (
    <>
      <Dialog
        open
        onClose={() => onClose()}
        fullWidth
        maxWidth="sm"
        classes={{
          paperWidthMd: classes.paper
        }}
      >
        <DialogTitle>Nový e-jarmark</DialogTitle>
        <DialogContent>
          <form
            className={classes.form}
            onSubmit={async ev => {
              setLoading(true);
              ev.preventDefault();
              if (efair.classroomId && efair.email && efair.fullName) {
                try {
                  const res = await createEFairMutation({
                    variables: efair as EFairInput
                  });

                  enqueueSnackbar("Jarmark byl vytvořen!", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                  onClose((res as any)?.data.createEFair.id);
                } catch (e) {
                  let message = "Jarmark nebyl vytvořen";
                  if (matchError(e, "create.manager.failed")) message += ", nejde vytvořit e-jarmark uživatele";
                  if (matchError(e, "create.efair.failed")) message += ", nejde vytvořit e-jarmark";
                  if (matchError(e, "create.efair.exists")) message += ", uživatel již existuje";
                  enqueueSnackbar(message + ".", {
                    variant: "error",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                } finally {
                  setLoading(false);
                }
              }
            }}
          >
            <Title>Přiřazený projekt</Title>
            <FormControl margin="normal" required fullWidth>
              <InputLabel
                style={{ position: "relative", top: "-10px" }}
                htmlFor="school"
              >
                Škola
              </InputLabel>
              <Input
                id="school"
                name="school"
                value={schoolInfo}
                onClick={() => {
                  setSchoolSearchDialog(true);
                }}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="classroom">Třída</InputLabel>
              <Select
                inputProps={{
                  id: "classroom",
                  name: "classroom"
                }}
                value={efair.classroomId ?? null}
                onChange={(e) => {
                  const id = e.target.value as string;
                  const classroom = school.Classroom.find(c => c.id === id);
                  setEfair(s => ({
                    classroomId: id,
                    fullName: classroom.Contact.name,
                    email: classroom.Contact.email
                  }));
                }}
              >
                {school?.Classroom && school.Classroom.length > 0
                  ? school.Classroom.map(c => c.deleted === false && c.projectType === "AP" && (
                    <MenuItem key={c.id} value={c.id}>
                      {c.classroomName} ({c.semester}. pololetí {c.year}/{c.year + 1})
                    </MenuItem>
                  ))
                  : <MenuItem>Žádné třídy k nalezení</MenuItem>}
              </Select>
            </FormControl>
            <Title>Správce e-jarmarku (učitel uvedený u projektu)</Title>
            <Text>
              Správci e-jarmarku bude vytvořen přístup k editaci ve webové
              aplikaci. Přístupy a pokyny budou správci zaslány na uvedený
              e-mail. Přístup bude smazán po ukončení e-jarmarku.
            </Text>
            <FormControl margin="normal" required fullWidth>
              <InputLabel
                style={{ position: "relative", top: "-10px" }}
                htmlFor="fullName"
              >
                Celé jméno
              </InputLabel>
              <Input
                id="fullName"
                name="fullName"
                value={efair.fullName}
                onChange={(e) => setEfair(s => ({ ...s, fullName: e.target.value }))}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel
                style={{ position: "relative", top: "-10px" }}
                htmlFor="email"
              >
                E-mail
              </InputLabel>
              <Input
                id="email"
                name="email"
                value={efair.email}
                onChange={(e) => setEfair(s => ({ ...s, email: e.target.value }))}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Založit nový e-jarmark"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {schoolSearchDialog ? (
        <SchoolDialog
          open
          onClose={(s) => {
            if (s) {
              setSchool(s);
              setEfair({});
            }
            setSchoolSearchDialog(false);
          }}
        />
      ) : null}
    </>
  );
};

const createEFairMutation = graphql(
  gql`
    mutation CreateEFair(
      $classroomId: ID!
      $email: String!
      $fullName: String!
    ) {
      createEFair(
        classroomId: $classroomId
        email: $email
        fullName: $fullName
      ) {
        id
      }
    }
  `,
  {
    name: "createEFairMutation"
  }
);

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(
  createEFairMutation,
  withStyles(styles) as any
)(CreateEFairModal) as any as FC<Props>;

const Title = styled.p`
  color: #393939;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: "Helvetica Neue";
  font-size: 1.2em;
`;
const Text = styled.p`
  color: #393939;
  margin-top: 30px;
  font-family: "Helvetica Neue";
  font-size: 0.8em;
  width: 75%;
`;
