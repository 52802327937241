import FormControl from "@material-ui/core/FormControl";
import DatePicker from "material-ui-pickers/DatePicker";
import TimePicker from "material-ui-pickers/TimePicker";
import moment from "moment-timezone";
import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  //background-color: #61dafb;
  float: left;
`;
const Date = styled.div`
  width: 30%;
  float: left;
`;
const Time = styled.div`
  margin: 0 10px 0 10px;
  width: 25%;
  float: right;
`;

const IssuingDay = ({ handleDateChange, time }) => {
  return (
    <>
      <Wrap>
        <Date>
          <FormControl margin="normal">
            <DatePicker
              id="fairDate"
              name="fairDate"
              value={time ? moment(time.from).tz("Europe/Berlin").format() : ""}
              onChange={handleDateChange}
              label="Datum"
              format="DD.MM.YYYY"
              disabled
            />
          </FormControl>
        </Date>
        <Time>
          <FormControl margin="normal" fullWidth>
            <TimePicker
              id="timeFrom"
              name="timeFrom"
              value={time ? moment(time.to).tz("Europe/Berlin").format() : ""}
              onChange={handleDateChange}
              label="Čas do"
              ampm={false}
            />
          </FormControl>
        </Time>
        <Time>
          <FormControl margin="normal" fullWidth>
            <TimePicker
              id="timeFrom"
              name="timeFrom"
              value={time ? moment(time.from).tz("Europe/Berlin").format() : ""}
              onChange={handleDateChange}
              label="Čas od"
              ampm={false}
            />
          </FormControl>
        </Time>
      </Wrap>
    </>
  );
};
export default IssuingDay;
