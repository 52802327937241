import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

import FairForm from "./forms/FairForm";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const FairModal = ({
  onClose,
  classes,
  classroom
}) => (
  <Dialog
    open
    onClose={onClose}
    fullWidth
    maxWidth={false}
    classes={{
      paperWidthMd: classes.paper
    }}
  >
    <DialogTitle>Detail jarmarku</DialogTitle>
    <DialogContent>
      <FairForm classroom={classroom} onClose={onClose} />
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(FairModal);
