import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import gql from "graphql-tag";
import DatePicker from "material-ui-pickers/DatePicker";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import React, { useState } from "react";
import { graphql, withApollo } from "react-apollo";

const classroomQuery = gql`
    query Classroom($id: ID!) {
        classroom(id: $id) {
            id
            subclassrooms {
                id
                name
                childrenCount
                note
                teacher {
                    id
                    name
                    email
                    phone
                }
                year
                exampleEducation
                toolboxOrder {
                    id
                    state
                    recipient
                    address
                    childrenCount
                    classroom {
                        id
                    }
                }
            }
        }
    }
`;

const SubclassroomForm = ({
  subclassroom,
  updateSubclassroomMutation,
  addSubclassroomMutation,
  updateContactMutation,
  classroomId,
  onCreated,
  client
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(subclassroom?.name || "");
  const [childrenCount, setChildrenCount] = useState(subclassroom?.childrenCount || "");
  const [year, setYear] = useState(subclassroom?.year || "");
  const [note, setNote] = useState(subclassroom?.note || "");
  const [exampleEducation, setExampleEducation] = useState(subclassroom?.exampleEducation || null);
  const [teacherName, setTeacherName] = useState(subclassroom?.teacher?.name || "");
  const [phone, setPhone] = useState(subclassroom?.teacher?.phone || "");
  const [email, setEmail] = useState(subclassroom?.teacher?.email || "");
  return (
    <div style={{ marginBottom: "40px" }}>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="name">Jméno třídy</InputLabel>
        <Input
          id="name"
          name="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="childrenCount">Počet dětí</InputLabel>
        <Input
          id="childrenCount"
          name="childrenCount"
          value={childrenCount}
          onChange={(e) => setChildrenCount(e.target.value as any)}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="year">Ročník</InputLabel>
        <Select
          inputProps={{
            id: "year",
            name: "year"
          }}
          value={year}
          onChange={e => setYear(e.target.value)}
        >
          <MenuItem key="2. ročník" value="2. ročník">2. ročník</MenuItem>
          <MenuItem key="4. ročník" value="4. ročník">4. ročník</MenuItem>
        </Select>
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <DatePicker
          id="exampleEducation"
          name="exampleEducation"
          value={exampleEducation}
          onChange={setExampleEducation}
          label="Ukázková hodina"
          format="DD.MM.YYYY"
          clearable
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="name">Jméno a příjmení učitele</InputLabel>
        <Input
          id="name"
          name="name"
          value={teacherName}
          onChange={(e) => setTeacherName(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="phone">Telefon</InputLabel>
        <Input
          id="phone"
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="email">E-mail</InputLabel>
        <Input
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="note">Poznámka</InputLabel>
        <Input
          id="note"
          name="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={loading}
        onClick={() => {
          setLoading(true);
          let apiCallPromises = [];
          if (!subclassroom) {
            apiCallPromises = [addSubclassroomMutation({
              variables: {
                classroomId,
                subclassroom: {
                  name,
                  childrenCount: `${childrenCount}`,
                  year,
                  note,
                  exampleEducation,
                  teacher: {
                    name: teacherName,
                    phone,
                    email
                  }
                }
              }
            })];
          } else {
            apiCallPromises = [
              updateSubclassroomMutation({
                variables: {
                  id: subclassroom.id,
                  name,
                  childrenCount: `${childrenCount}`,
                  year,
                  note,
                  exampleEducation
                }
              }),
              updateContactMutation({
                variables: {
                  id: subclassroom.teacher?.id,
                  name: teacherName,
                  email,
                  phone
                }
              })
            ];
          }
          Promise.all(apiCallPromises).then(() => {
            if (!subclassroom) {
              onCreated();
              client.query({
                query: classroomQuery,
                fetchPolicy: "network-only",
                variables: {
                  id: classroomId
                }
              }).then(() => {
                setLoading(false);
                enqueueSnackbar(
                  "Třída byla úspěšně uložena",
                  {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  }
                );
              });
            } else {
              setLoading(false);
              enqueueSnackbar(
                "Třída byla úspěšně uložena",
                {
                  variant: "success",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                }
              );
            }
          }).catch(() => {
            setLoading(false);
            enqueueSnackbar(
              "Třída nebyla uložena",
              {
                variant: "error",
                autoHideDuration: 4000,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              }
            );
          });
        }}
      >
        {loading && <CircularProgress />} Uložit
      </Button>
    </div>
  );
};

const AddSubclassroom = graphql(
  gql`
        mutation AddSubclassroom($classroomId: ID!, $subclassroom: CreateSubclassroomInput!) {
            addSubclassroom(
                classroomId: $classroomId,
                subclassroom: $subclassroom
            ) {
                id
            }
        }
    `, {
    name: "addSubclassroomMutation"
  }
);

const UpdateSubclassroom = graphql(gql`
    mutation UpdateSubclassroomMutation(
        $id: ID!
        $name: String
        $childrenCount: String
        $year: String
        $note: String
        $exampleEducation: DateTime
    ) {
        updateSubclassroom(
            data: {
                id: $id
                name: $name
                childrenCount: $childrenCount
                year: $year
                note: $note
                exampleEducation: $exampleEducation
            }
        ) {
            id
            name
            childrenCount
            year
            note
            exampleEducation
        }
    }
`, {
  name: "updateSubclassroomMutation"
});

const UpdateContactMutation = graphql(gql`
    mutation UpdateContact(
        $id: ID!
        $name: String
        $email: String
        $phone: String
    ) {
        updateContact(
            data: {
                id: $id
                name: $name
                email: $email
                phone: $phone
            }
        ) {
            id
            name
            phone
            email
        }
    }
`, {
  name: "updateContactMutation"
});

export default compose(
  UpdateSubclassroom,
  AddSubclassroom,
  UpdateContactMutation,
  withApollo
)(SubclassroomForm as any) as any;
