import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import React, { useState } from "react";
import { graphql } from "react-apollo";
import styled from "styled-components";

import updateEFairMutationNode from "../utils/updateEFairMutation";

const DataPage = styled.form`
  width: 100%;
  height: auto;
`;
const Title = styled.p`
  color: #393939;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: "Helvetica Neue";
  font-size: 1.2em;
`;
const Text = styled.p`
  color: #393939;
  margin-top: 30px;
  //margin-bottom: 30px;
  font-family: "Helvetica Neue";
  font-size: 0.8em;
  width: 75%;
`;
const DeleteButton = styled(Button)`
  background-color: #da2530;
  color: white;
  &:hover {
    background-color: #b42932;
  }
`;
const EFairManagement = ({
  eFair,
  deleteEFair,
  deleteFairManager,
  updateEFairMutation,
  onClose
}) => {
  const [hideReason, setHideReason] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <DataPage>
      {eFair.state === "Ukončený" ? null : (
        <>
          {eFair.state === "Skrytý" ? (
            <>
              <Title>Viditelnost e-jarmarku</Title>
              <Text>
                Pokud e-jarmark obsahuje nevhodný obsah, lze ho skrýt. E-jarmark
                po skrytí nebude viditelný pro veřejnost. Je-li už obsah v
                pořádku, lze e-jarmark zviditelnit pro veřejnost.
              </Text>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                // style={{ marginBottom: "20px", marginLeft: "10px" }}
                onClick={() => {
                  updateEFairMutation({
                    variables: {
                      id: eFair.id,
                      state: "Aktivní"
                      // approval: "approved",
                      // fairManagerId: eFair.fairManager.id,
                    }
                  })
                    .then((res) => {
                      enqueueSnackbar("E-jarmark je nyní viditelný", {
                        variant: "success",
                        autoHideDuration: 4000,
                        anchorOrigin: {
                          horizontal: "center",
                          vertical: "top"
                        }
                      });
                      onClose(true);
                    })
                    .catch((err) => console.error(err));
                }}
              >
                Zviditelnit
              </Button>
            </>
          ) : (
            <>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="hideReason">Důvod skrytí</InputLabel>
                <Input
                  id="hideReason"
                  name="hideReason"
                  autoFocus
                  value={hideReason}
                  onChange={(e) => setHideReason(e.target.value)}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                  fullWidth
                  onClick={() => {
                  if (hideReason !== "") {
                    updateEFairMutation({
                      variables: {
                        id: eFair.id,
                        state: "Skrytý",
                        hideReason: hideReason,
                        // approval: "rejected",
                        fairManagerId: eFair.fairManager.id
                      }
                    })
                      .then((res) => {
                        enqueueSnackbar("E-jarmark je nyní skrytý", {
                          variant: "success",
                          autoHideDuration: 4000,
                          anchorOrigin: {
                            horizontal: "center",
                            vertical: "top"
                          }
                        });
                        onClose(true);
                      })
                      .catch((err) => console.error(err));
                  } else {
                    enqueueSnackbar("Vyplňte důvod skrytí", {
                      variant: "error",
                      autoHideDuration: 4000,
                      anchorOrigin: {
                        horizontal: "center",
                        vertical: "top"
                      }
                    });
                  }
                }}
                // style={{ marginBottom: "20px", marginLeft: "10px" }}
              >
                Skrýt e-Jarmark
              </Button>
            </>
          )}
          <Title>Ukončení e-jarmarku</Title>
          <Text>
            Po ukončení e-jarmarku bude trvale smazán přístup správci do webové
            editace. E-jarmark také nebude možné vyhledat a zobrazit.
          </Text>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            // style={{ marginBottom: "20px", marginLeft: "10px" }}
            onClick={() => {
              updateEFairMutation({
                variables: {
                  id: eFair.id,
                  state: "Ukončený"
                  // fairManagerId: eFair.fairManager.id,
                }
              })
                .then((res) => {
                  if (eFair.fairManager) {
                    deleteFairManager({
                      variables: {
                        id: eFair.fairManager.id
                      }
                    })
                      .then(() => {
                        enqueueSnackbar("Správci e-jarmarku byl smazán účet", {
                          variant: "success",
                          autoHideDuration: 4000,
                          anchorOrigin: {
                            horizontal: "center",
                            vertical: "top"
                          }
                        });
                      })
                      .catch((err) => console.error(err));
                  } else {
                    enqueueSnackbar(
                      "K ukončenému e-jarmarku nebyl veden žádný správce",
                      {
                        variant: "success",
                        autoHideDuration: 4000,
                        anchorOrigin: {
                          horizontal: "center",
                          vertical: "top"
                        }
                      }
                    );
                  }
                  enqueueSnackbar("E-jarmark byl ukončený", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                  onClose(true);
                })
                .catch((err) => console.error(err));
            }}
          >
            Ukončit e-Jarmark
          </Button>
        </>
      )}
      <Title>Trvalé smazání</Title>
      <Text>
        Smazání e-jarmarku je trvalá a nevratná akce. E-jarmark nebude možné
        obnovit
      </Text>
      <Checkbox
        checked={isChecked}
        onClick={() => setIsChecked(!isChecked)}
        name="checkbox"
        color="primary"
      />
      <InputLabel htmlFor="checkbox" onClick={() => setIsChecked(!isChecked)}>
        Jsem si jistý, že chci e-jarmark trvale smazat
      </InputLabel>
      <DeleteButton
        variant="contained"
        fullWidth
        // style={{ marginBottom: "20px", marginLeft: "10px" }}
        onClick={() => {
          if (isChecked) {
            deleteEFair({
              variables: {
                id: eFair.id
              }
            })
              .then(() => {
                if (eFair.fairManager) {
                  deleteFairManager({
                    variables: {
                      id: eFair.fairManager.id
                    }
                  })
                    .then(() => {})
                    .catch((err) => console.error(err));
                }
                onClose(true);
                enqueueSnackbar("E-jarmark byl úspěšně smazán", {
                  variant: "success",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                });
              })
              .catch((e) => {
                console.error("ERROR", e);
                enqueueSnackbar("E-jarmark nebyl smazán", {
                  variant: "error",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                });
              });
          } else {
            enqueueSnackbar(
              "Musíte zaškrtnou kontrolní políčko pro smazání e-jarmarku",
              {
                variant: "error",
                autoHideDuration: 4000,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              }
            );
          }
        }}
      >
        Trvale smazat e-jarmark
      </DeleteButton>
    </DataPage>
  );
};

const deleteEFair = graphql(
  gql`
    mutation DeleteEFair($id: ID!) {
      deleteEFair(id: $id)
    }
  `,
  {
    name: "deleteEFair"
  }
);
const deleteFairManager = graphql(
  gql`
    mutation DeleteFairManager($id: ID!) {
      deleteFairManager(id: $id)
    }
  `,
  {
    name: "deleteFairManager"
  }
);

export default compose(
  deleteFairManager,
  deleteEFair,
  updateEFairMutationNode
)(EFairManagement);
