import { AppBar, IconButton, MenuItem, Theme, Toolbar, Typography, withStyles } from "@material-ui/core";
import MuiMenu from "@material-ui/core/Menu";
import { AccountCircle } from "@material-ui/icons";
import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ChangePasswordModal from "./ChangePasswordModal";

interface Props {
  title: string;
}

const TableLayout: FC<Props & RouteComponentProps & { classes: any }> = ({
  classes,
  title,
  children,
  history: { push }
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePassword, setChangePassword] = useState(false);

  function handleClick (event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose () {
    setAnchorEl(null);
  }

  return (
    <>
      {changePassword
        ? <ChangePasswordModal onClose={() => setChangePassword(false)} />
        : null}
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton onClick={handleClick} color="inherit">
            <AccountCircle />
          </IconButton>
        </Toolbar>
        <MuiMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setChangePassword(true);
            }}
          >
            Změnit heslo
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              localStorage.removeItem("token");
              push("/login");
            }}
          >
            Odhlásit
          </MenuItem>
        </MuiMenu>
      </AppBar>
      <main className={classes.content}>
        {children}
      </main>
    </>
  );
};

const styles = (theme: Theme) => ({
  content: {
    "flexGrow": 1,
    "paddingLeft": 0,
    "paddingRight": 0,
    "paddingBottom": 0,
    "height": "100%",
    "marginTop": "64px",
    "background-color": "white"
  },
  appBar: {
    "position": "fixed",
    "left": 0,
    "right": 0,
    "top": 0,
    "paddingLeft": "280px",
    "z-index": 10000
  },
  toolbar: theme.mixins.toolbar
} as const);

export default withStyles(styles, { withTheme: true })(withRouter(TableLayout));
