import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";

import { apollo2Client } from "../App";

export interface UserContext {
  id: string;
  email: string;
  region: string;
  Role: { name: string }[];
}

export const _UserContext = createContext<UserContext>(null!);

export const useUser = () => useContext<UserContext>(_UserContext);

export const UserContextProvider = withRouter(({ children, history: { push } }: PropsWithChildren<RouteComponentProps>) => {
  const [loaded, setLoaded] = useState(false);
  const query = useQuery<{ me: UserContext }>(meQuery, { client: apollo2Client });

  useEffect(() => {
    if (query.error) {
      push("/login");
    }

    if (query.data && !loaded) {
      setLoaded(true);
      if (query.data.me.Role?.some(r => r.name === "ADMIN")) {
        return;
      }
      if (query.data.me.Role?.some(r => r.name === "CORE_AGENCY")) {
        push("/fairs");
        return;
      }
      if (query.data.me.Role?.some(r => r.name === "AGENCY")) {
        push("/toolboxes");
        return;
      }
      if (query.data.me.Role?.some(r => r.name === "CORE")) {
        push("/classrooms-management");
      }
    }
  }, [loaded, push, query.data, query.error]);

  if (query.loading || !query.data?.me) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <_UserContext.Provider value={query.data.me}>
      {children}
    </_UserContext.Provider>
  );
});

const meQuery = gql`
  query Me {
    me {
      id
      email
      region
      Role {
        name
      }
    }
  }
`;
