import { useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import withStyles from "@material-ui/core/styles/withStyles";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { FC, useMemo, useState } from "react";
import { withApollo } from "react-apollo";

import { apollo2Client } from "../App";
import CreateUser from "./CreateUser";
import { DataGrid } from "./DataGrid";
import { useDataGrid } from "./DataGrid/useDataGrid";
import TeamModal from "./TeamModal";

interface Props {
  id: string;
}

interface InnerProps {
  classes: any;
  client: ApolloClient<unknown>;
}

const TeamsTable: FC<Props & InnerProps> = ({ id, classes, client }) => {
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [createUserTeam, setCreateUserTeam] = useState<string>();
  const [teamDetailId, setTeamDetailId] = useState<string>();

  const query = useQuery<TeamsQuery>(teamsQuery, { client: apollo2Client });

  const sortedData = useMemo(
    () => query.data ? [...query.data.teams].sort((a, b) =>

      b.User.length - a.User.length || b.id.localeCompare(a.id)
    ) : undefined,
    [query.data]
  );

  const data = useMemo(() => sortedData?.flatMap((team) => [
    [
      <>
        {team.User.map((user) => (
          <React.Fragment key={user.id}>
            {user.activated ? `${user.firstname} ${user.lastname}` : `${user.email ?? "-"} (${user.securityCode})`}<br />
            <span style={{ color: "#9d9d9d" }}>{user.phone ?? "-"}, {user.email}</span><br />
          </React.Fragment>
        ))}
      </>,
      <>
        {team.User.map((user) => (
          <React.Fragment key={user.id}>
            {user.region ?? "-"}<br /><br />
          </React.Fragment>
        ))}
      </>,
      team.id
    ],
    ...team.Classroom.length ? [] : [["", <i key={1}>Žádné projekty</i>]],
    ...team.Classroom.map(classroom => [
      <div key={1} style={{ paddingLeft: "5rem" }}>{classroom.classroomName}</div>,
      <>{`${getActivePhase(classroom)?.number}/${classroom.Phase.length}: ${getActivePhase(classroom)?.name}`}</>,
      ""
    ])
  ]), [sortedData]);

  const { state, ...dataGrid } = useDataGrid({
    id,
    columns,
    options: {
      onCellClick: (_, { rowIndex, dataIndex }) => {
        const teamId = data[rowIndex]?.[2] as string;
        if (teamId) {
          setTeamDetailId(teamId);
        }
      }
    }
  });

  if (query.loading) return <CircularProgress />;
  if (query.error) {
    return (
      <SnackbarContent
        className={classes.errorMessage}
        message="Načtení se nezdařilo"
      />
    );
  }
  return (
    <>
      {teamDetailId
        ? <TeamModal teamId={teamDetailId} onClose={() => setTeamDetailId(null)} />
        : null}
      {createUserTeam ? (
        <CreateUser
          teamId={createUserTeam}
          onClose={() => {
            query.refetch();
            setCreateUserTeam(null);
          }}
        />
      ) : null}

      <DataGrid
        {...dataGrid}
        loading={query.loading}
        data={data}
        toolbar={(
          <Button
            variant="outlined"
            disabled={creatingTeam}
            onClick={async () => {
              setCreatingTeam(true);
              const res = await client.query<{ createTeam: {id: string } }>({
                query: createTeamQuery,
                fetchPolicy: "network-only"
              });
              const teams = await query.refetch();
              setCreatingTeam(false);
              setTeamDetailId(res.data.createTeam.id);
            }}
          >
            {creatingTeam ? <CircularProgress /> : "Vytvořit tým"}
          </Button>
        )}
      />
    </>
  );
};

interface TeamsQuery {
  teams: {
    id: string;
    createdAt;
    Classroom: {
      id;
      classroomName;
      Phase: {
        id;
        number;
        name;
      }[];
    }[];
    User: {
      id;
      email;
      firstname;
      lastname;
      activated;
      securityCode;
      phone;
      region;
    }[];
    Message: {
      id;
      createdAt;
      text;
      User: {
        id;
        firstname;
        lastname;
      };
    };
  }[];
}

const teamsQuery = gql`
  {
    teams(orderBy: { createdAt: desc }) {
      id
      createdAt
      Classroom {
        id
        classroomName
        Phase {
          id
          number
          name
        }
      }
      User {
        id
        email
        firstname
        lastname
        activated
        securityCode
        phone
        region
      }
      Message {
        id
        createdAt
        text
        User {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const createTeamQuery = gql`query CreateTeamQuery {
  createTeam {
      id
  }
}`;

const styles = (theme) => ({
  table: {
    minWidth: 1800
  },
  popover: {
    margin: theme.spacing(2)
  },
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(2)
  }
});

export default (compose as any)(
  withStyles(styles),
  withApollo
)(TeamsTable as any) as FC<Props>;

const getActivePhase = (classroom) => classroom.Phase?.find((phase) => !phase.finished);

const columns = [
  {
    name: "Tým",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Region",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Id",
    options: {
      filter: false,
      sort: false
    }
  }
];
