import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from "react";

import ProjectModalTabs from "../../constants/ProjectModalTabs";
import ProjectModal from "./ProjectModal";

export const ProjectDetailModal: FC<{ children: ReactNode }> = ({ children }) => {
  const [context, setContext] = useState<Omit<Context, "setProjectDetail">>();

  const value = useMemo<Context>(() => ({
    ...context,
    setProjectDetail: (classroomId, { defaultTab, refetch, onClose }) => setContext({
      classroomId,
      defaultTab: defaultTab ?? ProjectModalTabs.PROJECT_DETAIL,
      refetch,
      onClose
    })
  }), [context]);

  return (
    <Context.Provider value={value}>
      {context && (
        <ProjectModal
          classroomId={context.classroomId}
          defaultTab={context.defaultTab}
          onClose={(refetch?: boolean) => {
            refetch && context.refetch?.();
            setContext(null);
            context.onClose?.();
          }}
        />
      )}
      {children}
    </Context.Provider>
  );
};

interface Context {
  classroomId: string;
  defaultTab: keyof typeof ProjectModalTabs;
  refetch?: () => unknown;
  onClose: () => unknown;

  setProjectDetail: (classroomId: string, opts: {
    defaultTab?: keyof typeof ProjectModalTabs;
    refetch?: () => unknown;
    onClose?: () => unknown;
  }) => unknown;
}

const Context = createContext<Context | undefined>(undefined);

export const useProjectDetail = () => useContext(Context);
