import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import { FC, useState } from "react";
import { DataValue, graphql, MutationFn } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import { all } from "../../utils/permissions";
import { useUser } from "../UserContext";

interface Props {
  toolbox: Toolbox;
  classroom: ClassroomAttributes;
  classroomId: string;
  classroomQuery: DataValue<unknown>;
  subclassroomId: string;
  editDisabled?: boolean;
}

interface Toolbox {
  id: string;
  childrenCount: number;
  address: string;
  recipient: string;
}

interface InnerProps {
  classes: any;
  updateToolboxOrder: MutationFn<{
    id: string;
    recipient: string;
    address: string;
    childrenCount: string;
  }>;
  createToolboxOrder: MutationFn<{
    classroomId: string;
    address: string;
    recipient: string;
    childrenCount: string;
    subclassroomId: string;
  }>;

}

const ToolboxForm: FC<Props & InnerProps> = ({
  classes,
  updateToolboxOrder,
  toolbox,
  subclassroomId,
  createToolboxOrder,
  classroomId,
  classroomQuery,
  editDisabled,
  classroom
}) => {
  const user = useUser();
  const isCoreProject = classroom.type === "CORE";
  const isCoreUser = all(["CORE"], user);
  const prefillData = isCoreProject && isCoreUser;
  const [childrenCount, setChildrenCount] = useState(toolbox?.childrenCount);
  const prefilledAddress = prefillData ? classroom.branchAddress : "";
  const [address, setAddress] = useState(toolbox?.address ?? prefilledAddress);
  const prefilledRecipient = prefillData ? classroom.branchRepresentativeEmail : "";
  const [recipient, setRecipient] = useState(toolbox?.recipient ?? prefilledRecipient);
  const [creatingOrder, setCreatingOrder] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        if (toolbox) {
          updateToolboxOrder({
            variables: {
              id: toolbox.id,
              childrenCount,
              recipient,
              address
            }
          }).catch((ex) => {
            console.error("ERROR", ex);
          });
        } else {
          setCreatingOrder(true);
          createToolboxOrder({
            variables: {
              childrenCount,
              recipient,
              address,
              classroomId,
              subclassroomId
            }
          })
            .then(() => {
              enqueueSnackbar("Toolbox úspěšně objednán", {
                variant: "success",
                autoHideDuration: 4000,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              });
              return classroomQuery.refetch();
            })
            .then(() => {
              setCreatingOrder(false);
            });
        }
      }}
    >
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="branchRepresentativeName">Adresát</InputLabel>
        <Input
          id="recipient"
          name="recipient"
          autoFocus
          value={recipient}
          onChange={(e) => setRecipient(e.target.value as any)}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="address">Adresa</InputLabel>
        <Input
          id="address"
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value as any)}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="childrenCount">Počet dětí</InputLabel>
        <Input
          id="childrenCount"
          name="childrenCount"
          value={childrenCount}
          onChange={(e) => setChildrenCount(e.target.value as any)}
        />
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        type="submit"
        disabled={creatingOrder || editDisabled}
      >
        {creatingOrder ? <CircularProgress /> : null}
        {toolbox ? "Uložit" : "Vytvořit"}
      </Button>
    </form>
  );
};

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default compose(
  graphql(
    gql`
      mutation UpdateToolboxOrder(
        $id: ID!
        $recipient: String!
        $address: String!
        $childrenCount: String!
      ) {
        updateToolboxOrder(
          data: {
            id: $id
            recipient: $recipient
            address: $address
            childrenCount: $childrenCount
          }
        ) {
          id
          recipient
          address
          childrenCount
        }
      }
    `,
    {
      name: "updateToolboxOrder"
    }
  ),
  graphql(
    gql`
      mutation CreateToolboxOrder(
        $classroomId: ID!
        $address: String!
        $recipient: String!
        $childrenCount: String!
        $subclassroomId: ID
      ) {
        createToolboxOrder(
          data: {
            address: $address
            recipient: $recipient
            classroomId: $classroomId
            subclassroomId: $subclassroomId
            childrenCount: $childrenCount
          }
        ) {
          id
        }
      }
    `,
    {
      name: "createToolboxOrder"
    }
  ),
  withStyles(styles)
)(ToolboxForm) as any;
