import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import { ClassroomAttributesV2 } from "../../constants/classroomAttributesV2";

export const classroomToRow = (classroom: ClassroomAttributes | ClassroomAttributesV2, colNames: string[]) => [
  {
    name: "",
    content: (
      <Button>
        <Edit />
      </Button>
    )
  },
  {
    name: "Zakladatel",
    content: "creator" in classroom
      ? `${classroom.creator.firstname || "-"} ${classroom.creator.lastname || "-"}`
      : `${classroom.User.firstname || "-"} ${classroom.User.lastname || "-"}`
  },
  {
    name: "Projekt",
    content: classroom.classroomName || "-"
  },
  {
    name: "Škola",
    content: "school" in classroom
      ? `${classroom.school?.street || "-"}, ${classroom.school?.city ?? '-'}`
      : `${classroom.School?.street || "-"}, ${classroom.School?.city ?? '-'}`
  },
  {
    name: "Třídy",
    content: "subclassrooms" in classroom
      ? classroom.subclassrooms?.map(s => s.name) || "-"
      : classroom.Subclassroom?.map(s => s.name) ?? "-"
  },
  {
    name: "Pobočka",
    content: classroom.branchAddress || "-"
  },
  {
    name: "Pololetí",
    content: classroom.semester
      ? `${classroom.semester}. pololetí ${classroom.year}/${classroom.year + 1}`
      : "-"
  },
  {
    name: "Termín oslovení školy",
    content: classroom.schoolFirstContact
      ? moment(classroom.schoolFirstContact).format("L")
      : "-"
  },
  {
    name: "Termín ukázkové hodiny",
    content: "subclassrooms" in classroom
      ? classroom.subclassrooms.map(s => s.exampleEducation) ?? []
      : classroom.Subclassroom.map(s => s.exampleEducation) ?? []
  },
  {
    name: "Termín návštěvy školy",
    content: classroom.schoolMeeting
      ? moment(classroom.schoolMeeting).format("L")
      : "-"
  },
  {
    name: "Termín exkurze",
    content: classroom.excursionDate
      ? moment(classroom.excursionDate).format("L")
      : "-"
  },

  {
    name: "Datum návštěvy při výrobě",
    content: classroom.visitInProduction
      ? moment(classroom.visitInProduction).format("L")
      : "-"
  },
  {
    name: "Datum kávy s učitelem",
    content: classroom.coffeeWithTeacher
      ? moment(classroom.coffeeWithTeacher).format("L")
      : "-"
  },
  {
    name: "Termín jarmarku",
    content: classroom.fairDate
      ? moment(classroom.fairDate).format("L")
      : "-"
  },
  {
    name: "E-jarmark",
    content: ("eFair" in classroom ? classroom.eFair : classroom.EFair) ? "ANO" : "NE"
  },
  {
    name: "Toolbox",
    content: "subclassrooms" in classroom
      ? classroom.subclassrooms.map(s => s.toolboxOrder?.state || "-") ?? []
      : classroom.Subclassroom.map(s => s.ToolboxOrder?.state || "") ?? []
  },
  {
    name: "Název firmy",
    content: classroom.companyName || "-"
  },
  {
    name: "V čem děti podnikají",
    content: classroom.businessDescription || "-"
  },
  {
    name: "Výdělek použití",
    content: classroom.businessPurpose || "-"
  },
  {
    name: "Výdělek (Kč)",
    content: classroom.moneyGoalAmount || "-"
  },
  {
    name: "Oblast",
    content: classroom.area || "-"
  },
  {
    name: "Typ",
    content: classroom.type
      ? classroom.type === "CORE"
        ? "RMKT"
        : "Dobrovolník"
      : "-"
  },
  {
    name: "Tým",
    content: "team" in classroom
      ? classroom.team.users ?? []
      : classroom.Team.User ?? []
  },
  {
    name: "Region",
    content: "school" in classroom
      ? classroom.school
        ? classroom.school?.region
        : "-"
      : classroom.School
        ? classroom.School?.region
        : "-"
  },
  {
    name: "Stav projektu",
    content: "phases" in classroom ? classroom.phases : classroom.Phase
  }
].filter(r => colNames.includes(r.name));

export const wrapWithWrap = (cols: { content: any }[], indexes: number[]) =>
  cols.map((c, i) => indexes.includes(i)
    ? <span style={{ whiteSpace: "normal" }}>{c.content}</span>
    : c.content
  );
