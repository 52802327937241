import { UserContext } from "../components/UserContext";

type Role = { name: string };
type MeQuery = {
  me: {
    roles: Role[];
  };
} | Pick<UserContext, "Role"> | Role[] | string[];

type RoleString = "CORE" | "ADMIN" | "SUPER_ADMIN" | "AGENCY" | "CORE_AGENCY";

export const all = (roles: RoleString[], me: MeQuery) =>
  intersect(roles, normalize(me)).length === roles.length;

export const any = (roles: RoleString[], me: MeQuery) =>
  intersect(roles, normalize(me)).length !== 0;

export const none = (roles: RoleString[], me: MeQuery) =>
  intersect(roles, normalize(me)).length === 0;

const intersect = <T>(a: T[], b: T[]) => {
  const sa = new Set(a);
  const sb = new Set(b);
  return [...new Set([...sa].filter(x => sb.has(x)))];
};

const normalize = (me: MeQuery): string[] =>
  "me" in me
    ? me.me.roles.map(r => r.name)
    : Array.isArray(me)
      ? (me as (Role | string)[]).map(s => typeof s === "string" ? s : s.name)
      : me.Role.map(r => r.name);
