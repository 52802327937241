import { CREATE_SCHOOL, EDIT_SCHOOL } from "./constants";

export const setCreateSchool = (direction) => ({
  type: CREATE_SCHOOL,
  direction
});

export const setEditSchool = (schoolId) => ({
  type: EDIT_SCHOOL,
  schoolId
});
