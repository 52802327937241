import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import { Dispatch, FC, SetStateAction, useState } from "react";

import { ClassroomAttributesV2 } from "../constants/classroomAttributesV2";
import { SchoolAttributesV2 } from "../constants/schoolAttributesV2";
import SchoolDialog from "./SchoolDialog";

interface Props {
  school: SchoolAttributesV2;
  setSchool: Dispatch<SetStateAction<SchoolAttributesV2>>;

  classroom: ClassroomAttributesV2;

  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
}

const SchoolDetail: FC<Props> = ({
  school,
  setSchool,
  name,
  email,
  phone,
  setName,
  setEmail,
  setPhone,
  classroom
}) => {
  const [schoolSearchDialog, setSchoolSearchDialog] = useState(false);

  return (
    <>
      {schoolSearchDialog ? (
        <SchoolDialog
          open
          onClose={(s) => {
            s && setSchool(s);
            setSchoolSearchDialog(false);
          }}
        />
      ) : null}
      <FormControl margin="normal" required fullWidth>
        <InputLabel
          style={{ position: "relative", top: "-10px" }}
          htmlFor="school"
        >
          Název
        </InputLabel>
        <Input
          id="school"
          name="school"
          value={school?.name}
          onClick={() => {
            setSchoolSearchDialog(true);
          }}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="address">Adresa</InputLabel>
        <Input
          id="address"
          name="address"
          value={`${school?.street || "-"}, ${school?.city ?? '-'}`}
          onChange={() => {}}
          onClick={() => {
            setSchoolSearchDialog(true);
          }}
        />
      </FormControl>
      <Typography variant="h5" style={{ marginTop: "24px", marginBottom: 0 }}>
        Ředitel
      </Typography>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="directorName">Jméno</InputLabel>
        <Input
          id="directorName"
          name="directorName"
          value={school?.Contact_ContactToSchool_director?.name ?? ""}
          onChange={() => {}}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="directorEmail">E-mail</InputLabel>
        <Input
          id="directorEmail"
          name="directorEmail"
          value={school?.Contact_ContactToSchool_director?.email ?? ""}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="directorPhone">Telefon</InputLabel>
        <Input
          id="directorPhone"
          name="directorPhone"
          value={school?.Contact_ContactToSchool_director?.phone ?? ""}
        />
      </FormControl>

      <Typography variant="h5" style={{ marginTop: "24px", marginBottom: 0 }}>
        Zástupce
      </Typography>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="alternateName">Jméno</InputLabel>
        <Input
          id="alternateName"
          name="alternateName"
          value={school?.Contact_ContactToSchool_alternate?.name}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="alternateEmail">E-mail</InputLabel>
        <Input
          id="alternateEmail"
          name="alternateEmail"
          value={school?.Contact_ContactToSchool_alternate?.email}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="alternatePhone">Telefon</InputLabel>
        <Input
          id="alternatePhone"
          name="alternatePhone"
          value={school?.Contact_ContactToSchool_alternate?.phone}
        />
      </FormControl>
      {classroom.projectType !== "ZVS" ? (
        <>
          <Typography variant="h5" style={{ marginTop: "24px", marginBottom: 0 }}>
            Učitel
          </Typography>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="name">Jméno a příjmení</InputLabel>
            <Input
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="phone">Telefon</InputLabel>
            <Input
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth style={{ marginBottom: 60 }}>
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </>
      ) : null}
    </>
  );
};

export default SchoolDetail;
