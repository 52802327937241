import Button from "@material-ui/core/Button";
import gql from "graphql-tag";
import { FC, useState } from "react";
import { DataValue, graphql } from "react-apollo";

import CreateEFairModal from "../components/CreateEFairModal";
import EFairsTable from "../components/EFairsTable";
import TableLayout from "../components/Layout/TableLayout";
import { useUser } from "../components/UserContext";
import { any } from "../utils/permissions";

interface InnerProps {
  eFairsQuery: DataValue<{ eFairs: any[] }>;
}

const EFairs: FC<InnerProps> = ({ eFairsQuery }) => {
  const user = useUser();
  const [createEFairForm, setCreateEFairForm] = useState(false);
  const createEnabled = any(["ADMIN", "CORE"], user);

  return (
    <TableLayout title="Přehled e-jarmarků">
      {createEFairForm && createEnabled ? (
        <CreateEFairModal
          onClose={() => {
            setCreateEFairForm(false);
            eFairsQuery.refetch();
          }}
        />
      ) : null}
      <EFairsTable
        id="efairs"
        query={eFairsQuery}
        dataSelector={d => d.eFairs}
        toolbar={createEnabled ? (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "20px", marginLeft: "10px" }}
            onClick={() => {
              setCreateEFairForm(true);
            }}
          >
            Nový e-Jarmark
          </Button>
        ) : null}
      />
    </TableLayout>
  );
};

const eFairsQuery = graphql(
  gql`
    query EFairs {
      eFairs {
        id
        videoLink
        state
        approval
        from
        to
        classroom {
          id
          classroomName
          year
          semester
          schoolAddress
          type
          school {
            id
            street
            name
            city
            address
          }
          creator {
            id
            region
          }
        }
      }
    }
  `,
  {
    name: "eFairsQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network"
    })
  }
);

export default eFairsQuery(EFairs as any) as any as FC;
