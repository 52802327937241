import schoolAttributes, { SchoolAttributes } from "./schoolAttributes";

export interface ClassroomAttributes {
  id: string;
  excursionDate;
  schoolFirstContact: string;
  classroomName: string;
  creator: {
    id;
    firstname;
    lastname;
    region;
  };
  schoolAddress: string;
  directorName: string;
  directorEmail: string;
  directorPhone: string;
  teacherName: string;
  teacherPhone: string;
  teacherEmail: string;
  schoolMeeting: string;
  semester: number;
  year: number;
  branchAddress: string;
  branchRepresentativeEmail: string;
  branchRepresentativePhone: string;
  branchRepresentativeName: string;
  fairDate;
  childrenCount;
  fairNote: string;
  fairElectricity;
  fairAnnexationState;
  fairAnnexationNote;
  fairTime;
  fairEnd;
  kioskReadyTime;
  kioskPlace;
  archived;
  deleted;
  adminNote;
  toolboxOrder: {
    id: string;
    state;
    recipient;
    address;
    childrenCount;
    classroom: {
      id: string;
    };
  };
  phases: {
    id: string;
    name: string;
    finished: boolean;
    finishDate;
    number;
    checklist: {
      id;
      name;
      checked;
    }[];
    files?: {
      id: string;
      name: string;
      path: string;
      fileType: string;
    }[];
  }[];
  companyName: string;
  businessPurpose;
  businessDescription: string;
  moneyGoalAmount;
  team: {
    id;
    users: {
      id;
      firstname;
      lastname;
      activated;
      email;
      region;
    }[];
    messages: {
      id: string;
      createdAt: string;
      text: string;
      author: {
        firstname: string;
        lastname: string;
      };
    }[];
  };
  type: string;
  visitInProduction;
  coffeeWithTeacher;
  fairAgency: {
    id;
    name;
  };
  forgiveLoan;
  forgiveLoanCause;
  nps;
  area;
  school: SchoolAttributes;
  eFairProfit;
  eFair: {
    id: string;
  };
  teacher: {
    id: string;
    name: string;
    phone: string;
    email: string;
  };
  projectType: "AP" | "ZVS";
  subclassrooms: {
    id: string;
    name: string;
    childrenCount;
    note: string;
    teacher: {
      id: string;
      name: string;
      email: string;
      phone: string;
    };
    year;
    exampleEducation;
    toolboxOrder: {
      id: string;
      state: string;
      recipient: string;
      address: string;
      childrenCount: string;
      classroom: {
        id: string;
      };
    };
  }[];
}

export default `
    id
    excursionDate
    schoolFirstContact
    classroomName
    creator {
        id
        firstname
        lastname
        region
    }
    schoolAddress
    directorName
    directorEmail
    directorPhone
    teacherName
    teacherPhone
    teacherEmail
    schoolMeeting
    semester
    year
    branchAddress
    branchRepresentativeEmail
    branchRepresentativePhone
    branchRepresentativeName
    fairDate
    childrenCount
    fairNote
    fairElectricity
    fairAnnexationState
    fairAnnexationNote
    fairTime
    fairEnd
    kioskReadyTime
    kioskPlace
    archived
    deleted
    adminNote
    toolboxOrder {
        id
        state
        recipient
        address
        childrenCount
        classroom {
            id
        }
    }
    phases {
        id
        name
        finished
        finishDate
        number
        checklist {
            id
            name
            checked
        }
    }
    companyName
    businessPurpose
    businessDescription
    moneyGoalAmount
    team {
        id
        users {
            id
            firstname
            lastname
            activated
            email
            region
        }
        messages {
          id
          createdAt
          text
          author {
            firstname
            lastname
          }
        }
    }
    type
    visitInProduction
    coffeeWithTeacher
    fairAgency {
        id
        name
    }
    forgiveLoan
    forgiveLoanCause
    nps
    area
    school {
      ${schoolAttributes}
    }
    eFairProfit
    eFair {
        id
    }
    teacher {
        id
        name
        phone
        email
    }
    projectType
    subclassrooms {
        id
        name
        childrenCount
        note
        teacher {
            id
            name
            email
            phone
        }
        year
        exampleEducation
        toolboxOrder {
            id
            state
            recipient
            address
            childrenCount
            classroom {
                id
            }
        }
    }
    `;
