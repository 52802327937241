import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { FC, useState } from "react";
import { apollo2Client } from "../App";

import MessagesForm from "./forms/MessagesForm";
import TeamUsersForm from "./forms/TeamUsersForm";
import TabPanel from "./TabPanel";
import { useUser } from "./UserContext";

interface Props {
  teamId: string;
  onClose: () => unknown;
}

interface InnerProps {
  classes: any;
}

const TeamModal: FC<Props & InnerProps> = ({ onClose, classes, teamId }) => {
  const user = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const userRoles = user.Role.map(r => r.name);

  const { data, error, refetch } = useQuery<{ team: Team }>(teamQuery, { client: apollo2Client, variables: { id: teamId } });

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{
        paperWidthMd: classes.paper
      }}
    >
      {!data && !error ? <CircularProgress /> : error ? "Error" : (
        <>
          <DialogTitle>Detail týmu</DialogTitle>
          <DialogContent>
            <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
              <Tab label="Uživatelé"></Tab>
              <Tab label="Zprávy"></Tab>
              {/* <Tab label="Poznámka"></Tab> */}
            </Tabs>
            <TabPanel value={activeTab} id={0}>
              <TeamUsersForm team={data.team} refetch={refetch} />
            </TabPanel>
            <TabPanel value={activeTab} id={1}>
              <MessagesForm team={data.team} refetch={refetch} />
            </TabPanel>
            {/* <TabPanel value={activeTab} id={2}>
          <AdminNoteForm team={team} />
        </TabPanel> */}
          </DialogContent>
        </>
      )}

    </Dialog>
  );
};

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default withStyles(styles)(TeamModal) as any as FC<Props>;

export interface Team {
  id: string;
  createdAt: string;
  Classroom: {
    id: string;
    classroomName: string;
    Phase: {
      id;
      number;
      name;
    }[];
  };
  User: {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    activated: boolean;
    securityCode: string;
    phone: string;
    region: string;
  }[];
  Message: {
    id: string;
    createdAt: string;
    text: string;
    User: {
      id: string;
      firstname: string;
      lastname: string;
    };
  }[];
}

const teamQuery = gql`
  query Team($id: String!) {
    team(where: {id: $id}) {
      id
      createdAt
      Classroom {
        id
        classroomName
        Phase {
          id
          number
          name
        }
      }
      User {
        id
        email
        firstname
        lastname
        activated
        securityCode
        phone
        region
      }
      Message {
        id
        createdAt
        text
        User {
          id
          firstname
          lastname
        }
      }
    }
  }
`;
