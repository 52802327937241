import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  background-color: ${props => props.bgColor ? props.bgColor : "#be2321"};
  padding: 20px 40px;
  height: auto;
  color: ${props => props.color ? props.color : "white"};
  border-radius: 4px;
  margin: 50px 0;
`;
const Title = styled.p`
  font-size: 0.8em;
`;

const EFairApprovalText = ({ text, title, color, bgColor }) => {
  return (
    <>
      <Wrap color={color} bgColor={bgColor}>
        <Title>{title}</Title>
        {text}
      </Wrap>
    </>
  );
};

export default EFairApprovalText;
