import React from "react";

import ContentLayout from "../components/Layout/ContentLayout";
import FairsTable from "../components/FairsTable";

const Fairs = () => (
  <ContentLayout title="Jarmarky">
    <FairsTable />
  </ContentLayout>
);

export default Fairs;
