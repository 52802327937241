import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { useState } from "react";
import { graphql } from "react-apollo";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const SelectUnassignedUserModal = ({
  onClose,
  classes,
  unassignedVolunteersQuery,
  unassignedCoreUsersQuery,
  assignUserMutation,
  teamId
}) => {
  const [loading, setLoading] = useState(false);
  const unassignedUsers = [];
  const coreUsers = unassignedCoreUsersQuery.unassignedCoreUsers;
  const volunteers = unassignedVolunteersQuery.unassignedVolunteers;

  if (coreUsers && volunteers) {
    coreUsers.forEach(el => {
      unassignedUsers.push(el);
    });
    volunteers.forEach(el => {
      unassignedUsers.push(el);
    });
  }

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{
        paperWidthMd: classes.paper
      }}
    >
      <DialogTitle>Vybrat uživatele {loading ? <CircularProgress /> : null}</DialogTitle>
      <DialogContent>
        {unassignedVolunteersQuery.loading ? <CircularProgress /> : (
          <List>
            {unassignedUsers?.map((user) => (
              <ListItem
                key={user.id}
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                    assignUserMutation({
                      variables: {
                        id: teamId,
                        userId: user.id
                      }
                    }).then(() => {
                      setLoading(false);
                      onClose();
                    });
                  }
                }}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd"
                }}
              >
                <ListItemText>
                  {`${user.firstname} ${user.lastname} (${user.email})`}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  graphql(
    gql`query UnassignedVolunteers {
            unassignedVolunteers {
                id
                email
                firstname
                lastname
            }
        }
        `,
    {
      name: "unassignedVolunteersQuery",
      options: {
        fetchPolicy: "network-only"
      }
    }
  ),
  graphql(
    gql`query UnassignedCoreUsers {
            unassignedCoreUsers {
                id
                email
                firstname
                lastname
            }
        }
        `,
    {
      name: "unassignedCoreUsersQuery",
      options: {
        fetchPolicy: "network-only"
      }
    }
  ),
  graphql(
    gql`mutation AssignUserMutation($id: ID!, $userId: ID!) {
            updateTeam(data: {
                id: $id
                users: {
                    connect: {
                        id: $userId
                    }
                }
            }) {
                id
                users {
                    id
                    email
                    firstname
                    lastname
                    phone
                    region
                }
            }
        }
        `, {
      name: "assignUserMutation"
    }
  ),
  withStyles(styles)
)(SelectUnassignedUserModal) as any;
