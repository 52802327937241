import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import React, { useState } from "react";
import { graphql } from "react-apollo";

import schoolAttributes from "../constants/schoolAttributes";
import ClassroomsTable from "./ClassroomsTable";
import EditContactForm from "./forms/EditContactForm";
import EditSchoolForm from "./forms/EditSchoolForm";
import TabPanel from "./TabPanel";

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

const EditSchoolModal = ({ schoolQuery, onClose, classes }) => {
  const [activeTab, setActiveTab] = useState("SCHOOL");
  const school = schoolQuery.school;
  const schoolClassrooms = prop("classrooms")(school);

  return (
    <Dialog
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="xl"
      classes={{
        paperWidthXl: classes.paper
      }}
    >
      <DialogTitle>Detail školy</DialogTitle>
      <DialogContent>
        {schoolQuery.loading
          ? <CircularProgress />
          : (
            <>
              <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Škola"
                  value="SCHOOL"
                />
                <Tab
                  label="Ředitel"
                  value="DIRECTOR"
                />
                <Tab
                  label="Zástupce"
                  value="ALTERNATE"
                />
                <Tab
                  label="Historie spolupráce"
                  value="CLASSROOMS"
                />
              </Tabs>
              <TabPanel value={activeTab} id="SCHOOL">
                <EditSchoolForm
                  school={school}
                  onClose={() => {
                    onClose(true);
                  }}
                />
              </TabPanel>
              <TabPanel value={activeTab} id="DIRECTOR">
                <EditContactForm
                  contact={school.director || {}}
                />
              </TabPanel>
              <TabPanel value={activeTab} id="ALTERNATE">
                <EditContactForm
                  contact={school.alternate || {}}
                />
              </TabPanel>
              <TabPanel value={activeTab} id="CLASSROOMS">
                <ClassroomsTable data={schoolClassrooms} refetch={schoolQuery.refetch} />
              </TabPanel>
            </>
          )}
      </DialogContent>
    </Dialog>
  );
};

EditSchoolModal.propTypes = {

};

const schoolQuery = graphql(gql`
    query School($id: ID!) {
        school(id: $id) {
            ${schoolAttributes}
        }
    }
`, {
  name: "schoolQuery",
  options: ({ id }) => ({
    fetchPolicy: "network-only",
    variables: {
      id
    }
  })
});

export default compose(
  withStyles(styles),
  schoolQuery
)(EditSchoolModal);
