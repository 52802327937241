import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { FC, useState } from "react";
import { graphql, MutationFn } from "react-apollo";

interface Props {
  onSubmit?: () => unknown | Promise<unknown>;
  onClose: () => unknown;
  teamId: string;
}

interface InnerProps {
  classes: any;
  createMessageMutation: MutationFn<unknown, { text: string; teamId: string }>;
}

const CreateMessageModal: FC<Props & InnerProps> = ({ onClose, classes, teamId, createMessageMutation, onSubmit }) => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{
        paperWidthMd: classes.paper
      }}
    >
      <DialogTitle>Poslat týmu zprávu</DialogTitle>
      <DialogContent>
        <form
          className={classes.form}
          onSubmit={async (e) => {
            setLoading(true);
            e.preventDefault();
            try {
              await createMessageMutation({
                variables: {
                  text,
                  teamId
                }
              });

              await onSubmit?.();
              setLoading(false);
              onClose();
            } catch (ex) {
              setLoading(false);
              console.error("ERROR", ex);
            }
          }}
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="text">Zpráva</InputLabel>
            <Input
              id="text"
              name="text"
              autoFocus
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Odeslat"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const createMessageMutation = graphql(gql`
  mutation CreateMessageMutation($text: String!, $teamId: ID!,) {
    createMessage(text: $text, teamId: $teamId) {
      id
      text
    }
  }
`, {
  name: "createMessageMutation"
});

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default createMessageMutation(withStyles(styles)(CreateMessageModal) as any) as any as FC<Props>;
