import gql from "graphql-tag";
import { graphql } from "react-apollo";

import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import classroomAttributes from "../constants/classroomAttributes";

const Projects = ({ classroomsQuery }) => (
  <TableLayout title="Přehled projektů">
    <ProjectsTable
      id="Projects"
      query={classroomsQuery}
      dataSelector={d => d.classrooms}
      projectType="AP"
    />
  </TableLayout>
);

const classroomsQuery = graphql(gql`
    {
        classrooms(projectType: AP) {
            ${classroomAttributes}
        }
    }
`, {
  name: "classroomsQuery",
  options: {
    fetchPolicy: "cache-and-network"
  }
});

export default classroomsQuery(Projects);
