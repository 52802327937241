import Button from "@material-ui/core/Button";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { graphql } from "react-apollo";

import CreateClassroomModal from "../components/CreateClassroomModal";
import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import classroomAttributes from "../constants/classroomAttributes";

const ClassroomManagement = ({ myClassroomsQuery, projectType }) => {
  const [createClassroomForm, setCreateClassroomForm] = useState(false);
  const [lastCreatedClassroomId, setLastCreatedClassroomId] = useState(null);

  const onModalClose = useCallback(() => setLastCreatedClassroomId(null), []);

  return (
    <TableLayout title="Projekty">
      {createClassroomForm ? (
        <CreateClassroomModal
          projectType={projectType}
          onClose={(classroomId) => {
            setCreateClassroomForm(false);
            myClassroomsQuery.refetch().then(() => {
              setLastCreatedClassroomId(classroomId);
            });
          }}
        />
      ) : null}

      <ProjectsTable
        id="ClassroomManagement"
        query={myClassroomsQuery}
        dataSelector={d => d.myClassrooms}
        defaultDetail={myClassroomsQuery?.myClassrooms?.find((classroom) => classroom.id === lastCreatedClassroomId)}
        projectType={projectType}
        onModalClose={onModalClose}
        toolbar={(
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreateClassroomForm(true);
            }}
          >
            Vytvořit třídu
          </Button>
        )}
      />
    </TableLayout>
  );
};

const myClassroomsQuery = graphql(gql`
    query MyClassrooms ($projectType: ProjectType){
        myClassrooms(projectType: $projectType) {
            ${classroomAttributes}
        }
    }
`, {
  name: "myClassroomsQuery",
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      projectType: props.projectType
    }
  })
});

export default myClassroomsQuery(ClassroomManagement);
