import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { apollo2Client } from "../App";
import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { ClassroomAttributesV2, classroomAttributesV2 } from "../constants/classroomAttributesV2";

const DeletedClassrooms = ({ projectType }) => {
  const query = useQuery<DeletedQuery>(archiveQuery, { client: apollo2Client, variables: { projectType } });
  return (
    <TableLayout title="Koš">
      <ProjectsTable
        id="DeletedClassroom"
        query={query}
        dataSelector={d => d.deleted}
        projectType={projectType}
        isArchive
      />
    </TableLayout>
  );
};

type DeletedQuery = {
  deleted: ClassroomAttributesV2[];
};

const archiveQuery = gql`
    query Deleted($projectType: String) {
        deleted(where: {projectType: {equals: $projectType}}) {
            ${classroomAttributesV2}
        }
    }
`;

export default DeletedClassrooms;
