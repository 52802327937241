import gql from "graphql-tag";
import React, { useState } from "react";
import { graphql } from "react-apollo";

import TableLayout from "../components/Layout/TableLayout";
import SchoolDetailModals from "../components/SchoolDetailModals";
import SchoolsTable from "../components/SchoolsTable";
import schoolAttributesSimple from "../constants/schoolAttributesSimple";

const Schools = ({ schoolsQuery }) => {
  return (
    <TableLayout title="Školy">
      <SchoolDetailModals schoolsQuery={schoolsQuery} />
      <SchoolsTable id="schools" schoolsQuery={schoolsQuery} />
    </TableLayout>
  );
};

Schools.propTypes = {

};

const schoolsQuery = graphql(gql`
    {
        schools {
            ${schoolAttributesSimple}
        }
    }
`, {
  name: "schoolsQuery",
  options: {
    fetchPolicy: "cache-and-network"
  }
});

export default schoolsQuery(Schools);
