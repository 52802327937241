import React from "react";

import ContentLayout from "../components/Layout/ContentLayout";

const SeniorAPManagement = () => (
  <ContentLayout title="AP pro seniory">
    TO BE DONE
  </ContentLayout>
);

export default SeniorAPManagement;
