import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { FC } from "react";

import { apollo2Client } from "../App";
import TableLayout from "../components/Layout/TableLayout";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { ClassroomAttributesV2, classroomAttributesV2 } from "../constants/classroomAttributesV2";

interface Props {
  projectType: "ZVS" | "AP";
}

const CoreProjects: FC<Props> = ({ projectType }) => {
  const query = useQuery <CoreClassroomsQuery>(coreClassroomsQuery, {
    client: apollo2Client,
    variables: { projectType }
  });

  return (
    <TableLayout title="Přehled RMKT">
      <ProjectsTable
        id="CoreProjects"
        query={query}
        dataSelector={d => d.coreClassrooms}
        projectType={projectType}
      />
    </TableLayout>
  );
};

type CoreClassroomsQuery = {
  coreClassrooms: ClassroomAttributesV2[];
};

const coreClassroomsQuery = gql`
    query classrooms($projectType: String!){
        coreClassrooms(where: {projectType: {equals: $projectType}}) {
            ${classroomAttributesV2}
        }
    }
`;

export default CoreProjects;
