import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import gql from "graphql-tag";
import { FC, useState } from "react";

import { apollo2Client } from "../App";
import { SchoolAttributesV2, schoolAttributesV2 } from "../constants/schoolAttributesV2";
import { all } from "../utils/permissions";
import { useUser } from "./UserContext";

interface Props {
  open: boolean;
  onClose: (s?: SchoolAttributesV2) => unknown;
}

const SchoolDialog: FC<Props> = ({
  open,
  onClose
}) => {
  const [search, setSearch] = useState("");
  const user = useUser();
  const isCore = all(["CORE"], user);

  const query = useQuery<{ schools: SchoolAttributesV2[] }>(schoolsQuery, {
    client: apollo2Client,
    skip: search.length < 3,
    variables: { query: search }
  });

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
    >
      <DialogContent style={{ minWidth: "500px", minHeight: "500px" }}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="search">Hledat (alespoň 3 znaky)</InputLabel>
          <Input
            id="search"
            name="search"
            value={search}
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
          />
        </FormControl>
        {!query.loading
          ? query.data?.schools.filter((school) => !isCore || school.region === user.region).map((schoolRec) => (
            <Button
              fullWidth
              key={schoolRec.id}
              onClick={() => {
                setSearch("");
                onClose(schoolRec);
              }}
            >
              {schoolRec.name}, {schoolRec.city}, {schoolRec.street}
            </Button>
          ))
          : <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};

const schoolsQuery = gql`
  query SearchSchools($query: String!) {
    schools(where: {OR: [
      {street: {contains: $query, mode: insensitive}},
      {city: {contains: $query, mode: insensitive}},
      {name: {contains: $query, mode: insensitive}},
      {region: {contains: $query, mode: insensitive}}
    ]}) {
      ${schoolAttributesV2}
    }
  }
`;

export default SchoolDialog;

export const getSchoolInfo = (s: SchoolAttributesV2) => `${s.name}, ${s.street}, ${s.city}`;
