import gql from "graphql-tag";
import { graphql } from "react-apollo";

const updateEFairMutation = graphql(
  gql`
    mutation UpdateEFairMutation(
      $id: ID!
      $semester: Int
      $state: String
      $approval: String
      $approvalText: String
      $videoLink: String
      $videoLinkPublic: String
      $introText: String
      $introTextPublic: String
      $goal: String
      $goalPublic: String
      $from: DateTime
      $fromPublic: DateTime
      $to: DateTime
      $toPublic: DateTime
      $consent: Boolean
      $hideReason: String
      $products: [UpdateProductInput]
    ) {
      updateEFair(
        data: {
          id: $id
          semester: $semester
          state: $state
          approval: $approval
          approvalText: $approvalText
          videoLink: $videoLink
          videoLinkPublic: $videoLinkPublic
          introText: $introText
          introTextPublic: $introTextPublic
          goal: $goal
          goalPublic: $goalPublic
          from: $from
          fromPublic: $fromPublic
          to: $to
          toPublic: $toPublic
          consent: $consent
          hideReason: $hideReason
          products: $products
        }
      ) {
        id
        semester
        state
        approval
        approvalText
        videoLink
        videoLinkPublic
        introText
        introTextPublic
        goal
        goalPublic
        from
        fromPublic
        to
        toPublic
        fairManager {
          id
        }
        consent
        products {
          photoLink
          photoLinkPublic
          name
          namePublic
          description
          descriptionPublic
          price
          pricePublic
          amount
          amountPublic
        }
      }
    }
  `,
  {
    name: "updateEFairMutation"
  }
);

export default updateEFairMutation;
