/* eslint-disable react/jsx-key */
import { useQuery } from "@apollo/client";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import withStyles from "@material-ui/core/styles/withStyles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { graphql, MutationFn } from "react-apollo";

import { apollo2Client } from "../App";
import CreateAdminUser from "./CreateAdminUser";
import { DataGrid } from "./DataGrid";
import { useDataGrid } from "./DataGrid/useDataGrid";
import { withErrorBoundary } from "./ErrorBoundary";
import UserDetail from "./UserDetail";

interface Props {
  id: string;
}

interface InnerProps {
  classes: any;
  unbanMutation: MutationFn<{}>;
  banMutation: MutationFn<{}>;
  forgotPasswordMutation: MutationFn<{}>;
}

interface UsersQuery {
  users: {
    id: string;
    email: string;
    phone;
    firstname: string;
    lastname: string;
    activated: boolean;
    securityCode: string;
    banned: boolean;
    region;
    Role: {
      name: string;
    }[];
    Team: {
      id: string;
      Classroom: {
        id: string;
        classroomName: string;
      }[];
    };
  }[];
}

const UsersTable: FC<Props & InnerProps> = ({ id, classes, banMutation, unbanMutation, forgotPasswordMutation }) => {
  const [user, setUser] = useState(null);
  const [createAdmin, setCreateAdmin] = useState(false);

  const query = useQuery<UsersQuery>(usersQuery, { client: apollo2Client });
  const { state, ...dataGrid } = useDataGrid({
    id,
    columns,
    options: {
      onCellClick: (_, { dataIndex }) => {
        setUser(query.data.users[dataIndex]);
      }
    }
  });

  const data = useMemo(() => query.data?.users.map(u => [
    <Badge color={u.activated ? "primary" : "error"} variant="dot">
      <AccountCircle />
    </Badge>,
    <Button
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (u.banned) {
          unbanMutation({
            variables: {
              id: u.id
            }
          }).then(() => {
            query.refetch();
          });
        } else {
          banMutation({
            variables: {
              id: u.id
            }
          }).then(() => {
            query.refetch();
          });
        }
      }}
    >
      {u.banned ? "Aktivovat" : "Blokovat"}
    </Button>,
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        forgotPasswordMutation({
          variables: { email: u.email }
        }).then(() => {
          alert("Heslo obnoveno");
        });
      }}
    >
      Obnovit
    </Button>,
    `${u.firstname || ""} ${u.lastname || ""}`,
    u.securityCode,
    <>
      {u.Team?.Classroom.map(c => (
        <React.Fragment key={c.id}>
          {c.classroomName && (
            <>
              {c.classroomName} <br />
            </>
          )}
        </React.Fragment>
      ))}
      {!!u.Team?.Classroom.filter(f => !f.classroomName).length && (
        <i>
          + {u.Team.Classroom.filter(f => !f.classroomName).length} tříd bez názvu
        </i>
      )}
    </>,
    u.email,
    u.phone,
    u.Role.map((role) =>
      <React.Fragment key={role.name}>{role.name} <br /></React.Fragment>
    )
  ]), [banMutation, forgotPasswordMutation, query, unbanMutation]);

  if (query.loading) return <CircularProgress />;

  if (query.error) {
    return (
      <SnackbarContent
        className={classes.errorMessage}
        message="Načtení se nezdařilo"
      />
    );
  }

  return (
    <>
      {user
        ? <UserDetail user={user} onClose={() => setUser(null)} />
        : null}
      {createAdmin ? (
        <CreateAdminUser
          onClose={() => {
            query.refetch();
            setCreateAdmin(false);
          }}
        />
      ) : null}

      <DataGrid
        {...dataGrid}
        loading={query.loading}
        data={data}
        toolbar={(
          <Button
            variant="outlined"
            onClick={() => setCreateAdmin(true)}
          >
            Vytvořit uživatele adm. aplikace
          </Button>
        )}
      />
    </>
  );
};

const usersQuery = gql`
    query Users {
      users {
        id
        email
        phone
        firstname
        lastname
        activated
        securityCode
        banned
        region
        Role {
          name
        }
        Team {
          id
          Classroom {
            id
            classroomName
          }
        }
      }
    }
`;

const banMutation = graphql(gql`
    mutation BanUser($id: ID!) {
        banUser(id: $id) {
            id
            banned
        }
    }
`, {
  name: "banMutation"
});

const unbanMutation = graphql(gql`
    mutation UnbanUser($id: ID!) {
        unbanUser(id: $id) {
            id
            banned
        }
    }
`, {
  name: "unbanMutation"
});

const forgotPassword = graphql(gql`
    mutation ForgotPasswordMutation($email: String!) {
        forgotPassword(email: $email)
    }
`, {
  name: "forgotPasswordMutation"
});

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing()
  }
});

export default withErrorBoundary(compose(
  withStyles(styles),
  banMutation,
  unbanMutation,
  forgotPassword
)(UsersTable as any) as FC<Props>);

const columns = [
  {
    name: "",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Obnova hesla",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "Jméno",
    options: {
      filter: true
    }
  },
  {
    name: "Bezpečnostní kód",
    options: {
      filter: true
    }
  },
  {
    name: "Projekty",
    options: {
      filter: true
    }
  },
  {
    name: "E-mail",
    options: {
      filter: true
    }
  },
  {
    name: "Telefon",
    options: {
      filter: true
    }
  },
  {
    name: "Role",
    options: {
      filter: true
    }
  }
];
