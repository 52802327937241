import moment from "moment";
import { MUIDataTableColumn } from "mui-datatables";
import React from "react";

import { User } from "../../../types/User";
import { ClassroomAttributes } from "../../constants/classroomAttributes";

export const getActivePhase = (classroom: Pick<ClassroomAttributes, "phases">) =>
  classroom.phases?.find(p => !p.finished);

// eslint-disable-next-line max-len
export const ZVS_COLUMNS = ["", "Zakladatel", "Škola", "Třídy", "Pobočka", "Pololetí", "Termín oslovení školy", "Termín ukázkové hodiny", "Toolbox", "Typ", "Region", "Stav projektu"];
// eslint-disable-next-line max-len
export const AP_COLUMNS = ["", "Zakladatel", "Škola", "Projekt", "Pobočka", "Pololetí", "Termín návštěvy školy", "Termín exkurze", "Datum návštěvy při výrobě", "Datum kávy s učitelem", "Termín jarmarku", "E-jarmark", "Toolbox", "Název firmy", "V čem děti podnikají", "Výdělek použití", "Výdělek (Kč)", "Oblast", "Typ", "Tým", "Region", "Stav projektu"];

export default (projectType, isCoreUser): MUIDataTableColumn[] => {
  const allColumns = [
    {
      name: "",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "Zakladatel",
      options: {
        filter: true,
        customBodyRender: (value) => (
          <div style={{ whiteSpace: "normal" }}>
            {value}
          </div>
        )
      }
    },
    {
      name: "Projekt",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div style={{ whiteSpace: "normal" }}>
            {value}
          </div>
        )
      }
    },
    {
      name: "Škola",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div style={{ whiteSpace: "normal" }}>
            {value}
          </div>
        )
      }
    },
    {
      name: "Třídy",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value = []) => (
          <div>
            {value.map((name) => <>{name || "-"} <br /></>)}
          </div>
        )
      }
    },
    {
      name: "Pobočka",
      options: {
        filter: false
      }
    },
    {
      name: "Pololetí",
      options: {
        filter: true
      }
    },
    {
      name: "Termín oslovení školy",
      options: {
        filter: false
      }
    },
    {
      name: "Termín ukázkové hodiny",
      options: {
        filter: false,
        customBodyRender: (value = []) =>
          value.map((date) => <>{date ? moment(date).format("L") : "-"} <br /></>)
      }
    },
    {
      name: "Termín návštěvy školy",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div
            style={{
              padding: "24px",
              backgroundColor:
                                !value || value !== "-" ? "lightgreen" : "transparent"
            }}
          >
            {value}
          </div>
        )
      }
    },
    {
      name: "Termín exkurze",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div
            style={{
              padding: "24px",
              backgroundColor:
                                !value || value !== "-" ? "lightgreen" : "transparent"
            }}
          >
            {value}
          </div>
        )
      }
    },
    ...isCoreUser
      ? [
        {
          name: "Datum návštěvy při výrobě",
          options: {
            filter: false
          }
        },
        {
          name: "Datum kávy s učitelem",
          options: {
            filter: false
          }
        }
      ]
      : [],
    {
      name: "Termín jarmarku",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div
            style={{
              padding: "24px",
              backgroundColor:
                                !value || value !== "-" ? "lightgreen" : "transparent"
            }}
          >
            {value}
          </div>
        )
      }
    },
    {
      name: "E-jarmark",
      options: {
        filter: false
      }
    },
    {
      name: "Toolbox",
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (Array.isArray(value)) {
            return value.map((state) => <>{state} <br /></>);
          }
          return value;
        }
      }
    },
    {
      name: "Název firmy",
      options: {
        filter: false
      }
    },
    {
      name: "V čem děti podnikají",
      options: {
        filter: false
      }
    },
    {
      name: "Výdělek použití",
      options: {
        filter: false
      }
    },
    {
      name: "Výdělek (Kč)",
      options: {
        filter: false
      }
    },
    {
      name: "Oblast",
      options: {
        filter: false
      }
    },
    {
      name: "Typ",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "Tým",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: User[]) => (
          <div>
            {Array.isArray(value) ? value.map((user: User) => (
              <React.Fragment key={user.id}>
                {user.activated
                  ? `${user.firstname} ${user.lastname}`
                  : user.email}
                <br />
              </React.Fragment>
            )) : value}
          </div>
        ),
        search: (query, values) => {
          return !!values.find((user: User) => {
            const valueString = user.activated
              ? `${user.firstname} ${user.lastname}`
              : user.email;
            return !!valueString?.toLowerCase().includes(query.toLowerCase());
          });
        }
      }
    },
    ...isCoreUser
      ? []
      : [
        {
          name: "Region",
          options: {
            filter: true,
            sort: true
          }
        },
        {
          name: "Stav projektu",
          options: {
            filter: false,
            customBodyRender: (phases: ClassroomAttributes["phases"]) => {
              const phase = getActivePhase({ phases });
              const cellString = phase
                ? `${phase.number}/${phases.length}: ${phase.name}`
                : "Dokončeno";
              return (
                <div>
                  {cellString}
                  <div>
                    {phase?.checklist?.map((task: any) => (
                      <span
                        key={task.id}
                        style={{
                          color: task.checked ? "green" : "red",
                          fontSize: "22px"
                        }}
                      >
                        •
                      </span>
                    ))}
                  </div>
                </div>
              );
            },
            search: (query, phases) => {
              const phase = getActivePhase({ phases });
              const cellString = phase
                ? `${phase.number}/${phases.length}: ${phase.name}`
                : "Dokončeno";
              return !!cellString?.toLowerCase().includes(query ? query.toLowerCase() : "");
            }
          }
        }
      ]
  ];
  return filterColumnsByProjectType(projectType, allColumns);
};

export const filterColumnsByProjectType = (
  projectType: "AP" | "ZVS",
  columns: MUIDataTableColumn[]
) => {
  const visibleColumns = projectType === "ZVS" ? ZVS_COLUMNS : AP_COLUMNS;
  return columns.filter((column: any) => visibleColumns.includes(column.name));
};
