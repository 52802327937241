import { QueryResult, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import compose from "ramda/src/compose";
import { FC, useState } from "react";
import { graphql, MutationFn, withApollo } from "react-apollo";
import styled from "styled-components";

import { apollo2Client } from "../App";
import SchoolDetail from "../components/SchoolDetail";
import SubmitButton from "../components/SubmitButton";
import { classroomAttributesV2, ClassroomAttributesV2 } from "../constants/classroomAttributesV2";
import schoolAttributes from "../constants/schoolAttributes";

interface Props {
  classroomId: string;
  editDisabled?: boolean;

  classes?: any;
}

interface InnerProps {
  classes: any;
  query: QueryResult<{ classroom: ClassroomAttributesV2 }>;
  client: ApolloClient<unknown>;
  changeClassroomSchool: MutationFn<never>;
  createContactMutation: MutationFn<never>;
  updateContactMutation: MutationFn<never>;
}

const SchoolForms: FC<Props & InnerProps> = ({
  classes,
  query: { data: { classroom } },
  changeClassroomSchool,
  editDisabled,
  client,
  createContactMutation,
  updateContactMutation
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [school, setSchool] = useState(classroom.School);

  const [name, setName] = useState(classroom.Contact?.name || "");
  const [email, setEmail] = useState(classroom.Contact?.email || "");
  const [phone, setPhone] = useState(classroom.Contact?.phone || "");

  const classroomId = classroom.id;

  return (
    <>
      <form className={classes.form} style={{ height: "750px" }}>
        <SchoolDetail
          classroom={classroom}
          school={school}
          setSchool={setSchool}
          name={name}
          email={email}
          phone={phone}
          setName={setName}
          setEmail={setEmail}
          setPhone={setPhone}
        />
        <Buttons>
          <SubmitButton
            classes={classes}
            editDisabled={editDisabled}
            color="primary"
            value="Uložit školu"
            actionHandler={(e) => {
              e.preventDefault();
              changeClassroomSchool({
                variables: {
                  id: classroom.id,
                  schoolId: school.id
                }
              })
                .then(() => {
                  enqueueSnackbar("Projekt byl úspěšně uložen", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                })
                .catch((ex) => {
                  console.error("ERROR", ex);
                });
            }}
          />
          {classroom.projectType !== "ZVS" ? (
            <SubmitButton
              classes={classes}
              editDisabled={editDisabled}
              color="primary"
              value="Uložit učitele"
              actionHandler={(e) => {
                e.preventDefault();
                const isCreate = !classroom.Contact?.id && classroomId;
                const saveAction = isCreate
                  ? createContactMutation({
                    variables: {
                      name,
                      email,
                      phone,
                      classroomId,
                      contactType: "TEACHER"
                    }
                  })
                  : updateContactMutation({
                    variables: {
                      id: classroom.Contact?.id,
                      name,
                      email,
                      phone
                    }
                  });
                saveAction
                  .then(() => {
                    enqueueSnackbar("Kontakt byl úspěšně uložen", {
                      variant: "success",
                      autoHideDuration: 4000,
                      anchorOrigin: {
                        horizontal: "center",
                        vertical: "top"
                      }
                    });
                    if (isCreate) {
                      client.query({
                        query: classroomQuery,
                        fetchPolicy: "network-only",
                        variables: {
                          id: classroomId
                        }
                      });
                    }
                  })
                  .catch((ex) => {
                    console.error("ERROR", ex);
                  });
              }}
            />
          ) : null}
        </Buttons>
      </form>
    </>
  );
};

const changeClassroomSchool = graphql(
  gql`
        mutation ChangeClassroomSchool($id: ID!, $schoolId: ID!) {
            changeClassroomSchool(
                id: $id,
                schoolId: $schoolId,
            ) {
                id
                school {
                    ${schoolAttributes}
                }
            }
        }
    `,
  {
    name: "changeClassroomSchool"
  }
);

const updateContactMutation = graphql(
  gql`
    mutation UpdateContact(
      $id: ID!
      $name: String
      $email: String
      $phone: String
    ) {
      updateContact(
        data: { id: $id, name: $name, email: $email, phone: $phone }
      ) {
        id
        name
        phone
        email
      }
    }
  `,
  {
    name: "updateContactMutation"
  }
);

const createContactMutation = graphql(
  gql`
    mutation CreateContact(
      $name: String
      $email: String
      $phone: String
      $classroomId: ID!
      $contactType: String!
    ) {
      createContact(
        data: {
          name: $name
          phone: $phone
          email: $email
          classroomId: $classroomId
          contactType: $contactType
        }
      ) {
        id
        name
        phone
        email
      }
    }
  `,
  {
    name: "createContactMutation"
  }
);

type ClassroomQuery = {
  classroom: ClassroomAttributesV2;
};

const classroomQuery = gql`
  query Classroom($id: String!) {
    classroom(where: {id: $id}) {
      ${classroomAttributesV2}
    }
  }
`;

const ClassroomFetch: FC<Props & InnerProps> = p => {
  const query = useQuery <ClassroomQuery>(classroomQuery, { client: apollo2Client, variables: { id: p.classroomId } });

  return query.data ? <SchoolForms {...p} query={query} /> : <CircularProgress />;
};

const styles = {
  paper: {
    alignSelf: "flex-start"
  }
};

export default (compose as any)(
  withApollo,
  changeClassroomSchool,
  createContactMutation,
  updateContactMutation,
  withStyles(styles)
)(ClassroomFetch as any) as FC<Props>;

const Buttons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  padding: 10px 20px;
  border-radius: 4px;
`;
