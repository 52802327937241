import { QueryResult } from "@apollo/client";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Theme } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from "react";
import { DataValue } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import { ClassroomAttributesV2 } from "../../constants/classroomAttributesV2";
import ProjectModalTabs from "../../constants/ProjectModalTabs";
import { all } from "../../utils/permissions";
import { DataGrid } from "../DataGrid";
import { useDataGrid } from "../DataGrid/useDataGrid";
import { useProjectDetail } from "../ProjectDetailModal";
import { useUser } from "../UserContext";
import { classroomToRow } from "./classroomToRow";
import getInitialColumns, { AP_COLUMNS, getActivePhase, ZVS_COLUMNS } from "./getInitialColumns";

interface Props<T extends object> {
  id?: string;
  query: DataValue<T> | QueryResult<T>;
  dataSelector: (q: T) => (ClassroomAttributes | ClassroomAttributesV2)[];
  defaultDetail?: (ClassroomAttributes | ClassroomAttributesV2);
  projectType: "AP" | "ZVS";
  isArchive?: unknown;
  toolbar?: ReactNode;
  onModalClose?: () => unknown;
}

interface InnerProps {
  classes: {
    errorMessage: string;
  };
}

const ProjectsTable = <T extends object>({
  id,
  classes,
  query,
  dataSelector,
  defaultDetail,
  projectType = "AP",
  isArchive = false,
  toolbar,
  onModalClose
}: Props<T> & InnerProps): ReactElement => {
  const user = useUser();
  const { setProjectDetail, classroomId } = useProjectDetail();

  const isCoreUser = all(["CORE"], user);
  const userRegion = user.region;

  const columnDefinitions = useMemo(() => getInitialColumns(projectType, isCoreUser), [isCoreUser, projectType]);

  const colNames = columnDefinitions.map((colDef) => colDef.name);

  const rawData = useMemo(() =>
    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    (("data" in query ? query.data : query) && dataSelector("data" in query ? query.data : query as any)) || [],
  [dataSelector, query]);

  const onCellClick = useCallback((colData, colMetadata) => {
    const classroom = rawData[colMetadata.dataIndex];
    if (classroom) {
      setProjectDetail(classroom.id, { defaultTab: ProjectModalTabs.PROJECT_DETAIL, refetch: query.refetch });
    }
  }, [query.refetch, rawData, setProjectDetail]);

  const { state, ...dataGrid } = useDataGrid({
    id: `${id}-${projectType}`,
    defaultColumns: projectType === "AP" ? AP_COLUMNS : ZVS_COLUMNS,
    columns: columnDefinitions,
    options: {
      onCellClick
    }
  });

  useEffect(() => {
    defaultDetail && defaultDetail.id !== classroomId && setProjectDetail(defaultDetail.id, {
      refetch: query.refetch,
      onClose: onModalClose
    });
  }, [classroomId, defaultDetail, onModalClose, query.refetch, setProjectDetail]);

  const sortIndex = state.sort && colNames.findIndex(c => c === state.sort?.name);

  const data = useMemo(() => rawData
    .filter((classroom) => {
      const region = "team" in classroom
        ? classroom?.team?.users?.[0]?.region
        : classroom?.Team?.User?.[0]?.region;
      // eslint-disable-next-line @typescript-eslint/no-extra-parens
      return !isArchive || !isCoreUser || (isArchive && isCoreUser && !!userRegion && !!region && userRegion === region);
    })
    .map(c => classroomToRow(c, colNames))
    .sort((a, b) => sortIndex === undefined
      ? 0
      : customSort(isCoreUser, projectType, a[sortIndex].content, b[sortIndex].content, sortIndex, state.sort?.direction)
    )
    // .map(c => wrapWithWrap(c, [1, 2]))
    .map(c => c.map(col => col.content)),
  [colNames, isArchive, isCoreUser, projectType, rawData, sortIndex, state.sort, userRegion]);

  if (query.error) {
    return (
      <SnackbarContent
        className={classes.errorMessage}
        message="Načtení se nezdařilo"
      />
    );
  }
  return (
    <DataGrid
      {...dataGrid}
      toolbar={toolbar}
      data={data}
      loading={query.loading}
    />
  );
};

const styles = (theme: Theme) => ({
  table: {
    minWidth: 500
  },
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing()
  },
  errorProjectRow: {
    backgroundColor: theme.palette.error.light
  }
});

export default withStyles(styles)(ProjectsTable as any) as (
  <T extends object>(p: Props<T>) => ReactElement
);

const customSort = (isCoreUser: boolean, projectType: "AP" | "ZVS", a: any, b: any, colIndex: number, order: "asc" | "desc") => {
  if (Array.isArray(a) && Array.isArray(b)) {
    a = a[0];
    b = b[0];
  }
  switch (colIndex) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 18:
      return compareStringInput(a, b, order);
    case 17:
      const itemA = a.length > 0 ? `${a[0].firstname} ${a[0].lastname}` : "-";
      const itemB = b.length > 0 ? `${b[0].firstname} ${b[0].lastname}` : "-";
      if (order === "asc") {
        return itemA.localeCompare(itemB);
      } else {
        return itemB.localeCompare(itemA);
      }
    case 6:
    case 7:
      return compareMomentInput(a, b, order);
    case 8:
      if (projectType === "ZVS") {
        return compareStringInput(a, b, order);
      }
      return compareMomentInput(a, b, order);
    case 9:
    case 10:
      return projectType === "AP" && isCoreUser ? compareMomentInput(a, b, order) : compareStringInput(a, b, order);
    case 11:
      return projectType === "ZVS" ? compareProjectStatusInput(a, b, order) : compareStringInput(a, b, order);
    case 19:
      return compareProjectStatusInput(a, b, order);
    default:
      break;
  }

  let intA = 0;
  let intB = 0;
  try {
    intA = parseInt(a.data[colIndex], 10);
    intA = isNaN(intA) ? 0 : intA;
  } catch (e) {
    // nothing..
  }
  try {
    intB = parseInt(b.data[colIndex], 10);
    intB = isNaN(intB) ? 0 : intB;
  } catch (e) {
    // nothing..
  }
  return (order === "asc" ? 1 : -1) * intA - intB;
};

export const compareStringInput = (a: string, b: string, order: "asc" | "desc") =>
  order === "asc" ? `${a}`.localeCompare(b) : `${b}`.localeCompare(a);

export const compareMomentInput = (a: string | string[], b: string | string[], order: "asc" | "desc", format: string = "DD.MM.YYYY") => {
  const selectedA = (Array.isArray(a) ? a.sort((a1, b1) => compareMomentInput(a1, b1, order, format))[0] : a) || 0;
  const selectedB = (Array.isArray(b) ? b.sort((a1, b1) => compareMomentInput(a1, b1, order, format))[0] : b) || 0;

  return order === "asc"
    ? moment(selectedA).unix() - moment(selectedB).unix()
    : moment(selectedB).unix() - moment(selectedA).unix();
};

export const compareProjectStatusInput = (
  a: ClassroomAttributes["phases"],
  b: ClassroomAttributes["phases"],
  order
) => {
  const activePhaseA: any = getActivePhase({ phases: a });
  const activePhaseB: any = getActivePhase({ phases: b });
  const itemA = activePhaseA ? `${activePhaseA.number}/${activePhaseA.length}: ${activePhaseA.name}` : "Dokončeno";
  const itemB = activePhaseB ? `${activePhaseB.number}/${activePhaseB.length}: ${activePhaseB.name}` : "Dokončeno";
  if (!!itemA && !!itemB) {
    if (order === "asc") {
      return itemA.localeCompare(itemB);
    } else {
      return itemB.localeCompare(itemA);
    }
  }
  return 1;
};
