import { CircularProgress, createTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable, { MUIDataTableProps } from "mui-datatables";
import { ReactNode } from "react";
import { withErrorBoundary } from "../ErrorBoundary";

interface Props {
  loading: boolean;
  toolbar: ReactNode;

  options: MUIDataTableProps["options"];
  columns: Exclude<MUIDataTableProps["columns"][number], string>[];
  data: MUIDataTableProps["data"];
}

export const DataGrid = withErrorBoundary<Props>(({ loading, columns, toolbar, options, data }) => {
  return (
    <>
      <div>
        {loading && (
          <div style={{ display: "flex", width: "calc(100vw - 280px)", justifyContent: "center", padding: "1rem 0" }}>
            <CircularProgress />
          </div>
        )}
        <MuiThemeProvider theme={() => getMuiTheme()}>
          <MUIDataTable
            title={toolbar}
            columns={columns}
            options={options}
            data={data}
          />
        </MuiThemeProvider>
      </div>
    </>
  );
});

const getMuiTheme = () =>
  createTheme({
    overrides: {
      ["MUIDataTable" as any]: {
        responsiveBase: {
          overflow: "initial !important"
        }
      },
      ["MUIDataTableHeadCell" as any]: {
        fixedHeader: {
          top: "128px"
        },
        data: {
          "white-space": "nowrap"
        }
      },
      ["MUIDataTableToolbar" as any]: {
        root: {
          "position": "sticky",
          "top": "64px",
          "left": "280px",
          "width": "calc(100vw - 280px)",
          "background-color": "white",
          "z-index": 2000
        },
        actions: {
          "display": "flex",
          "align-items": "center",
          "justify-content": "flex-end"
        }
      },
      ["MUIDataTableFooter" as any]: {
        root: {
          "background-color": "white",
          "border-top": "1px solid rgba(224, 224, 224, 1)",
          "position": "sticky",
          "bottom": 0,
          "left": "280px",
          "width": "calc(100vw - 280px)"
        }
      }
    }
  });
