export interface SchoolAttributes {
  id: string;
  name: string;
  region: string;
  note: string;
  status;
  street: string;
  city: string;
}

export default `
    id
    name
    region
    note
    status
    street
    city
    classrooms {
      id
      classroomName
      deleted
      semester
      year
    }
`;
