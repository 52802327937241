import { CircularProgress, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import gql from "graphql-tag";
import compose from "ramda/src/compose";
import React, { ComponentType, FC, useState } from "react";
import { graphql, MutationFunc } from "react-apollo";

import { ClassroomAttributes } from "../../constants/classroomAttributes";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import { any } from "../../utils/permissions";
import validatePhase from "../../utils/validatePhase";
import { useUser } from "../UserContext";

interface Props {
  classroom: ClassroomAttributes;
  userRoles: string[];
}

interface InnerProps {
  classes: any;
  switchCheckItemMutation: MutationFunc<{}, {}>;
  finishPhaseMutation: MutationFunc<{}, {}>;
}

const ProjectState: FC<Props & InnerProps> = ({
  classroom,
  classes,
  switchCheckItemMutation,
  finishPhaseMutation
}) => {
  const { Role } = useUser();
  const [loadingTaskCheck, setLoadingTaskCheck] = useState(null);
  const [finishPhaseLoading, setFinishPhaseLoading] = useState(false);

  const activePhase = classroom.phases.find(p => !p.finished) ?? classroom.phases[classroom.phases.length - 1];
  const allChecked = (activePhase.checklist ?? []).every(t => t.checked);

  const editable = any(["CORE"], Role) && !activePhase.finished;

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h5">
        Krok: {`${activePhase.number} - ${activePhase.name}`}
      </Typography>
      <List>
        {activePhase?.checklist?.map(task => (
          <ListItem
            key={task.id}
            onClick={() => {
              if (!task.checked && !loadingTaskCheck && editable) {
                setLoadingTaskCheck(task.id);
                switchCheckItemMutation({
                  variables: {
                    id: task.id
                  }
                }).then(() => {
                  setLoadingTaskCheck(null);
                });
              }
            }}
            className={classes.taskCheckItem}
          >
            {editable ? (
              <>
                <ListItemIcon>
                  {loadingTaskCheck === task.id ? <CircularProgress /> : <Checkbox checked={task.checked} color="primary" />}
                </ListItemIcon>
                <ListItemText primary={task.name} />
              </>
            ) : (
              <>
                <ListItemIcon>
                  {task.checked ? <Check color="primary" /> : <Close color="error" />}
                </ListItemIcon>
                <ListItemText primary={task.name} />
              </>
            )}
          </ListItem>
        ))}
      </List>
      {editable
        ? allChecked && isNilOrEmpty(validatePhase(activePhase, classroom)) ? (
          <Button
            color="primary"
            variant="contained"
            disabled={finishPhaseLoading}
            onClick={() => {
              setFinishPhaseLoading(true);
              finishPhaseMutation({
                variables: {
                  id: activePhase.id
                }
              }).then(() => {
                setFinishPhaseLoading(false);
              });
            }}
          >
            {finishPhaseLoading ? <CircularProgress /> : null} Dokončit
          </Button>
        ) : (
          <PhaseTooltip
            placement="top-start"
            title={(
              <>
                {!allChecked ? (
                  <Typography style={{ marginBottom: "4px", fontSize: "16px", fontWeight: 700 }}>
                    Zaškrtněte všechny úkoly
                  </Typography>
                ) : null}
                {!isNilOrEmpty(validatePhase(activePhase, classroom)) ? (
                  <>
                    <Typography style={{ marginBottom: "4px", fontSize: "16px", fontWeight: 700 }}>
                      Ještě musíte doplnit:
                    </Typography>
                    <ul>
                      {validatePhase(activePhase, classroom).map((error) => (
                        <li key={error} style={{ marginBottom: "4px", fontSize: "16px" }}>
                          {error}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </>
            )}
          >
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled
                onClick={() => { }}
              >
                Dokončit
              </Button>
            </span>
          </PhaseTooltip>
        )
        : null}
    </div>
  );
};

const SwitchCheckItemMutation = graphql(
  gql`
        mutation SwitchChecklistItem($id: ID!) {
            swithChecklistItem(id: $id) {
                id
                name
                checked
            }
        }
    `,
  {
    name: "switchCheckItemMutation"
  }
);

const styles = (theme) => {
  return {
    taskCheckItem: {
      "cursor": "pointer",
      "&:hover": {
        backgroundColor: theme.palette.action.hover
      }
    }
  };
};

export default compose(
  withStyles(styles),
  SwitchCheckItemMutation,
  graphql(gql`
        mutation FinishPhase($id: ID!) {
            finishPhase(id: $id) {
                id
                finished
            }
        }
    `, {
    name: "finishPhaseMutation"
  })
)(ProjectState as any) as ComponentType<Props>;

const PhaseTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "rgba(221, 221, 221, 1)",
    opacity: 1,
    color: "#000",
    minWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);
