import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const initRef = useRef(initialValue);
  const thisHook = useRef({});

  const [state, setState] = useState<T>(JSON.parse(localStorage.getItem(key)!) ?? initRef.current);

  useEffect(() => {
    const ref = thisHook.current;
    hookMap.set(ref, (k, value) => {
      k === key && setState(value);
    });
    return () => void hookMap.delete(ref);
  }, [key]);

  return [
    state,
    useCallback((sa: SetStateAction<T>) => setState(s => {
      const val = typeof sa === "function" ? (sa as any)(s) : sa;

      hookMap.forEach((fn) => fn(key, val));

      localStorage.setItem(key, JSON.stringify(val));
      return val;
    }), [key])
  ] as const;
};

const hookMap = new Map<object, (key: string, value: any) => unknown>();
